import React, { useEffect, useState, useRef } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { api } from "../../../services/Main/Api";
import moment from "moment";
import { Popover, Box, Typography } from "@mui/material";
import theme from "../../../layout/Main/Theme";

const EmailMessageComponent = (dataEmail) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const boxRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (boxRef.current) {
                const { left, top, right, bottom } = boxRef.current.getBoundingClientRect();
                const isInside =
                    event.clientX >= left &&
                    event.clientX <= right &&
                    event.clientY >= top &&
                    event.clientY <= bottom;

                if (!isInside) {
                    setAnchorEl(null);
                }
            }
        };

        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    const getIconColor = () => {
        if (dataEmail?.data?.email_send || dataEmail?.data?.email_delivered || dataEmail?.data?.email_was_read) {
            return theme?.palette?.success.main;
        }
        return 'lightgray';
    };

    const getHoverMessages = () => {
        const messages = [];
        if (dataEmail?.data?.email_send) {
            messages.push(`Enviado em ${moment(dataEmail?.data?.send_date).format("DD/MM/YYYY [às] HH:mm:ss")}`);
        }
        if (dataEmail?.data?.message_delivered) {
            messages.push(`Entregue em ${moment(dataEmail?.data?.delivered_date).format("DD/MM/YYYY [às] HH:mm:ss")}`);
        }
        if (dataEmail?.data?.message_was_read) {
            messages.push(`Lido em ${moment(dataEmail?.data?.reading_date).format("DD/MM/YYYY [às] HH:mm:ss")}`);
        }
        return messages;
    };

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    return (
        <Box
            ref={boxRef}
            onMouseEnter={handleMouseEnter}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <MailOutlineIcon sx={{ color: getIconColor() }} fontSize="medium" />
            {getHoverMessages().length > 0 &&
                <Popover
                    id="mouse-over-popover"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    disableRestoreFocus
                >
                    <Box
                        sx={{
                            padding: '12px',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                            maxWidth: '420px',
                        }}
                    >
                        {getHoverMessages().map((msg, index) => (
                            <Typography
                                component='li'
                                key={index}
                                sx={{
                                    fontSize: '12px',
                                    color: 'black',
                                    textAlign: 'start',
                                    marginBottom: index !== getHoverMessages().length - 1 ? '8px' : '0',
                                }}
                            >
                                {msg}
                            </Typography>
                        ))}
                    </Box>
                </Popover>
            }
        </Box>
    );
};

export default EmailMessageComponent;
