// src/components/StreamViewer.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
    Box,

    Button,
    CircularProgress,
    Alert,

    Divider, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, TextField, AvatarGroup, Avatar
} from '@mui/material';

import Markdown from "react-markdown";
import {Edit} from "@mui/icons-material";
import {api} from "../services/Main/Api";

const REACT_APP_ASGI_URL = process.env.REACT_APP_ASGI_URL

const StreamViewer = (props) => {
    const [content, setContent] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const abortControllerRef = useRef(null);
    const [open, setOpen] = useState(true);
    const [edit, setEdit] = useState(false);
    const formatContent = (text) => {
        // Garante que quebras de linha do servidor sejam respeitadas
        return text.replace(/\n/g, '\n');
    };

    const fetchStream = async () => {
        try {
            setIsLoading(true);
            setError(null);
            setContent('');

            abortControllerRef.current = new AbortController();
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/test/`, {
                signal: abortControllerRef.current.signal
            });

            if (!response.ok) {
                throw new Error(`Erro na requisição: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                const text = decoder.decode(value);
                setContent(prev => prev + formatContent(text));
            }
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Requisição cancelada');
            } else {
                setError(err.message);
                console.error('Erro ao processar stream:', err);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (props.open === true && !content && props.manual === false) {
            fetchStream();
        }

        if (props.manual === true){
            setEdit(true)
        }else{
            setEdit(false)
        }

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [props.open,]);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function postRentContract(file) {
        try {
            setIsLoading(true)
            const formData = new FormData();

            formData.append("document_file", file);
            const response = await api.post('/ai-rent-contract/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response?.data);
            setResult(response?.data)
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <Dialog
            open={props.open}
            maxWidth={"md"}
            fullWidth={true}
            onClose={()=>{
                props.onClose()
                setContent('')
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{ display: "flex", justifyContent: "space-between" }}>
                Analisando {isLoading ? (
                    <CircularProgress size={20} />
            ): (

                <Button color={"secondary"}
                        variant={"text"}
                        size={"small"}
                        startIcon={<Edit />} onClick={()=>{
                            setEdit(!edit)
                }}>
                    {edit? "Fechar edição" : "Editar Descrição"}</Button>
            )
            }
            </DialogTitle>
            <Divider  />
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <AvatarGroup max={4}>
                        <Avatar alt="Remy Sharp" src="https://i.pinimg.com/736x/e4/08/90/e408903cc42edbe26f0ba7bf23feaf68.jpg" />
                        <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                        <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                        <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                        <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                    </AvatarGroup>
                    {error && (
                        <Alert
                            severity="error"
                            sx={{ mb: 2 }}
                            onClose={() => setError(null)}
                        >
                            {error}
                        </Alert>
                    )}

                    { edit ? <>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Descrição"
                                sx={{width: "100%"}}
                                multiline
                                value={content}
                                onChange={(event)=> {
                                    setContent(event.target.value)
                                }}
                                maxRows={25}
                            />
                        </>
                        :
                        <>
                        {content ?
                            <Box onDoubleClick={()=>{
                                setEdit(!edit)
                            }}>
                            <Markdown>{content}</Markdown>
                            </Box>
                        : (
                            <Box>
                                Aguardando dados da análise...
                            </Box>
                        )}
                        </>
                     }


                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                    <Button onClick={()=>{
                        props.onClose()
                        if (props.manual === true){
                            setContent('')
                        }

                    }}>Cancelar</Button>
                </Box>

                <Button onClick={handleClose} autoFocus>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>



    );
};

export default StreamViewer;