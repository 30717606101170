import { Box } from '@mui/system'
import {Card, CardContent, Grid, Stack, TextField} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import { getCondoId, getFilterDate, setFilterDate } from '../../../services/Main/AuthStorage'
import CardInvoiceInfo from '../../../components/Invoice/CardInvoiceInfo'
import OverduesCard from '../../../components/Shared/OverduesCard'
import Treemap from '../../../components/Charts/Treemap'
import Pizza from '../../../components/Charts/Pizza'
import { useContext, useEffect, useState } from 'react'
import Context from '../../../contexts/Context'
import {DesktopDatePicker} from "@mui/x-date-pickers";


const CondoDashboard = () => {

  const storageDate = getFilterDate()

  const { condoIdLogin } = useContext(Context)
  const [date, setDate] = useState(storageDate ? moment(new Date(storageDate)).toDate() : moment().toDate())
  const condo = getCondoId()

  useEffect(function preventError() {
    setFilterDate(date)
  }, [])

  return (
    <>
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
    <Stack justifyContent="space-between" direction="row" spacing={1}>
      <DesktopDatePicker
        label="Filtrar Por Mês/Ano"
        openTo="month"
        views={['year', 'month',]}
        value={date}
        onChange={(newValue) => {
          setDate(moment(new Date(newValue)).locale('pt-br').format())
          setFilterDate(moment(new Date(newValue)).locale('pt-br').format())

        }}
        slotProps={{
          field: { clearable: false, onClear: () => {} },
          textField: {
            size: "small",
            inputProps: { readOnly: true },
          }
        }}
      />
    </Stack>
  </Box>

  <CardInvoiceInfo date={storageDate}/>

      <Grid container spacing={2} padding={2}>
        <Grid item xs={12} md={6}>
            <Pizza date={date} />
        </Grid>
        <Grid item xs={12} md={6}>
            <OverduesCard />
        </Grid>
      </Grid>

  <Card sx={{ width: '100%' }}>
    <CardContent>
      <Treemap date={date}/>
    </CardContent>
  </Card>
    </>)
}

export default CondoDashboard