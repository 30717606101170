import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Chip, Tab, Tabs } from '@mui/material'
import masks from "../Shared/Masks";

const StatusTabFilter = ({onChange, summary}) => {
  const [value, setValue] = useState(0)

  return (
    <Box>

      <Tabs
        value={value || 0}
        onChange={(e, value) => {
          setValue(value)
        }}
        textColor={"secondary"}
        indicatorColor={"secondary"}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab
          icon={
            <Chip
              sx={{
                color: "secondary.main",
                bgcolor: "secondary.lighter"
              }}
              variant={"filled"}
              color={"secondary"}
              label={summary?.totals?.total?.qty}
              size={"small"}
                />}
          iconPosition={"start"}
          disableRipple
          label={ value === 0 ? masks.money(summary?.totals?.total?.value.toString()) : "Todas"}
          onClick={(e) => onChange(e, "all")}
        />
        <Tab
          icon={
            <Chip
              sx={{
                color: "info.main",
                bgcolor: "info.lighter"
              }}
              variant={"filled"}
              color={"info"}
              label={summary?.totals?.open?.qty}
              size={"small"}
            />}
          iconPosition={"start"}
          disableRipple
          label={ value === 1 ? masks.money(summary?.totals?.open?.value.toString()) :"Abertas"}
          onClick={(e) => onChange(e, "open")}
        />
        <Tab
          icon={
            <Chip
              sx={{
                color: "warning.dark",
                bgcolor: "warning.lighter"
              }}
              variant={"filled"}
              color={"warning"}
              label={summary?.totals?.pending?.qty}
              size={"small"}
            />}
          iconPosition={"start"}
          disableRipple
          label={ value === 2 ? masks.money(summary?.totals?.pending?.value.toString()) :"Pendentes"}
          onClick={(e) => onChange(e, "pending")}
        />
        <Tab
          icon={
            <Chip
              sx={{
                color: "success.main",
                bgcolor: "success.lighter"
              }}
              variant={"filled"}
              color={"success"}
              label={summary?.totals?.paid?.qty}
              size={"small"}
            />}
          iconPosition={"start"}
          disableRipple
          label={ value === 3 ? masks.money(summary?.totals?.paid?.value.toString()) :"Pagas"}
          onClick={(e) => onChange(e, "paid")}
        />
        <Tab
          icon={
            <Chip
              sx={{
                color: "error.main",
                bgcolor: "error.lighter"
              }}
              variant={"filled"}
              label={summary?.totals?.overdue?.qty}
              size={"small"}
            />}
          iconPosition={"start"}
          disableRipple
          label={ value === 4 ? masks.money(summary?.totals?.overdue?.value.toString()) :"Vencidas"}
          onClick={(e) => onChange(e, "overdue")}
        />
      </Tabs>
    </Box>
  )
}

StatusTabFilter.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default StatusTabFilter
