import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Table,
  TableContainer, Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import {setCondoId, setCondoName, setCondoType, setRentType} from '../../../services/Main/AuthStorage'
import Context from "../../../contexts/Context";
import {useNavigate} from "react-router-dom";
import TableCondoHead from "./components/CondoList/TableCondoHead";
import TableCondoBody from "./components/CondoList/TableCondoBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";

const CondoList = () => {
  const { setCondoIdLogin } = useContext(Context)
  const navigate = useNavigate()

  const [condoData, setCondoData] = useState(null)
  const [verifier, setVerifier] = useState(null)
  const [filters, setFilters] = useState({
    page_size: 50,
    status: "ACTIVE"
  })

  async function fetchData() {
    try {
      if (filters) {
        const res = await api.get(`/condo/`, { params: filters });
        setCondoData(res.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setVerifier(true);
    }
  }

  async function changeCondo(value) {
    try {
      const response = await api.get(`/condo/${value}`);
      setCondoId(response.data.id);
      setCondoIdLogin(response.data.id);
      setCondoName(response.data.name);
      setCondoType(response.data.type);
      setRentType(response.data.rent_type);
      navigate('/');
      navigate(0);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData()
  }, [filters])

  return (
    <>
      {verifier ?
      <Box>
        <Card>
          <Typography
            variant={'h5'}
            sx={{
              p: '1rem'
            }}
          >
            Contas ({condoData?.count})
          </Typography>
          <Divider light sx={{ mb: '.3rem' }}/>
          <CardContent>
            <TableContainer>
              { condoData?.count ?
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableCondoHead />
                  <TableCondoBody
                    condoData={condoData}
                    changeCondo={changeCondo}
                  />
                </Table>
                  :
                <NoResultsAnimation />
              }
            </TableContainer>
          </CardContent>
        </Card>
        <PaginationGlobal
          state={condoData}
          filters={filters}
          setFilters={setFilters}
        />
      </Box>
      :
        <Skeleton variant="rounded" height={500} animation="pulse"/>
      }
    </>
  )
}

export default CondoList
