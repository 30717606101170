import {
    Alert, Avatar, Backdrop,
    Box,
    Button, Card, CardContent, CardHeader, Chip, Divider,
    Fade,
    FormControlLabel,
    FormHelperText, IconButton,
    InputAdornment,
    Switch, TextField, Tooltip, Typography,
    useMediaQuery
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment/moment";
import {api} from "../../../../services/Main/Api";
import Context from "../../../../contexts/Context";
import {useParams} from "react-router-dom";
import Masks from "../../../../components/Shared/Masks";
import CircularProgress from "@mui/material/CircularProgress";
import {Check, CheckCircle, CheckCircleOutlineOutlined, Close, Edit, Lock, Receipt} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import SaveCancelButton from "../../../../components/Shared/SaveCancelButton";

const AutoTransfer = (
    {
        reqData,
        setReqData,
        error,
        getContract,
        prevStateData
    }
) => {
    const { user } = useContext(Context)
    const { perm_bank } = user || {}
    const {dataProvider} = useContext(Context)
    const {enqueueSnackbar} = useSnackbar()


    const params = useParams()

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [reqLoading, setReqLoading] = useState(false)
    const [edit, setEdit] = useState(false)
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);


    function AutoTransferReq() {
        setReqLoading(true)
        const { value, bankslip_fine_value, bankslip_interest_value, contract_time} = reqData || {}

        const data = {
            ...reqData,
            // start_date: moment(active_date).format('YYYY-MM-DD'),
            contract_time: +contract_time,
            value: value?.includes('R$') && value?.replace(/\D+/g, ""),
            bankslip_fine_value: bankslip_fine_value ? bankslip_fine_value?.replace(/\D+/g, "") : null,
            bankslip_interest_value: bankslip_interest_value ? bankslip_interest_value?.replace(/\D+/g, "") : null,
        }

        if (params?.id || dataProvider?.rentId) {
            api.put(`/rent_contract/${dataProvider?.rentId ?? params?.id}/`, data)
                .then(response => {
                    getContract(null, true)
                    setEdit(false)
                    enqueueSnackbar('Repasse automático atualizado', {variant: 'success'})
                })
                .catch(error => {
                    console.log(error)
                    error.response.data.transfer_day && enqueueSnackbar(error.response.data.transfer_day, {variant: 'error'})
                })
                .finally(() => {
                    setReqLoading(false)
                })
        }
    }

    function RefreshReq() {
        api.get(`rent_contract/${dataProvider?.rentId ?? params?.id}/`)
            .then(response => {
                setReqData({...response.data, value: Masks.money(response.data.value.toString())})
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
            })
    }

    function handleAutotransfer(event, key) {
        const newValue = key === 'auto_transfer' || key === 'transfer_guaranteed' || key === 'send_email_recipient' || key === 'send_whatsapp_recipient' ? event.target.checked : event.target.value
        setReqData(prevState => ({
            ...prevState,
            [key]: newValue
        }))
        setButtonDisabled(prevState => ({
            ...prevState,
            [key]: newValue !== reqData?.[key]
        }))
    }

    const sizeMatch = useMediaQuery('@media (min-width:730px)')


    useEffect(function observeInputs() {
        const requiredKeys = ['auto_transfer', 'transfer_guaranteed', 'transfer_day', 'transfer_guaranteed_duration', 'send_email_recipient', 'send_whatsapp_recipient'];
        const hasChanged = requiredKeys.some(key => reqData?.[key]?.toString() !== prevStateData?.[key]?.toString())
        if (hasChanged) {
            setIsSaveEnabled(true)
        } else {
            setIsSaveEnabled(false)
        }

    }, [reqData]);

    // useEffect(function noDueDate() {
    //     if (!reqData?.bankslip_due_day) {
    //         setReqData(prevState => ({
    //             ...prevState,
    //             auto_transfer: false,
    //             transfer_guaranteed: false,
    //             transfer_day: null,
    //             transfer_guaranteed_duration: null
    //         }))
    //     }
    // }, [reqData?.bankslip_due_day])

    useEffect(() => {
      RefreshReq();
    }, [params?.id, dataProvider?.rentId]);

    return (
        <Card>
            <Box display="flex"  justifyContent= 'space-between'>
                <CardHeader
                    avatar={
                        <Avatar
                            sx={{
                                bgcolor: 'primary.main',
                            }}
                        >
                            <CurrencyExchangeOutlinedIcon />
                        </Avatar>
                    }
                    title={'Repasse'}
                />
                <Box sx={{mt:'1.5rem', mr: '1rem'}}>
                    <Chip
                        color={reqData?.auto_transfer ? 'success' : 'info'}
                        size="small"
                        variant="outlined"
                        label={reqData?.auto_transfer ? 'Automático' : 'Manual'}
                    />
                </Box>
            </Box>
            <Divider sx={{mb: '.3rem'}}/>
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'start',
                        mb: '1rem'

                    }}
                >
                    <Typography
                        sx={{
                            // ...(edit && {mb: '1rem'}),
                            fontWeight: 'bold',
                            fontSize: '18px',
                            color: 'primary.main',

                        }}>
                        Configurações
                    </Typography>
                    {!edit &&
                        <IconButton
                            sx={{p: 0}}
                            onClick={() => setEdit(true)}
                        >
                            <Edit/>
                        </IconButton>
                    }

                </Box>

                {!edit ?
                    <Box>


                    <Box display="flex" justifyContent= 'space-between' sx={{mb: '.3rem'}}>
                        <Typography>
                            Dia do repasse
                        </Typography>
                        <Typography>
                            <Box>
                                {reqData?.transfer_day ? reqData?.transfer_day : 'Não informado' }
                            </Box>
                        </Typography>
                    </Box>


                        <Box display="flex" justifyContent= 'space-between'>
                            <Typography>
                                Enviar comprovante por e-mail
                            </Typography>
                            <Typography>
                                {!perm_bank ?
                                    <Tooltip title="Você precisa ativar o banco para usar essa funcionalidade.">
                                        <Lock sx={{ml:0}} fontSize={"small"} color={"warning"}/>
                                    </Tooltip>
                                :
                                <Box
                                    sx={{
                                        color: reqData?.send_email_recipient ? 'success.main' : 'error.main'
                                    }}
                                >
                                    {reqData?.send_email_recipient ? <CheckCircleOutlineOutlined /> : <Close />}
                                </Box> }
                            </Typography>
                        </Box>


                        <Box display="flex" justifyContent= 'space-between'>
                            <Typography>
                                Enviar comprovante por whatsapp
                            </Typography>
                            <Typography>
                                {!perm_bank ?
                                    <Tooltip title="Você precisa ativar o banco para usar essa funcionalidade.">
                                        <Lock sx={{ml:0}} fontSize={"small"} color={"warning"}/>
                                    </Tooltip>
                                    :
                                <Box
                                    sx={{
                                        color: reqData?.send_whatsapp_recipient ? 'success.main' : 'error.main'
                                    }}
                                >
                                    {reqData?.send_whatsapp_recipient ? <CheckCircleOutlineOutlined /> : <Close />}
                                </Box> }
                            </Typography>
                        </Box>

                        {/*{reqData?.auto_transfer && reqData?.transfer_guaranteed &&*/}
                        {/*    <Box display="flex" justifyContent= 'space-between'>*/}
                        {/*        <Typography>*/}
                        {/*            Repasse garantido*/}
                        {/*        </Typography>*/}
                        {/*        <Typography>*/}
                        {/*            <Box*/}
                        {/*                sx={{*/}
                        {/*                    color: reqData?.transfer_guaranteed ? 'success.main' : 'error.main'*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                {reqData?.transfer_guaranteed ? <Check /> : <Close />}*/}
                        {/*            </Box>*/}
                        {/*        </Typography>*/}
                        {/*    </Box>*/}
                        {/*}*/}
                        {/*{reqData?.auto_transfer && reqData?.transfer_guaranteed &&*/}
                        {/*    <Box display="flex" justifyContent= 'space-between'>*/}
                        {/*        <Typography>*/}
                        {/*            Tempo do repasse garantido*/}
                        {/*        </Typography>*/}
                        {/*        <Typography>*/}
                        {/*            <Box>*/}
                        {/*                {reqData?.transfer_guaranteed_duration} Meses*/}
                        {/*            </Box>*/}
                        {/*        </Typography>*/}
                        {/*    </Box>*/}
                        {/*}*/}
                    </Box>

                    :

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <TextField
                            label={'Selecione o dia do repasse'}
                            size={'small'}
                            fullWidth
                            sx={{mb: '1rem'}}
                            required
                            value={reqData?.transfer_day?.toString().replace(/\D+/g, "") ?? ''}
                            onChange={(event) => handleAutotransfer(event, 'transfer_day')}
                        />

                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb:1}}>
                            <FormControlLabel
                                sx={{mb: '0rem'}}
                                control={
                                    <Switch
                                        checked={!!reqData?.auto_transfer}
                                        onChange={(event) => handleAutotransfer(event, 'auto_transfer')}
                                        disabled={!perm_bank}
                                    />}
                                label={'Repasse automático'}
                            />
                            {!perm_bank &&
                                <Tooltip title="Você precisa ativar o banco para usar essa funcionalidade.">
                                    <Lock sx={{ml:1}} fontSize={"small"} color={"warning"}/>
                                </Tooltip>
                            }
                        </Box>

                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb:1}}>
                            <FormControlLabel

                                control={
                                    <Switch
                                        disabled={!perm_bank}
                                        checked={!!reqData?.send_email_recipient}
                                        onChange={(event) => handleAutotransfer(event, 'send_email_recipient')}
                                    />}
                                label={'Enviar comprovante por e-mail?'}
                            />
                            {!perm_bank &&
                                <Tooltip title="Você precisa ativar o banco para usar essa funcionalidade.">
                                    <Lock sx={{ml:1}} fontSize={"small"} color={"warning"}/>
                                </Tooltip>
                            }
                        </Box>
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", mb:1}}>
                            <FormControlLabel

                                control={
                                    <Switch
                                        disabled={!perm_bank || !reqData.whatsapp_send_message}
                                        checked={!!reqData?.send_whatsapp_recipient}
                                        onChange={(event) => handleAutotransfer(event, 'send_whatsapp_recipient')}
                                    />}
                                label={'Enviar comprovante por whatsapp?'}
                            />
                            {!perm_bank &&
                                <Tooltip title="Você precisa ativar o banco para usar essa funcionalidade.">
                                    <Lock sx={{ml:1}} fontSize={"small"} color={"warning"}/>
                                </Tooltip>
                            }
                        </Box>
                        <Divider sx={{mb: '1rem', mt: '1rem'}}/>
                        <SaveCancelButton
                            edit={edit}
                            align={'start'}
                            disabled={!isSaveEnabled}
                            saveClick={() => {
                                AutoTransferReq()
                            }}
                            cancelClick={() => {
                                getContract(null, true)
                                setEdit(false)
                            }}
                        />

                        {/*<Box*/}
                        {/*    sx={{*/}
                        {/*        display: 'flex',*/}
                        {/*        flexDirection: 'column'*/}
                        {/*    }}*/}
                        {/*>*/}

                        {/*    {reqData?.auto_transfer &&*/}
                        {/*        <FormControlLabel*/}
                        {/*            sx={{mb: '1rem'}}*/}
                        {/*            control={*/}
                        {/*                <Switch*/}
                        {/*                    disabled={!reqData?.bankslip_due_day}*/}
                        {/*                    checked={!!reqData?.transfer_guaranteed}*/}
                        {/*                    onChange={(event) => handleAutotransfer(event, 'transfer_guaranteed')}*/}
                        {/*                />}*/}
                        {/*            label={'Repasse garantido'}*/}
                        {/*        />*/}
                        {/*    }*/}

                        {/*    {reqData?.transfer_guaranteed &&*/}
                        {/*        <TextField*/}
                        {/*            label={'Tempo para o repasse garantido'}*/}
                        {/*            size={'small'}*/}
                        {/*            fullWidth*/}
                        {/*            required*/}
                        {/*            value={reqData?.transfer_guaranteed_duration?.toString()?.replace(/\D+/g, "") ?? ''}*/}
                        {/*            onChange={(event) => handleAutotransfer(event, 'transfer_guaranteed_duration')}*/}
                        {/*            InputProps={{*/}
                        {/*                endAdornment: (*/}
                        {/*                    <InputAdornment position="end">*/}
                        {/*                        Meses*/}
                        {/*                    </InputAdornment>*/}
                        {/*                )*/}
                        {/*            }}*/}

                        {/*        />*/}
                        {/*    }*/}

                        {/*</Box>*/}
                    </Box>

                }
                {!reqData?.bankslip_due_day &&
                <Box>
                <Backdrop
                    sx={{color: '#fff', zIndex: 1500,}}
                    open={reqLoading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Alert
                    severity={'info'}
                    sx={{
                        background: 'transparent',
                        boxShadow: 'none',
                        padding: 0,
                        fontSize: '13px'
                    }}
                >
                    {'Opção disponível quando boleto possui dia de vencimento configurado'}
                </Alert>
                </Box>
                }
            </CardContent>
        </Card>
    )
}

export default AutoTransfer