import React, {useEffect, useState} from "react";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Chip, CardHeader, Box, TextField, ImageList, ImageListItem,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import DropzoneImage from "../../../components/DropzoneImage";
import {Assistant, Create} from "@mui/icons-material";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {api} from "../../../services/Main/Api";
import {useParams} from "react-router-dom";
import moment from 'moment/moment'
import StreamViewer from "../../../components/StreamViewer";
import CollectionsIcon from '@mui/icons-material/Collections';

const InspectionDetails = () => {

    const [data, setData] = useState(null);
    const [images, setImages] = useState([]);
    const [open, setOpen] = useState(false);
    const [manual, setManual] = useState(false);
    const params = useParams()
    function getInspection(){
        const condo_id= getCondoId()
        api.get(`/property-inspection/${params.id}/?condo_id=${condo_id}`).then(response => {
            const {data} = response
            setData(data)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getInspection()
    }, []);

    useEffect(() => {
        getInspection()
    }, [images]);

    async function uploadImages(files, characteristic_id) {
        try {
            const formData = new FormData();

            // Adiciona cada arquivo com o mesmo nome de campo 'image'
            files.forEach(file => {
                formData.append('image', file);
            });

            formData.append("characteristic", characteristic_id);

            const response = await api.post('/property-inspection/characteristic/images/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log(response?.data);
            setImages(response?.data);
        } catch (error) {
            console.error(error);
        }
    }
    const handleUploadComplete = (uploadedImages) => {
        console.log('Upload completo:', uploadedImages);
        setImages(uploadedImages);
    };

    return (
        <Grid container spacing={2} padding={2}>

            {/* Dados da Vistoria */}
            <Grid item xs={12} md={8}>
                <Box>
                    <StreamViewer
                        open={open}
                        manual={manual}
                        onClose={() => setOpen(false)}/>
                </Box>

                {data?.characteristic.map(characteristic=>  (<Card sx={{ mb: 2 }}>
                    <CardHeader
                        title={`${characteristic.name}`}
                        action={
                            <Chip
                                color="default"
                                variant="outlined"
                                size="small"
                                label={
                                    <Box display="flex" alignItems="center" gap={0.5}>
                                        <CollectionsIcon fontSize="small" />
                                        {`${characteristic.images.length} image${characteristic.images.length > 1 ? 'ns' : 'm'}`}
                                    </Box>
                                }
                            />
                        }
                    />
                    <Divider/>
                    <CardContent>
                        {/*<Box sx={{ mt:2}}>*/}
                        {/*    <ImageList sx={{ width: 'auto', height: 450 }} cols={6} rowHeight={75}>*/}
                        {/*    { characteristic.images?.map(image => (*/}
                        {/*        <ImageListItem key={image.image}>*/}
                        {/*            <img*/}
                        {/*                srcSet={image.image}*/}
                        {/*                src={image.image}*/}
                        {/*                alt={"test"}*/}
                        {/*                width={75}*/}
                        {/*                loading="lazy"*/}
                        {/*            />*/}
                        {/*        </ImageListItem>*/}

                        {/*        ))}*/}
                        {/*    </ImageList>*/}
                        {/*</Box>*/}
                        <DropzoneImage
                            onUploadComplete={handleUploadComplete}
                            characteristicId={characteristic.id}
                            apiEndpoint="/property-inspection/characteristic/images/"
                        />


                        <Divider sx={{mt: 4, mb: 4}}/>
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <Button variant="outlined"

                                    color={"secondary"}
                                    onClick={() => {
                                        setOpen(true)
                                        setManual(true)
                                    }}
                                    startIcon={<Create/>}
                                    sx={{marginTop: "1rem", mr: "1rem"}}>
                                Criar descrição
                            </Button>
                            <Button variant="outlined"

                                    onClick={() => {
                                        setOpen(true)
                                        setManual(false)
                                    }}
                                    startIcon={<Assistant/>}
                                    sx={{marginTop: "1rem"}}>
                                Criar descrição com IA
                            </Button>

                        </Box>

                    </CardContent>
                </Card> ))}


            </Grid>

            {/* Imóvel */}
            <Grid item xs={12} md={4}>
                <Card sx={{mb:2}}>
                    <CardHeader sx={{ color:"primary.main"}} title={data?.number}
                                action={
                                    <Chip
                                        label="Aberto"
                                        color="info"
                                        size="small"
                                        variant="outlined"

                                    />
                                }
                    />
                    <Divider/>
                    <CardContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography><strong>Nome:</strong></Typography>
                                        <Typography>{data?.name}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography><strong>CPF/CNPJ:</strong> </Typography>
                                        <Typography>{data?.cpf_or_cnpj}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography><strong>Telefone:</strong> </Typography>
                                        <Typography>{data?.phone}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography><strong>E-mail:</strong> </Typography>
                                        <Typography>{data?.email}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography><strong>Criado em:</strong> </Typography>
                                        <Typography>{moment(data?.created_at).format("DD/MM/YYYY [às] HH:mm:ss")}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography><strong>Endereço:</strong> </Typography>
                                        <Typography>{data?.full_address}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </CardContent>
                </Card>

            </Grid>

            {/* Contrato */}
            <Grid item xs={6}>

            </Grid>
        </Grid>
    );
};

export default InspectionDetails;