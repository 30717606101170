import React, { useState } from 'react';
import {
    Autocomplete,
    TextField,
    Chip,
    Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RoomIcon from '@mui/icons-material/Room';
import KitchenIcon from '@mui/icons-material/Kitchen';
import BathtubIcon from '@mui/icons-material/Bathtub';
import WeekendIcon from '@mui/icons-material/Weekend';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import GarageIcon from '@mui/icons-material/Garage';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KingBedIcon from '@mui/icons-material/KingBed';

function ComodoAutocomplete({onChange}) {
    const [options, setOptions] = useState([
        "Dormitorio",
        "Suíte",
        "Sala",
        "Cozinha",
        "Banheiro",
        "Garagem",

    ]);

    const [selectedItems, setSelectedItems] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const formatComodoName = (newComodo) => {
        const existingCount = selectedItems.filter(item =>
            item.replace(/\s+\d+$/, '') === newComodo
        ).length;
        return existingCount > 0 ? `${newComodo} ${existingCount + 1}` : newComodo;
    };

    const handleSelect = (event, newValue) => {
        if (newValue) {
            const baseComodo = newValue.replace(/\s+\d+$/, '');
            const formattedComodo = formatComodoName(baseComodo);
            const updatedItems = [...selectedItems, formattedComodo];
            setSelectedItems(updatedItems);

            if (onChange) {
                onChange(updatedItems);
            }

            setTimeout(() => {
                setInputValue('');
            }, 0);
        }
    };

    const handleDelete = (itemToDelete) => {
        const filteredItems = selectedItems.filter(item => item !== itemToDelete);
        setSelectedItems(filteredItems);

        // Chama a função de callback com os itens atualizados
        if (onChange) {
            onChange(filteredItems);
        }
    };

    // Função para selecionar o ícone baseado no tipo de cômodo
    const getComodoIcon = (comodo) => {
        const baseComodo = comodo.replace(/\s+\d+$/, '').toLowerCase();
        switch (baseComodo) {
            case 'cozinha':
                return <KitchenIcon />;
            case 'banheiro':
                return <BathtubIcon />;
            case 'sala':
                return <WeekendIcon />;
            case 'dormitorio':
                return <SingleBedIcon />;
            case 'suíte':
                return <KingBedIcon />;
            case 'garagem':
                return <GarageIcon />;
            default:
                return <MoreHorizIcon />;
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto' }}>
            <Autocomplete
                value={null}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                onChange={handleSelect}
                options={options}
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterOptions={(options, params) => {
                    const filtered = options.filter(option =>
                        option.toLowerCase().includes(params.inputValue.toLowerCase())
                    );

                    if (params.inputValue !== '' && !options.includes(params.inputValue)) {
                        filtered.push(`Adicionar "${params.inputValue}"`);
                    }

                    return filtered;
                }}
                renderOption={(props, option) => {
                    if (option.startsWith('Adicionar "')) {
                        const newValue = option.slice(10, -1);
                        return (
                            <li {...props} onClick={() => {
                                setOptions([...options, newValue]);
                                const formattedComodo = formatComodoName(newValue);
                                setSelectedItems([...selectedItems, formattedComodo]);
                                setInputValue('');
                            }}>
                                <AddIcon sx={{ mr: 1, color: 'success.main' }} />
                                {option}
                            </li>
                        );
                    }
                    return (
                        <li {...props}>
                            {getComodoIcon(option)}
                            <Box component="span" sx={{ ml: 1 }}>
                                {option}
                            </Box>
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Comodos"
                        variant="outlined"
                        sx={{ mb: 2 }}
                    />
                )}
            />

            {selectedItems.length > 0 && (
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        p: 2,
                        borderRadius: 1,
                        bgcolor: 'background.paper',
                        boxShadow: 1
                    }}
                >
                    {selectedItems.map((item, index) => (
                        <Chip
                            key={index}
                            label={item}
                            onDelete={() => handleDelete(item)}
                            color="primary"
                            icon={getComodoIcon(item)}
                            sx={{
                                height: 32,
                                '& .MuiChip-label': {
                                    fontSize: '0.875rem',
                                },
                                '& .MuiChip-icon': {
                                    fontSize: '1.2rem',
                                },
                                '&:hover': {
                                    bgcolor: 'primary.dark',
                                }
                            }}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
}

export default ComodoAutocomplete;