import {
    Alert, Autocomplete,
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, InputAdornment,
    InputLabel, ListSubheader,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Skeleton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
    Add,
    Business,
    Home,
    LocalAtm,
    LocalAtmOutlined,
    Money, Percent,
    ReceiptLong,
    ReceiptOutlined
} from '@mui/icons-material'
import Masks from '../../../components/Shared/Masks'
import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {MobileDatePicker} from '@mui/x-date-pickers'
import {api} from '../../../services/Main/Api'


import Context from '../../../contexts/Context'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {getCondoId} from "../../../services/Main/AuthStorage";
import CircularProgress from '@mui/material/CircularProgress'
import AnimationGlobal from '../../../components/Shared/animations/AnimationGlobal'
import successAnimation from '../../../assets/animations/success.json'
import {useSnackbar} from "notistack";
import ComodoAutocomplete from "../../../components/Autocomplete/ComodoAutocomplete";

function InspectionForm(props) {
    //////////////////////////////////////
    //// States, Provider and Hooks /////
    /////////////////////////////////////
    const {id} = useParams();
    const {dataProvider} = useContext(Context)

    const [renterChoices, setRenterChoies] = useState([]);
    const [renter, setRenter] = useState(null);
    const [inspectionType, setInspectionType] = useState("initial");
    const [openDialog, setOpenDialog] = useState(true);
    const [isSaving, setIsSaving] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [activeBackdrop, setActiveBackdrop] = useState(false);
    const [error, setError] = useState(null);
    const [rentContract, setRentContract] = useState(null);
    const [items, setItems] = useState([]);
    const [comodosSelecionados, setComodosSelecionados] = useState([]);

    const {user} = useContext(Context)
    const {perm_bank} = user || {}
    const {enqueueSnackbar} = useSnackbar()

    const handleDialogClose = () => {
        setOpenDialog(false);
    };



    function resetFields() {
        setRenter(null)
        setInspectionType("initial")
        setRentContract(null)
        setComodosSelecionados([])
    }

    function handleSaveInspection() {
        setIsSaving(true);
        setOpenDialog(false)
        setActiveBackdrop(true)

        const idRenter = renter.id

        let data = {
            "name": renter.name,
            "cpf_or_cnpj": renter.cpf,
            "phone": renter.phone,
            "email": renter.email,
            "unit": renter.id,
            "rent_contract": rentContract,
            "type_inspection": inspectionType,
            "characteristic": comodosSelecionados,
        }

        api.post("/property-inspection/", data)
            .then(response => {

                const result = response.data
                // window.location.href = `${process.env.REACT_APP_HOST_URL}/contratos/${result.id}`
                setTimeout(() => {
                    setIsSuccess(true)
                    resetFields()
                }, 3000);

                setIsSaving(false);

                setTimeout(() => {
                    setActiveBackdrop(false)
                    // setIsSuccess(false)
                    props.closeForm()

                }, 4000);

            })
            .catch(error => {
            setIsSaving(false);
            setError(error?.response)
            error.response.data.generate_bankslip_day && enqueueSnackbar(error.response.data.generate_bankslip_day, {variant: 'error'})
            error.response.data.transfer_day && enqueueSnackbar(error.response.data.transfer_day, {variant: 'error'})
            console.log('error', error)
            error.response?.data?.message && enqueueSnackbar(error.response.data.message, {variant: 'error'})
            error.response?.data?.[0] && enqueueSnackbar(error.response.data[0], {variant: 'error'})
            setOpenDialog(true)
        })
    }




    function getRenters() {
        const condo_id = getCondoId()
        api.get(`/rent-contract/unit/?condo_id=${condo_id}`).then(response => {
            const {data} = response
            setRenterChoies(data.results)
        }).catch(error => {
            console.log(error)
        })
    }


    useEffect(() => {
        getRenters()
    }, [])


    const handleComodosChange = (novosComodos) => {
        setComodosSelecionados(novosComodos);
        console.log('novosComodos', novosComodos)
        if (novosComodos.length > 0 && renter){
            setIsSaving(false)
        }

    };

    return (
        <Grid item xs={12}>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={openDialog}
                scroll={'body'}
                onClose={() => props.closeForm()}
            >
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '1rem'}}>
                    <DialogTitle>Nova Vistoria</DialogTitle>


                </Box>

                <Divider/>

                <DialogContent>
                    <Grid container spacing={2} mt={0}>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth size={'large'}>

                                <InputLabel id="transfer-select-label">Tipo</InputLabel>
                                <Select
                                    labelId="transfer-select"
                                    id="transfer-select"
                                    defaultValue={inspectionType}
                                    value={inspectionType}
                                    label="Indice para reajuste"

                                    onChange={(event) => {
                                        setInspectionType(event.target.value)
                                    }}
                                >
                                    <MenuItem value={"initial"}>{"Entrada"}</MenuItem>
                                    <MenuItem value={"exit"}>{"Saída"}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth size={'large'}>

                                <Autocomplete

                                    onChange={(event, newValue) => {
                                        const renter = renterChoices.find(item => item.id === newValue.id)
                                        setRenter(renter)
                                        if (comodosSelecionados.length > 0 && renter) {
                                            setIsSaving(false)
                                        }
                                    }}
                                    options={renterChoices.map((item) => ({id:item.id, label: item.name}))}
                                    renderInput={(params) => <TextField {...params} label="Locatário" />}
                                />

                            </FormControl>
                        </Grid>
                        {renter && <Grid item xs={12} md={4}>
                            <Typography sx={{fontWeight: 'bold'}}>Nome:</Typography>
                            <Typography variant="body2" display="block" gutterBottom>
                                {renter?.name}
                            </Typography>
                        </Grid>}
                        {renter && <Grid item xs={12} md={4}>
                            <Typography sx={{fontWeight: 'bold'}}>CPF/CNPJ:</Typography>
                            <Typography variant="body2" display="block" gutterBottom>
                                {renter?.cpf}
                            </Typography>
                        </Grid>}
                        {renter && <Grid item xs={12} md={4}>
                            <Typography sx={{fontWeight: 'bold'}}>Telefone:</Typography>
                            <Typography variant="body2" display="block" gutterBottom>
                                {renter?.phone}
                            </Typography>
                        </Grid>}
                        {renter && <Grid item xs={12} md={12}>
                            <Typography sx={{fontWeight: 'bold'}}>Endereço:</Typography>
                            <Typography variant="body2" display="block" gutterBottom>
                                {renter?.full_address}
                            </Typography>
                        </Grid>}



                    </Grid>

                </DialogContent>
                <DialogTitle sx={{display: "flex", justifyContent: "center"}}>
                    Caracteristicas do imóvel
                </DialogTitle>
                <DialogContent>
                <Grid container spacing={1} mt={0}>
                    <Grid item xs={12} md={12}>

                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth size={'large'}>

                            <ComodoAutocomplete onChange={handleComodosChange} />

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {items.map((item, index) =>(
                            <>{item}</>
                            )
                        )}
                    </Grid>
                </Grid>


                </DialogContent>





                <Divider/>
                <DialogActions sx={{display: "flex", justifyContent: "space-between", padding: "2rem 1rem"}}>
                    <Button variant="text" color={"secondary"} onClick={() => props.closeForm()}>Cancelar</Button>
                    <Button variant="contained" disabled={isSaving} color={"primary"}
                            onClick={() => handleSaveInspection()}>{"Salvar"}</Button>
                </DialogActions>
            </Dialog>


            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={activeBackdrop}
            >
                {!isSuccess ? <Box><CircularProgress color="inherit"/> </Box>
                    : <AnimationGlobal
                        text={'Seu contrato foi foi criado com sucesso!'}
                        size={'200px'}
                        animation={successAnimation}
                        fontStyles={{mb: '1rem'}}
                        fontSize={'20px'}
                        color="#FFFFFF"
                    />}
            </Backdrop>


        </Grid>
    );
}

export default InspectionForm