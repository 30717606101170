import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './pages/MainPages/User/Login'
import RequireAuth from './services/Main/RequireAuth'
import React, { useContext } from 'react'
import Dashboard from './pages/MainPages/Dashboard'
import InvoiceList from './pages/MainPages/Invoice/InvoiceList'
import ExpenseList from './pages/MainPages/Expense/ExpenseList'
import Base from './layout/Main/Base'
import { NoMatch } from './pages/MainPages/NoMatch'
import { UnitList } from './pages/MainPages/Unit/UnitList'
import RecoverPassword from './pages/MainPages/User/RecoverPassword'
import ChangePassword from './pages/MainPages/User/ChangePassword'
import Register from './pages/MainPages/User/Register'
import ProfileUpdate from './pages/MainPages/User/ProfileUpdate'
import CondoUpdate from './pages/MainPages/Condo/CondoUpdate'
import CondoList from './pages/MainPages/Condo/CondoList'
import ExpenseCreate from './pages/MainPages/Expense/ExpenseCreate'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ptBR } from 'date-fns/locale/pt-BR'
import NewPassword from './pages/MainPages/User/NewPassword'
import ExpenseUpdate from './pages/MainPages/Expense/ExpenseUpdate'
import InvoiceDetail from './pages/MainPages/Invoice/InvoiceDetail'
import FavIconTheme from './layout/Main/FavIconTheme'
import { BankStatement, BankTransfer } from './pages/MainPages/BankOperations'
import ExpenseDetails from './pages/MainPages/Expense/ExpenseDetails'
import { NotificationList } from './pages/MainPages/Notification/NotificationList'
import BankTransferReceiptTed from './pages/MainPages/BankOperations/BankTransferReceiptTed'
import Settings from './pages/MainPages/Settings/Settings'
import BlockFormSet from './pages/MainPages/Blocks/BlockFormSet'
import Category from './pages/MainPages/Category/Category'
import ServiceList from './pages/MainPages/services/ServiceList'
import ServiceCreate from './pages/MainPages/services/ServiceCreate'
import ServiceDetailAndUpdate from './pages/MainPages/services/ServiceDetailAndUpdate'
import BookingAreaList from './pages/MainPages/BookingArea/BookingAreaList'
import ComplaintCreate from './pages/MainPages/Complaints/ComplaintCreate'
import ComplaintMain from './pages/MainPages/Complaints/ComplaintMain'
import ComplaintDetailandUpdate from './pages/MainPages/Complaints/ComplaintDetailandUpdate'
import ComplaintList from './pages/MainPages/Complaints/ComplaintList'
import ComplaintCategory from './pages/MainPages/Complaints/ComplaintCategory'
import BookingCalendar from './pages/MainPages/Booking/BookingCalendar'
import UnitUserRegister from './pages/UnitUser/User/UnitUserRegister'
import UnitUserLogin from './pages/UnitUser/User/UnitUserLogin'
import UnitUserInvoiceList from './pages/UnitUser/Invoices/UnitUserInvoiceList'
import RequiredUnitAuth from './services/UnitUser/RequiredUnitAuth'
import BaseUnitUser from './layout/UnitUser/BaseUnitUser'
import UnitUserInvoiceDetail from './pages/UnitUser/Invoices/UnitUserInvoiceDetail'
import ChargeList from './pages/MainPages/Charge/ChargeList'
import ChargeDetail from './pages/MainPages/Charge/ChargeDetail'
import ChargeCreate from './pages/MainPages/Charge/ChargeCreate'
import MaintenanceCalendar from './pages/MainPages/Maintenance/MaintenanceCalendar'
import BillboardList from './pages/MainPages/Billboard/BillboardList'
import DocumentList from './pages/MainPages/Documents/DocumentList'
import ReportGeneralList from './pages/MainPages/Report/ReportGeneralList'
import Payment from './pages/MainPages/Payment/Payment'
import ManagementList from './pages/MainPages/Management/ManagementList'
import BankTransferReceiptPix from './pages/MainPages/BankOperations/BankTransferReceiptPix'
import RentList from './pages/MainPages/Rent/RentList'
import Context from './contexts/Context'
import RentForm from './pages/MainPages/Rent/RentForm'
import RentRecipientList from './pages/MainPages/RentRecipient/RentRecipientList'
import RentRecipientForm from './pages/MainPages/RentRecipient/RentRecipientForm'
import Impersonate from './pages/MainPages/User/Impersonate'
import UnitForm from "./pages/MainPages/Unit/UnitForm";
import Report from "./pages/MainPages/Report/Report";
import SignedRecipientTransferList from './pages/MainPages/SignedRecipientTransfer/SignedRecipentTransferList'
import SignedRecipientTransferDetail from './pages/MainPages/SignedRecipientTransfer/SignedRecipientTransferDetail'
import { getCondoType } from './services/Main/AuthStorage'
import PaymentList from "./pages/MainPages/Payment/PaymentList";
import RentalChargesList from './pages/MainPages/financial/RentalChargesList'
import Reajust from './pages/MainPages/financial/Reajust'
import LimitBankDetail from "./pages/MainPages/BankOperations/LimitBankDetail";
import CompanyList from './pages/MainPages/Admin/CompanyList'
import AdminDashboard from './pages/MainPages/Admin/AdminDashboard'
import AdminBase from './layout/Main/admin/AdminBase'
import CustomerInvoiceList from './pages/MainPages/Admin/CustomerInvoice/CustomerInvoiceList'
import CustomerInvoiceDetail from './pages/MainPages/Admin/CustomerInvoice/CustomerInvoiceDetail'
import RentContractAdminList from './pages/MainPages/Admin/Rent/RentList'
import RentReportList from './pages/MainPages/Report/RentList'
import UserList from './pages/MainPages/Admin/UserList'
import RentalChargePayment from "./pages/MainPages/financial/RentalChargePayment";

import WhatsappConfig from "./pages/MainPages/Whatsapp/WhatsAppConfig";
import WebhookList from './pages/MainPages/Admin/WebhookList'
import BankTransferDetailTed from "./pages/MainPages/BankOperations/components/BankTransfer/BankTransferDetailTed";
import BankTransferDetailPix from "./pages/MainPages/BankOperations/components/BankTransfer/BankTransferDetailPix";
import CompanyRateList from './pages/MainPages/Admin/CompanyRateList'
import CompanyRateDetail from "./pages/MainPages/Admin/CompanyRateDetail";
import TransactionList from './pages/MainPages/Admin/TransactionList'
import CompanyDetail from "./pages/MainPages/Admin/CompanyDetail";
import Dimob from "./pages/MainPages/financial/Dimob";
import DimobDetail from "./pages/MainPages/financial/DimobDetail";
import InspectionList from "./pages/MainPages/Inspection/InspectionList";
import InspectionDetails from "./pages/MainPages/Inspection/InspectionDetails";

function App () {
  const { user } = useContext(Context)
  const {
    is_superuser,
    perm_bank,
    perm_config,
    perm_financial,
    perm_billboard,
    perm_booking,
    perm_complain,
    perm_expense,
    perm_maintenance,
    perm_management,
    perm_rent
  } = user || {}

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <FavIconTheme/>
        <Routes>
          <Route path="/login" element={<Login/>}/>
          <Route path="/login/usuario" element={<UnitUserLogin/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/registrar/usuario" element={<UnitUserRegister/>}/>
          <Route path="/recuperar-senha" element={<RecoverPassword/>}/>
          <Route path="/nova-senha" element={<NewPassword/>}/>

          <Route element={<BaseUnitUser/>}>
            <Route element={<RequiredUnitAuth/>}>
              {user &&
                <>
                  <Route path="/usuario/perfil/" element={<ProfileUpdate/>}/>
                  <Route path="/usuario/faturas/" element={<UnitUserInvoiceList/>}/>
                  <Route path="/usuario/faturas/:id" element={<UnitUserInvoiceDetail/>}/>
                </>
              }
            </Route>
          </Route>

          {is_superuser &&<Route element={<AdminBase/>}>
            <Route element={<RequireAuth/>}>
                  <Route path="/admin/dashboard" element={<AdminDashboard/>}/>
                  <Route path="/admin/company" element={<CompanyList/>}/>
                  <Route path="/admin/company/:id" element={<CompanyDetail/>}/>
                  <Route path="/admin/transaction" element={<TransactionList/>}/>
                  <Route path="/admin/company-rate" element={<CompanyRateList/>}/>
                  <Route path="/admin/company-rate/:id" element={<CompanyRateDetail/>}/>
                  <Route path="/admin/user" element={<UserList/>}/>
                  <Route path="/admin/webhooks" element={<WebhookList/>}/>
                  <Route path="/admin/invoice" element={<CustomerInvoiceList/>}/>
                  <Route path="/admin/invoice/:id" element={<CustomerInvoiceDetail/>}/>
                  <Route path="/admin/rent-contract" element={<RentContractAdminList/>}/>
            </Route>
          </Route>}

          <Route element={<Base/>}>
            <Route element={<RequireAuth/>}>
              {user &&
                <>
                  {user?.perm_dashboard &&<Route path={'/'} element={<Dashboard/>}/> }
                  <Route path="/perfil" element={<ProfileUpdate/>}/>
                  <Route path="/perfil/alterar-senha" element={<ChangePassword/>}/>
                  {/*<Route path="/documentos" element={<DocumentList/>}/>*/}
                  <Route path="/notificacoes" element={<NotificationList/>}/>

                  {perm_financial === true ? (
                    <>
                      <Route path="/faturas" element={<InvoiceList/>}/>
                      <Route path="/faturas/:id" element={<InvoiceDetail/>}/>
                      <Route path="/cobranca" element={<ChargeList/>}/>
                      <Route path="/cobranca/criar" element={<ChargeCreate/>}/>
                      <Route path="/cobranca/:id" element={<ChargeDetail/>}/>

                      <Route path="/encargos-locaticios" element={<RentalChargesList/>}/>
                      <Route path="/pagamento/encargos" element={<RentalChargePayment/>}/>
                      <Route path="/reajustes" element={<Reajust/>}/>
                      <Route path="/dimob" element={<Dimob/>}/>
                      <Route path="/dimob/:id" element={<DimobDetail/>}/>

                      {getCondoType() === 'CONDO' ? (
                        <>
                          <Route path="/despesas" element={<ExpenseList/>}/>
                          <Route path="/despesas/criar" element={<ExpenseCreate/>}/>
                          <Route path="/despesas/:id/editar" element={<ExpenseUpdate/>}/>
                          <Route path="/despesas/:id" element={<ExpenseDetails/>}/>
                        </>
                      ) : (
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                      )}

                      {getCondoType() === 'RENT' ? (
                        <>
                          <Route path="/contratos/" element={<RentList/>}/>

                          <Route path="/contratos/criar" element={<RentForm/>}/>
                          <Route path="/contratos/:id" element={<RentForm/>}/>

                          <Route path="/beneficiarios/" element={<RentRecipientList/>}/>
                          <Route path="/beneficiarios/criar" element={<RentRecipientForm/>}/>
                          <Route path="/beneficiarios/:id" element={<RentRecipientForm/>}/>

                          <Route path="/repasses/" element={<SignedRecipientTransferList/>}/>
                          <Route path="/repasses/:id" element={<SignedRecipientTransferDetail/>}/>
                          <Route path="/relatorio-geral" element={<RentReportList/>}/>
                        </>
                      ) : (
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                      )}
                    </>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}

                  {perm_bank === true ? (
                    <>
                      <Route path="/extrato" element={<BankStatement/>}/>
                      <Route path="/transferencia" element={<BankTransfer/>}/>
                      <Route path="/transferencia/ted/" element={<BankTransferDetailTed/>}/>
                      <Route path="/transferencia/pix/" element={<BankTransferDetailPix/>}/>
                      <Route path="/transferencia/:id/recibo/ted" element={<BankTransferReceiptTed/>}/>
                      <Route path="/transferencia/:id/recibo/pix" element={<BankTransferReceiptPix/>}/>
                      <Route path="/pagamento" element={<Payment/>}/>
                      <Route path="/pagamento/list" element={<PaymentList/>}/>
                    </>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}

                  {perm_config === true ? (
                    <Route element={<Settings/>}>
                      <Route path={'/configuracoes/contas'} element={<CondoList/>}/>
                      <Route path={'/configuracoes/dadosconta'} element={<CondoUpdate/>}/>
                      <Route path="/limites" element={<LimitBankDetail/>}/>
                      {perm_expense === true &&
                        <>
                          <Route path={'/configuracoes/bloco'} element={<BlockFormSet/>}/>
                          <Route path={'/configuracoes/categorias'} element={<Category/>}/>
                        </>
                      }

                      <Route path={'/configuracoes/relatorios'} element={<Report />}/>
                      <Route path={'/configuracoes/documentos'} element={<DocumentList/>}/>
                      <Route path={'/configuracoes/whatsapp'} element={<WhatsappConfig/>}/>
                    </Route>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}
                  {getCondoType() === 'RENT' ? (
                    <>
                      <Route path={'/alugueis/imoveis'} element={<UnitList/>}/>
                      <Route path={'/alugueis/imoveis/criar'} element={<UnitForm/>}/>
                      <Route path={'/alugueis/imoveis/:id/editar'} element={<UnitForm/>}/>
                    </>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}
                  <Route path="/vistoria" element={<InspectionList/>}/>
                  <Route path="/vistoria/:id" element={<InspectionDetails/>}/>

                  {getCondoType() === 'CONDO' ? (
                    <>
                      <Route path={'/condominio/imoveis'} element={<UnitList/>}/>
                      <Route path={'/condominio/imoveis/criar'} element={<UnitForm/>}/>
                      <Route path={'/condominio/imoveis/:id/editar'} element={<UnitForm/>}/>
                    </>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}

                  {perm_maintenance === true ? (
                    <>
                      <Route path="/manutencao" element={<MaintenanceCalendar/>}/>
                      <Route path="/manutencao/servicos" element={<ServiceList/>}/>
                      <Route path="/manutencao/servicos/cadastrar" element={<ServiceCreate/>}/>
                      <Route path="/manutencao/servicos/prestador/:id" element={<ServiceDetailAndUpdate/>}/>
                    </>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}

                  {perm_booking === true ? (
                    <>
                      <Route path="/reserva" element={<BookingCalendar/>}/>
                      <Route path="/reserva/areas" element={<BookingAreaList/>}/>
                    </>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}

                  {perm_billboard === true ? (
                    <Route path="/avisos" element={<BillboardList/>}/>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}

                  {perm_complain === true ? (
                    <Route element={<ComplaintMain/>}>
                      <Route path="/reclamacoes" element={<ComplaintList/>}/>
                      <Route path="/reclamacoes/registrar" element={<ComplaintCreate/>}/>
                      <Route path="/reclamacoes/detalhes/:id" element={<ComplaintDetailandUpdate/>}/>
                      <Route path="/reclamacoes/categorias/" element={<ComplaintCategory/>}/>
                    </Route>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}

                  {perm_management === true ? (
                    <Route path="/portaria" element={<ManagementList/>}/>
                  ) : (
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                  )}

                  {!!is_superuser &&
                    <Route path="/user/impersonate" element={<Impersonate/>}/>
                  }
                </>
              }

              <Route path="*" element={user && <NoMatch/>}/>
            </Route>
          </Route>
          <Route path="*" element={user && <NoMatch/>}/>
        </Routes>
      </LocalizationProvider>
    </>
  )
}

export default App
