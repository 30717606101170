import {Box, Chip, Grid, IconButton, Link, Tooltip} from '@mui/material'
import InvoiceDetailTitle from "./dependencies/InvoiceDetailTitle";
import InvoiceDetailStatus from "./dependencies/InvoiceDetailStatus";
import InvoiceDetailDue from "./dependencies/InvoiceDetailDue";
import InvoiceDetailDownload from "./dependencies/InvoiceDetailDownload";
import InvoiceDetailSentMail from "./dependencies/InvoiceDetailSentMail";
import Divider from "@mui/material/Divider";
import React, {useContext, useState} from "react";
import InvoiceDetailGenerateSlip from "./dependencies/InvoiceDetailGenerateSlip";
import InvoiceDetailDeleteSlip from "./dependencies/InvoiceDetailDeleteSlip";
import InvoiceDetailDownloadRentReceipt from "./dependencies/InvoiceDetailDowloadRentReceipt";
import {NoteAdd, OpenInNew} from '@mui/icons-material'
import Context from "../../../../../../contexts/Context";
import MakeBankSlip from "../../MakeItPaidMenu/dependencies/MakeBankSlip";
import {useSnackbar} from "notistack";
import {api} from "../../../../../../services/Main/Api";
import WhatsAppMessageComponent from "../../../../Whatsapp/WhatsAppMessageComponent";
import EmailMessageComponent from "../../../../Emails/EmailMessageComponent";
import MakeItPaidMenu from "../../../MakeItPaidMenu";
import moment from "moment";
import UpdateIcon from "@mui/icons-material/Update";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
const InvoiceDetailHeader = ({ invoice, sending, sendToMail, condo, handleManualPayment }) => {
    const { user } = useContext(Context)
    const { perm_bank } = user || {}
    const {setLoadingModal} = useContext(Context)
    const [isOpen, setIsOpen] = useState(false)
    const [openByStatus, setOpenByStatus] = useState({paid: false, remake: false, create: false, open: false, deleted: false})
    const [reqNewInvoice, setReqNewInvoice] = useState({ send_email: false, send_whatsapp: false, due_date: ''})
    const [reqCreateInvoice, setReqCreateInvoice] = useState({ send_email: false, send_whatsapp: false, due_date: ''})
    const [errors, setErrors] = useState(null)
    const [anchor, setAnchor] = useState(null)
    const {setInvoiceListRefresh} = useContext(Context)
    const {enqueueSnackbar} = useSnackbar()

    const handleCloseConfirmation = (e) => {
        setOpenByStatus({paid: false, remake: false, create: false, open: false, deleted: false})
        setIsOpen(false)
    }

    const generate = () => {
        setLoadingModal(true)
        api.post(`bank-slip/invoice/${invoice?.id}/`, reqCreateInvoice)
            .then(response =>{
                setLoadingModal(false)
                enqueueSnackbar("Pedido para gerar boleto efetuado!", {variant: 'success'})
                enqueueSnackbar("Processando boleto, disponivel em breve!", {variant: 'success'})
            }).catch(response => {
            enqueueSnackbar("Informe uma data válida", {variant: "error"})
            setErrors(response)
            console.log(response.response.data)
            setLoadingModal(false)
        }).finally(() => {
            setInvoiceListRefresh(true)
        })
    }

    return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
      >
        <InvoiceDetailTitle
          invoice={invoice}
        />
          <Box
              sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '.5rem'
              }}
          >
              <Box>
                  {invoice?.new_payment_page?.key === "generate_bankslip_day" && invoice?.paid_by !== 'MANUAL' ? (
                      <Tooltip title={`Essa fatura será gerada em ${moment(invoice.new_payment_page.value).format('DD/MM/YYYY')}`}>
                          <Chip
                              label={moment(invoice.new_payment_page.value).format('DD/MM/YYYY')}
                              size="small"
                              icon={<UpdateIcon style={{ color: 'grey' }} />}
                          />
                      </Tooltip>
                  ) : invoice?.due_date ? (
                      <Tooltip
                          title={
                              moment(invoice.due_date).isBefore(moment())
                                  ? `Essa fatura venceu em ${moment(invoice.due_date).format('DD/MM/YYYY')}`
                                  : `Essa fatura irá vencer em ${moment(invoice.due_date).format('DD/MM/YYYY')}`
                          }
                      >
                          <Chip
                              label={moment(invoice.due_date).format('DD/MM/YYYY')}
                              size="small"
                              icon={<EventAvailableIcon style={{ color: 'grey' }} />}
                          />
                      </Tooltip>
                  ) : (
                      <Box></Box>
                  )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {condo?.type === 'RENT' &&
                      condo?.whatsapp_send_message &&
                      (invoice?.status.key === "paid" ||
                          invoice?.status.key === "pending" ||
                          invoice?.status.key === "overdue") && (
                          <WhatsAppMessageComponent data={invoice?.whatsapp_message} />
                      )}

                  {(invoice?.status.key === "paid" ||
                      invoice?.status.key === "pending" ||
                      invoice?.status.key === "overdue") && (
                      <EmailMessageComponent data={invoice?.email_message} />
                  )}
              </Box>
        <InvoiceDetailStatus
          invoice={invoice}
        />
              <Box sx={{ flexGrow: 1 }}>
              {(invoice?.status.key !== 'paid' && invoice?.status.key !== 'processing' ||
                      invoice?.paid_by === 'MANUAL') &&
                  <MakeItPaidMenu
                      invoice={invoice}
                      onSuccess={handleManualPayment}
                      sendToMail={sendToMail}
                  />
              }
              </Box>
          </Box>
        <Grid
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          display={'flex'}
          textAlign={'right'}
        >
          <InvoiceDetailDue
            invoice={invoice}
          />
            { perm_bank === true &&
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    {invoice?.status.key !== 'open' && invoice?.status.key !== 'processing' && (
                        <Link
                            href={invoice?.payment_page}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Chip
                                label={'Página de pagamento'}
                                size={'small'}
                                icon={<OpenInNew />}
                            />
                        </Link>
                    )}
                    {condo?.type === 'RENT' && invoice?.status?.key === 'paid' && (
                        <InvoiceDetailDownloadRentReceipt invoice={invoice} />
                    )}
                </Box>

            }
        </Grid>
      </Grid>
      <Divider
        sx={{
          mb: '2rem',
          mt: '.5rem'
        }}
      />
    </>
  )
}

export default InvoiceDetailHeader