import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    LinearProgress,
    Grid,
    Chip
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import {api} from "../services/Main/Api";

const DropzoneImage = ({ onUploadComplete, characteristicId, apiEndpoint }) => {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState({});

    // Função para fazer upload de um único arquivo
    const uploadFile = async (file) => {
        // Atualiza o status do arquivo para 'uploading'
        setFiles(prev => prev.map(f =>
            f.id === file.id ? { ...f, status: 'uploading' } : f
        ));

        // Inicializa o progresso
        setUploadProgress(prev => ({ ...prev, [file.id]: 0 }));

        const formData = new FormData();
        formData.append('image', file);
        formData.append('characteristic', characteristicId);

        try {
            setUploading(true);
            const response = await api.post(apiEndpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );

                    setUploadProgress(prev => ({
                        ...prev,
                        [file.id]: percentCompleted
                    }));
                }
            });

            // Atualiza o status do arquivo para 'success'
            setFiles(prev => prev.map(f =>
                f.id === file.id ? { ...f, status: 'success', progress: 100 } : f
            ));

            // Notifica o componente pai sobre o upload completo
            if (onUploadComplete) {
                onUploadComplete(response.data);
            }

            return response.data;
        } catch (error) {
            console.error('Erro no upload:', error);

            // Atualiza o status do arquivo para 'error'
            setFiles(prev => prev.map(f =>
                f.id === file.id ? { ...f, status: 'error', error: error.message } : f
            ));

            throw error;
        } finally {
            setUploading(false);
        }
    };

    // Função para lidar com os arquivos soltos/selecionados
    const onDrop = useCallback(async (acceptedFiles) => {
        // Adiciona metadados aos arquivos
        const newFiles = acceptedFiles.map(file => Object.assign(file, {
            id: Math.random().toString(36).substring(2),
            preview: URL.createObjectURL(file),
            progress: 0,
            status: 'pending', // pending, uploading, success, error
            error: null
        }));

        // Adiciona os novos arquivos ao estado
        setFiles(prev => [...prev, ...newFiles]);

        // Faz upload de cada arquivo sequencialmente
        for (const file of newFiles) {
            try {
                await uploadFile(file);
            } catch (error) {
                // Continua para o próximo arquivo mesmo se houver erro
                console.error(`Falha ao enviar ${file.name}:`, error);
            }
        }
    }, [characteristicId, apiEndpoint, onUploadComplete]);

    // Configuração do dropzone
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', 'heic'],
        },
        multiple: true,
        disabled: uploading, // Desabilita o dropzone durante o upload
        noClick: uploading, // Impede cliques durante o upload
    });

    // Limpa as URLs de preview quando o componente é desmontado
    useEffect(() => {
        return () => {
            files.forEach(file => {
                if (file.preview) URL.revokeObjectURL(file.preview);
            });
        };
    }, [files]);

    // Renderiza a lista de arquivos
    const renderFileList = () => {
        if (files.length === 0) return null;

        return (
            <List sx={{ width: '100%', mt: 2 }}>
                {files.map((file) => (
                    <ListItem
                        key={file.id}
                        sx={{
                            border: '1px solid #eee',
                            borderRadius: 1,
                            mb: 1,
                            bgcolor: file.status === 'success' ? 'rgba(76, 175, 80, 0.1)' :
                                file.status === 'error' ? 'rgba(244, 67, 54, 0.1)' : 'white'
                        }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={2} sm={1}>
                                {file.preview ? (
                                    <Box
                                        component="img"
                                        src={file.preview}
                                        sx={{ width: 40, height: 40, objectFit: 'cover', borderRadius: 1 }}
                                    />
                                ) : (
                                    <Box sx={{ width: 40, height: 40, bgcolor: '#eee', borderRadius: 1 }} />
                                )}
                            </Grid>

                            <Grid item xs={7} sm={9}>
                                <Typography variant="body2" noWrap title={file.name}>
                                    {file.name}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {file.size ? `${(file.size / 1024).toFixed(1)} KB` : 'Tamanho desconhecido'}
                                </Typography>

                                {(file.status === 'uploading' || file.status === 'pending') && (
                                    <LinearProgress
                                        variant={file.status === 'uploading' ? "determinate" : "indeterminate"}
                                        value={uploadProgress[file.id] || 0}
                                        sx={{ mt: 1, height: 5, borderRadius: 5 }}
                                    />
                                )}

                                {file.status === 'error' && (
                                    <Typography variant="caption" color="error">
                                        Erro: {file.error || 'Falha no upload'}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item xs={3} sm={2} sx={{ textAlign: 'right' }}>
                                {file.status === 'success' ? (
                                    <CheckCircleIcon color="success" />
                                ) : file.status === 'error' ? (
                                    <ErrorIcon color="error" />
                                ) : (
                                    <Chip
                                        label={file.status === 'uploading' ? `${uploadProgress[file.id] || 0}%` : 'Pendente'}
                                        size="small"
                                        color={file.status === 'uploading' ? "primary" : "default"}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper
                {...getRootProps()}
                sx={{
                    border: '2px dashed #ccc',
                    borderRadius: 2,
                    padding: 3,
                    textAlign: 'center',
                    cursor: uploading ? 'not-allowed' : 'pointer',
                    backgroundColor: isDragActive ? '#fafafa' : 'white',
                    opacity: uploading ? 0.7 : 1,
                    '&:hover': {
                        backgroundColor: uploading ? 'white' : '#fafafa'
                    }
                }}
            >
                <input {...getInputProps()} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2
                    }}
                >
                    <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main' }} />
                    <Typography variant="h6" color="textSecondary">
                        {isDragActive
                            ? 'Solte os arquivos aqui...'
                            : uploading
                                ? 'Upload em andamento...'
                                : 'Arraste e solte arquivos aqui, ou clique para selecionar'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        Suporta: JPG e PNG
                    </Typography>

                    {uploading && (
                        <Typography variant="caption" color="primary">
                            Enviando arquivos, por favor aguarde...
                        </Typography>
                    )}
                </Box>
            </Paper>

            {renderFileList()}
        </Box>
    );
};

export default DropzoneImage;