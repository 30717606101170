import React, {useEffect, useState} from 'react';
import {
    TextField,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
    InputLabel, IconButton
} from '@mui/material';
import {Box} from "@mui/system";
import BankSelector from "../../BankOperations/components/BankTransfer/BankSelector";
import {getBanks} from "../../BankOperations/components/BankTransfer/Requisitions";
import DeleteIcon from "@mui/icons-material/Delete";
import Masks from "../../../../components/Shared/Masks";
import axios from 'axios'

const RentRecipientFormSet = ({rentRecipients, setRentRecipients, rentRecipient, setRentRecipient, error, setError, ...props}) => {
    const [bankNames, setBankNames] = useState(null)
    const [bankData, setBankData] = useState(null)
    const { index, onChange, onRemove, id, accUpdate, accCreate, deleteAccById, getAcc, ...dataProps } = props;
    const { bank, bank_branch, bank_account, bank_account_digit, bank_account_type, bank_branch_digit } = dataProps
    const [cpfCnpjError, setCpfCnpjError] = useState(null);
    const [recipientData, setRecipientData] = useState(null);

    useEffect(function bankNames(){
        getBanks(setBankNames)
    }, [])

    const bankOptions = bankNames?.map((option, index) => ({ name: option.name , code: option.code, key: index}))

    const addRentRecipient = () => {
        let totalPercent = 0;
        for(let i = 0; i < rentRecipient.length; i++) {
            totalPercent += Number(rentRecipient[i].percent);
        }

        let availablePercent = totalPercent > 100 ? 0 : 100 - totalPercent;

        setRentRecipient([...rentRecipient, {
            name: '',
            cpf: '',
            phone: '',
            email: '',
            percent: availablePercent,
            pix_or_ted: 'PIX',
            pix_key: '',
            bank: '',
            branch: '',
            branch_digit: '',
            account: '',
            account_digit: '',
            account_type: '1',
        }]);
    };

    const createRentRecipient = (index, field, value) => {
        const newRentRecipient = [...rentRecipient];
        newRentRecipient[index][field] = value;
        setRentRecipient(newRentRecipient);
    };

    useEffect(function setBank() {
        if (rentRecipient) {
            
            setRentRecipients(rentRecipient);
        }
    }, [rentRecipient]);


    const removeRentRecipient = (indexToRemove) => {
        setRentRecipient(rentRecipient.filter((_, index) => index !== indexToRemove));
    };

    function isValidCPF(cpf) {
        if(typeof cpf !== "string") return false;
        cpf = cpf.replace(/[\s.-]*/gim, '');
        if (
          !cpf ||
          cpf.length != 11 ||
          cpf == "00000000000" ||
          cpf == "11111111111" ||
          cpf == "22222222222" ||
          cpf == "33333333333" ||
          cpf == "44444444444" ||
          cpf == "55555555555" ||
          cpf == "66666666666" ||
          cpf == "77777777777" ||
          cpf == "88888888888" ||
          cpf == "99999999999"
        ) {
            return false;
        }
        var soma = 0;
        var resto;
        for (var i = 1; i <= 9; i++)
            soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
        resto = (soma * 10) % 11;
        if ((resto == 10) || (resto == 11))  resto = 0;
        if (resto != parseInt(cpf.substring(9, 10)) ) return false;
        soma = 0;
        for (var i = 1; i <= 10; i++)
            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
        resto = (soma * 10) % 11;
        if ((resto == 10) || (resto == 11))  resto = 0;
        if (resto != parseInt(cpf.substring(10, 11) ) ) return false;
        return true;
    }

    function isValidCNPJ(cnpj) {

        if(typeof cnpj !== "string") return false
        cnpj = cnpj.replace(/[\s.-]*/gim, '')

        var pesos = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
        var base = 0
        var resultado = 0
        var resto = 0

        if (cnpj.length !== 14)
            return false

        for (var i = 0; i < 12; i++ ) {
            base += (cnpj[i] * pesos[i+1])
        }
        resto = (base % 11)

        if (cnpj[12] != (resto < 2 ? 0 : 11 - resto))
            return false

        base = 0
        resto = 0

        for (var i = 0; i < 13; i++) {
            base += (cnpj[i] * pesos[i])
        }
        resto = (base % 11)

        return cnpj[13] == (resto < 2 ? 0 : 11 - resto)
    }

    function validateCPFAndCNPJ(value) {
        const cleaned = ("" + value).replace(/\D/g, "");
        const isCpf = cleaned.length === 11 && isValidCPF(cleaned);
        const isCnpj = cleaned.length === 14 && isValidCNPJ(cleaned);

        return isCpf || isCnpj;
    }

    const handleCpfCnpjChange = (value) => {

        let cleanValue = value.replace(/\D/g, "");

        if (cleanValue.length > 11) {
            value = Masks?.cnpj(cleanValue);
        } else if (cleanValue.length > 0) {
            value = Masks?.cpf(cleanValue);
        }

        setRecipientData(prevData => ({ ...prevData, cpf: value }));

        if (cleanValue.length >= 11) {
            if (!validateCPFAndCNPJ(cleanValue)) {
                setCpfCnpjError('CPF ou CNPJ inválido');
            } else {
                setCpfCnpjError(null);
            }
        } else {
            setCpfCnpjError(null);
        }
    }

    const formatPixKeyDisplay = (pixKey, cpf) => {
        let cleanedCpfOrCnpj = cpf?.replace(/[^a-zA-Z0-9]/g, "");
        let cleanedPixKey = pixKey?.replace(/[^a-zA-Z0-9]/g, "");

        // Se cleanedPixKey é igual a cleanedCpfOrCnpj verificamos o tamanho e formatamos como cpf ou cnpj.
        if(cleanedPixKey === cleanedCpfOrCnpj){
            if(cleanedPixKey?.length === 11){
                return Masks.cpf(pixKey ?? '');
            }
            else if (cleanedPixKey?.length === 14){
                return Masks.cnpj(pixKey ?? '');
            }
        }

        // Se cleanedPixKey não é igual a cleanedCpfOrCnpj, verificamos se cleanedPixKey é cpf ou cnpj válido.
        else {
            if (cleanedPixKey?.length === 11 && isValidCPF(cleanedPixKey)){
                return Masks.cpf(pixKey ?? '');
            }
            else if (cleanedPixKey?.length === 14 && isValidCNPJ(cleanedPixKey)){ // Você deve implementar a função isValidCNPJ similar a isValidCPF
                return Masks.cnpj(pixKey ?? '');
            }
            else if (cleanedPixKey?.length === 11 && (/^\d+$/.test(cleanedPixKey))) {
                return Masks.phone(pixKey ?? '');
            }
        }

        return pixKey;
    };

    const renderRentRecipient = () => {
        return rentRecipient.map((rentRecipient, index) => (
            <div key={index} style={{border: '1px solid #ccc', padding: '15px', marginBottom: '15px', borderRadius: '10px'}}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box>
                        <h4>Beneficiário - {rentRecipient.percent}%</h4>
                    </Box>
                    <Box>
                        <IconButton

                            onClick={() => {
                                removeRentRecipient(index)}
                            }
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                >
                    <Box width="48%">
                        <TextField
                            label="Nome"
                            fullWidth
                            size={'small'}
                            value={rentRecipient.name}
                            onChange={(e) => {
                                createRentRecipient(index, 'name', e.target.value)
                            }}
                            style={{marginBottom: '10px'}}
                            error={error !== null}
                            helperText={error?.data?.name}
                        />
                    </Box>

                    <Box width="48%">
                        <TextField
                          label={'CPF / CNPJ'}
                          required
                          state={recipientData}
                          setState={setRecipientData}
                          keyName={'cpf_cnpj'}
                          value={rentRecipient?.cpf}
                          onChange={(e) => {
                              handleCpfCnpjChange(e.target.value)
                              createRentRecipient(index, 'cpf', e.target.value)
                          }}
                          error={!!cpfCnpjError}
                          helperText={cpfCnpjError}
                          fullWidth
                          size={'small'}
                          sx={{ mb: '1rem'}}
                        />

                    </Box>

                    <Box width="48%">
                        <TextField
                            label="Telefone"
                            fullWidth
                            size={'small'}
                            value={Masks?.phone(String(rentRecipient?.phone)) ?? ''}
                            onChange={(e) => createRentRecipient(index, 'phone', e.target.value)}
                            style={{marginBottom: '10px'}}
                        />
                    </Box>

                    <Box width="48%">
                        <TextField
                            label="Email"
                            fullWidth
                            size={'small'}
                            value={rentRecipient.email}
                            onChange={(e) => createRentRecipient(index, 'email', e.target.value)}
                            style={{marginBottom: '10px'}}
                            error={error !== null}
                            helperText={error?.data?.email}
                        />
                    </Box>
                    <Box width="48%">
                        <TextField
                            label="Porcentagem"
                            fullWidth
                            size={'small'}
                            value={rentRecipient.percent}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value)) {
                                    createRentRecipient(index, 'percent', value);
                                }
                            }}
                            style={{marginBottom: '10px'}}
                        />
                    </Box>
                </Box>

                <h5>Adicionar Chave Pix (PIX) / Conta Bancária (TED)</h5>
                <RadioGroup
                    row
                    value={rentRecipient.pix_or_ted}
                    onChange={(e) => createRentRecipient(index, 'pix_or_ted', e.target.value)}
                >
                    <FormControlLabel value="PIX" control={<Radio/>} label="Chave Pix"/>
                    <FormControlLabel value="TED" control={<Radio/>} label="Conta Bancária"/>
                </RadioGroup>

                {/* Fields for Pix Key */}
                {rentRecipient.pix_or_ted === 'PIX' && (
                    <TextField
                        label="Chave Pix"
                        fullWidth
                        size={'small'}
                        value={formatPixKeyDisplay(rentRecipient.pix_key, rentRecipient.cpf)}
                        onChange={(e) => {
                            createRentRecipient(index, 'pix_key', e.target.value)
                        }}
                        style={{marginBottom: '10px'}}
                        error={error !== null}
                        helperText={error?.data?.pix_key}
                    />
                )}

                {/* Fields for TED Account */}
                {rentRecipient.pix_or_ted === 'TED' && (
                    <>
                        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
                            <Box width="100%">
                                <BankSelector
                                    bankNames={bankNames}
                                    reqData={bankData}
                                    setReqData={(bankUpdateFunction) => {
                                        const newBankData = bankUpdateFunction(bankData);
                                        setBankData(newBankData);
                                        createRentRecipient(index, 'bank', newBankData.bank);
                                    }}
                                    defaultValue={bankOptions?.find(e => e?.code === bank?.code)}
                                    value={rentRecipient.bank}
                                    error={error !== null}
                                    helperText={error?.data?.bank}
                                />
                            </Box>

                            <Box width="48%">
                                <Box display="flex" justifyContent="space-between">

                                    <TextField
                                        label="Agência"
                                        fullWidth
                                        size={'small'}
                                        value={rentRecipient.branch}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                            createRentRecipient(index, 'branch', value);
                                        }}
                                        style={{ width: '48%', marginBottom: '5px' }}
                                        error={error !== null}
                                        helperText={error?.data?.branch}
                                    />
                                    {bankData?.bank === '001' &&
                                    <TextField
                                        label="Digíto"
                                        fullWidth
                                        size={'small'}
                                        value={rentRecipient.branch_digit}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                            createRentRecipient(index, 'branch_digit', value);
                                        }}
                                        style={{ width: '48%', marginBottom: '5px' }}

                                    />
                                    }
                                </Box>
                            </Box>

                            <Box width="48%">
                                <Box display="flex" justifyContent="space-between">

                                    <TextField
                                        label="Conta"
                                        fullWidth
                                        size={'small'}
                                        value={rentRecipient.account}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                            createRentRecipient(index, 'account', value);
                                        }}
                                        style={{ width: '48%', marginBottom: '5px' }}
                                        error={error !== null}
                                        helperText={error?.data?.account}
                                    />

                                    <TextField
                                        label="Digíto"
                                        fullWidth
                                        size={'small'}
                                        value={rentRecipient.account_digit}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, '');
                                            createRentRecipient(index, 'account_digit', value);
                                        }}
                                        style={{ width: '48%', marginBottom: '5px' }}
                                        error={error !== null}
                                        helperText={error?.data?.account_digit}
                                    />

                                </Box>
                            </Box>

                            <Box width="100%" style={{marginBottom: '5px'}}>
                                <RadioGroup
                                    row
                                    value={rentRecipient.account_type}
                                    onChange={(e) => createRentRecipient(index, 'account_type', e.target.value)}
                                >
                                    <FormControlLabel value="0" control={<Radio/>} label="Conta Corrente"/>
                                    <FormControlLabel value="1" control={<Radio/>} label="Conta Poupança"/>
                                </RadioGroup>
                            </Box>
                        </Box>
                    </>
                )}
            </div>
        ));
    };

    return (
        <div>
            {renderRentRecipient()}

            <Button variant="outlined" color="primary" onClick={addRentRecipient}>
                Adicionar outro beneficiário
            </Button>
        </div>
    );
};

export default RentRecipientFormSet;
