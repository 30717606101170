import React, {useContext, useEffect, useState} from "react";
import Context from "../../../contexts/Context";
import {getCondoId} from "../../../services/Main/AuthStorage";
import {api} from "../../../services/Main/Api";
import {Helmet} from "react-helmet";
import {
  Backdrop,
  Box, Button, Card, CardContent, Chip, Container, Grid, MenuItem, Skeleton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
  TextField, Tooltip, Typography, useMediaQuery
} from '@mui/material'


import SearchInput from "../../../components/Filters/SearchInput";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";

import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment/moment";
import Masks from "../../../components/Shared/Masks";
import WhatsAppMessageComponent from "../Whatsapp/WhatsAppMessageComponent";
import EmailMessageComponent from "../Emails/EmailMessageComponent";
import SignedRecipientTransferMakeMenu from "../SignedRecipientTransfer/SignedRecipientTransferMakeMenu";
import Divider from "@mui/material/Divider";
import {Add} from "@mui/icons-material";
import InspectionForm from "./InspectionForm";

const InspectionList = () => {
  const { filters, setFilters } = useContext(Context)
  const { user } = useContext(Context)
  const {
    is_superuser,
  } = user || {}
  const [data, setData] = useState(null)
  const [total, setTotal] = useState(0)
  const [openDelete, setOpenDelete] = useState(false)
  const [openForm, setOpenForm] = useState(false)
  const [openAIFormRent, setOpenAIFormRent] = useState(false)
  const [reqLoading, setReqLoading] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(['active', 'pending', 'inactivating']);



  function initialFilters () {
    return setFilters(prevState => ({
      ...prevState,
      page: 1,
      page_size: 50,
      unit__condo_id: +getCondoId(),
      search: '',
      // status: ''
    }))
  }


  useEffect(function filterSet () {
    initialFilters()

  }, [])


  function listRents() {
    if (filters?.unit__condo_id) {
      let params = new URLSearchParams();

      params.append('unit__condo_id', filters?.condo_id);
      params.append('page', filters?.page);
      params.append('page_size', filters?.page_size);
      params.append('search', filters?.search);

      api.get(`/property-inspection/`, { params: params })
        .then(response => {
          setData(response.data);
          setTotal(response.data.count);
        })
        .catch(error => {
          console.log(error);
        });

    }
  }






  useEffect(function firstLoad(){
    if (filters){
      listRents()
    }
  }, [filters, selectedStatus])






  return (
   <>
     <Helmet>
       <title>Vistoria - Bloco B3</title>
     </Helmet>
     { openForm &&
       <InspectionForm
        closeForm={()=>{
          setOpenForm(false)
        }}
       />
     }



     <Backdrop
       sx={{ color: '#fff', zIndex: 1500, }}
       open={reqLoading}
     >
       <CircularProgress color="inherit"/>
     </Backdrop>
     <Container
       maxWidth={false}
     >
       <Box
         sx={{
           display: 'flex',
           '@media (max-width:600px)': {
             display: 'grid',
             gridTemplateColumns: 'repeat(1, 1fr)',
             gap: 3,
           },
           justifyContent: 'space-between',
           alignItems: 'center',
           mb: 3,

         }}
       >
         <Typography
           textAlign={'center'}
           variant={'h5'}
         >
           Vistorias ({total})
         </Typography>

           <Box
             sx={{
               '@media (max-width:600px)': {
                 display: 'grid',
                 gridTemplateColumns: 'repeat(1, 1fr)',
                 gap: 2,

               }
             }}
           >

             <Button
               variant={'contained'}

               size={'small'}
               onClick={() => {
                 setOpenForm(true);
               }}
             >
               <Add /> { `Criar Vistoria` }
             </Button>

           </Box>

       </Box>

       <Card>
         {data ?
           <CardContent>
             <Grid container spacing={2} >

               <Grid item xs={3} sx={{justifyContent: 'end', display:'flex', alignItems: 'center'}} >



               </Grid>
             </Grid>

          <Divider sx={{my:3}} />


             <TableContainer>
               {data?.results?.length > 0 ?
                   <Table>
                     <TableHead>
                       <TableRow>
                         <TableCell>
                           <TableSortLabel
                               style={{fontSize: '14px'}}
                               active={filters?.ordering === '-id' || filters?.ordering === 'id'}
                               direction={filters?.ordering === '-id' ? 'desc' : 'asc'}
                               onClick={() => handleFilter('id', '-id')}
                           >#
                           </TableSortLabel>
                         </TableCell>
                         <TableCell>
                           <TableSortLabel
                               style={{fontSize: '14px'}}
                               active={filters?.ordering === '-unit__name' || filters?.ordering === 'unit__name'}
                               direction={filters?.ordering === '-unit__name' ? 'desc' : 'asc'}
                               onClick={() => handleFilter('unit__name', '-unit__name')}
                           >Nome
                           </TableSortLabel>
                         </TableCell>

                         <TableCell>
                           <TableSortLabel
                               style={{fontSize: '14px'}}
                               active={filters?.ordering === '-unit__name' || filters?.ordering === 'unit__name'}
                               direction={filters?.ordering === '-unit__name' ? 'desc' : 'asc'}
                               onClick={() => handleFilter('unit__name', '-unit__name')}
                           >Contrato
                           </TableSortLabel>
                         </TableCell>

                         <TableCell align={"center"}>
                           <TableSortLabel
                               style={{fontSize: '14px'}}
                               active={filters?.ordering === '-status' || filters?.ordering === 'status'}
                               direction={filters?.ordering === '-status' ? 'desc' : 'asc'}
                               onClick={() => handleFilter('status', '-status')}
                           >
                             Status
                           </TableSortLabel>
                         </TableCell>

                         <TableCell align={"center"}>
                           <TableSortLabel
                               style={{fontSize: '14px'}}
                               active={filters?.ordering === '-status' || filters?.ordering === 'status'}
                               direction={filters?.ordering === '-status' ? 'desc' : 'asc'}
                               onClick={() => handleFilter('status', '-status')}
                           >
                             Criado em
                           </TableSortLabel>
                         </TableCell>
                         <TableCell>Laudo</TableCell>
                         <TableCell></TableCell>

                       </TableRow>
                     </TableHead>
                     <TableBody>


                       {data.results.map(item =>
                           <TableRow
                               key={item.id}
                               hover
                               sx={{'cursor': 'pointer'}}

                               onClick={(e) => {
                                 e.stopPropagation()
                                 // handleManualPayment = {handleManualPayment}
                                 //navigate(`/repasses/${item.id}`)
                               }}
                           >
                             <TableCell>
                               <Box
                                   sx={{
                                     display: 'flex',
                                     alignItems: 'center'
                                   }}
                               >
                                 {item.number}
                               </Box>
                             </TableCell>
                             <TableCell>
                               <Box
                                   sx={{
                                     display: 'flex',
                                     alignItems: 'center'
                                   }}
                               >
                                 {item.name}
                               </Box>
                             </TableCell>

                               <TableCell>
                                   <Box
                                       sx={{
                                           display: 'flex',
                                           alignItems: 'center'
                                       }}
                                   >
                                       {item.rent_contract}
                                   </Box>
                               </TableCell>

                               <TableCell>
                                   <Box
                                       sx={{
                                           display: 'flex',
                                           alignItems: 'center'
                                       }}
                                   >
                                       {item.status}
                                   </Box>
                               </TableCell>

                               <TableCell>
                                   <Box
                                       sx={{
                                           display: 'flex',
                                           alignItems: 'center'
                                       }}
                                   >
                                       {moment(item.created_at).format("DD/MM/YYYY [às] HH:mm:ss")}
                                   </Box>
                               </TableCell>

                             {/*<TableCell>*/}
                             {/*  <Box*/}
                             {/*      sx={{*/}
                             {/*        display: 'flex',*/}
                             {/*        alignItems: 'center'*/}
                             {/*      }}*/}
                             {/*      color={item?.total < 0 && "error.main"}*/}
                             {/*  >*/}
                             {/*    {Masks.money(item?.total.toString())}*/}

                             {/*  </Box>*/}
                             {/*</TableCell>*/}

                           </TableRow>
                       )}

                     </TableBody>
                   </Table>
                   :
                   <NoResultsAnimation />
               }
             </TableContainer>
         </CardContent>
             :
             <Skeleton
               variant="rounded"
               height={700}
               animation="pulse"
             />

           }
       </Card>

       <PaginationGlobal
         state={data}
         filters={filters}
         setFilters={setFilters}
       />



     </Container>

   </>
 )
}

export default InspectionList