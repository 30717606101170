import React, {
  useEffect,
  useState
} from "react";
import SimpleTypography from "../../../../../components/Shared/SimpleTypography";
import {getCondoId} from "../../../../../services/Main/AuthStorage";
import {getAccountData} from "./Requisitions";
import Masks from "../../../../../components/Shared/Masks";
import {Close} from "@mui/icons-material";
import { Tooltip } from '@mui/material'

const AccountReadInfo = ({ balanceStyles }) => {

  const [accountData, setAccountData] = useState(null)

  useEffect(function accountBalance () {
    if (getCondoId()){
      getAccountData(setAccountData)
    }
  }, [])


  return (
    <>
      <SimpleTypography
        label={'Banco'}
        directValue={
          accountData?.bank ? (
            <Tooltip title="Copiar banco" arrow>
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'none', // Sem sublinhado por padrão
          }}
          onClick={() => navigator.clipboard.writeText(`${accountData.bank.code}`)}
          onMouseEnter={(e) => {
            e.currentTarget.style.textDecoration = 'underline'; // Sublinha no hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.textDecoration = 'none'; // Remove quando sai do hover
          }}
        >
          {`${accountData.bank.name} - ${accountData.bank.code}`}
        </span>
            </Tooltip>
          ) : (
            <Close sx={{ color: 'error.main' }} />
          )
        }
        isLoading={!accountData?.balance}
        boxStyles={{ mb: '1rem' }}
        labelStyles={{ fontWeight: 'bold'}}
      />
      <SimpleTypography
        label={'Agência'}
        directValue={
          accountData?.bank_branch ? (
            <Tooltip title="Copiar agência" arrow>
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
          }}
          onClick={() => navigator.clipboard.writeText(accountData.bank_branch)}
          onMouseEnter={(e) => {
            e.currentTarget.style.textDecoration = 'underline';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.textDecoration = 'none';
          }}
        >
          {accountData.bank_branch}
        </span>
            </Tooltip>
          ) : (
            <Close sx={{ color: 'error.main' }} />
          )
        }
        isLoading={!accountData?.balance}
        boxStyles={{ mb: '1rem' }}
        labelStyles={{ fontWeight: 'bold'}}
      />

      <SimpleTypography
        label={'Conta'}
        directValue={
          accountData?.bank_account ? (
            <Tooltip title="Copiar conta" arrow>
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
          }}
          onClick={() => navigator.clipboard.writeText(accountData.bank_account)}
          onMouseEnter={(e) => {
            e.currentTarget.style.textDecoration = 'underline';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.textDecoration = 'none';
          }}
        >
          {accountData.bank_account}
        </span>
            </Tooltip>
          ) : (
            <Close sx={{ color: 'error.main' }} />
          )
        }
        isLoading={!accountData?.balance}
        boxStyles={{ mb: '1rem' }}
        labelStyles={{ fontWeight: 'bold'}}
      />

      <SimpleTypography
        label={'Chave Pix'}
        directValue={
          accountData?.pix_key_company ? (
            <Tooltip title="Copiar chave Pix" arrow>
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
          }}
          onClick={() => navigator.clipboard.writeText(accountData.pix_key_company)}
          onMouseEnter={(e) => {
            e.currentTarget.style.textDecoration = 'underline';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.textDecoration = 'none';
          }}
        >
          {accountData.pix_key_company}
        </span>
            </Tooltip>
          ) : (
            <Close sx={{ color: 'error.main' }} />
          )
        }
        isLoading={!accountData?.balance}
        boxStyles={{ mb: '1rem' }}
        labelStyles={{ fontWeight: 'bold'}}
      />




      <SimpleTypography
        label={'Saldo'}
        directValue={Masks?.money(accountData?.balance?.toString() ?? '') ?? <Close sx={{color: 'error.main'}}/>}
        isLoading={!accountData?.balance}
        boxStyles={balanceStyles}
        labelStyles={{ fontWeight: 'bold'}}

      />
    </>
  )
}

export default AccountReadInfo