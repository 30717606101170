import React, { useEffect, useState } from 'react'
import {
  Backdrop,
  Box, Button,
  Card,
  CardHeader,
  Chip,
  Container, Dialog, DialogContent, Divider, FormControl, Grid, InputLabel, Menu, MenuItem, OutlinedInput, Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import PaginationGlobal from '../../../components/Shared/PaginationGlobal'
import { CalendarIcon } from '@mui/x-date-pickers'
import { DateRangePicker } from 'react-date-range'
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subDays, subMonths } from 'date-fns'

const TransactionList = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState(null)
  const [transactionResult, setTransactionResult] = useState([])
  const [monthInput, setMonthInput] = useState(moment().format("MM"))
  const [yearInput, setYearInput] = useState(moment().format("YYYY"))
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams();
  const [listCondos, setListCondos] = useState([]);
  const [condo, setCondo] = useState(null)
  const [filters, setFilters] = useState(
    {
      page: 1,
      page_size: 100,
      condo_id: condo,
      status: null,
      event:null
    }
  )
  const [dataRange, setDateRange] = useState([
    {
      startDate: subMonths(new Date(), 12),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const [startDate, setStartDate] = useState(null)
  const [type, setType] = useState(null)
  const [reqEvent, setReqEvent] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const getCurrentMonthRange = () => ({
    startDate: startOfMonth(new Date()),  // Início do mês atual
    endDate: endOfMonth(new Date()),      // Fim do mês atual
  });

  useEffect(() => {
    const fetchCondos = async () => {

      api.get(`condo-select/`)
          .then(response => {
            const data = response.data

            setListCondos(data);

            //setLoading(false)
          }).catch(error => {
        console.log(error)
        //setLoading(false)
      })

    };

    fetchCondos();
  }, []);

  const getLastMonthRange = () => ({
    startDate: startOfMonth(subMonths(new Date(), 1)),  // Início do último mês
    endDate: endOfMonth(subMonths(new Date(), 1)),      // Fim do último mês
  });

  const getLast30DaysRange = () => ({
    startDate: subDays(new Date(), 30),  // Últimos 30 dias
    endDate: new Date(),
  });

  const getLast3MonthsRange = () => ({
    startDate: subMonths(new Date(), 3),  // Últimos 3 meses
    endDate: new Date(),
  });

  const getLast6MonthsRange = () => ({
    startDate: subMonths(new Date(), 6),  // Últimos 6 meses
    endDate: new Date(),
  });

  const getLast12MonthsRange = () => ({
    startDate: subMonths(new Date(), 12), // Últimos 12 meses
    endDate: new Date(),
  });

  const getCurrentYearRange = () => ({
    startDate: startOfYear(new Date()),  // Início do ano
    endDate: endOfYear(new Date()),      // Fim do ano
  });

  const customStaticRanges = [
    {
      label: 'Mês atual',
      range: getCurrentMonthRange, // Referência à função
      isSelected(range) {
        return range.startDate === startOfMonth(new Date()) && range.endDate === endOfMonth(new Date());
      },
    },
    {
      label: 'Último mês',
      range: getLastMonthRange, // Referência à função
      isSelected(range) {
        const lastMonthStart = startOfMonth(subMonths(new Date(), 1));
        const lastMonthEnd = endOfMonth(subMonths(new Date(), 1));
        return range.startDate === lastMonthStart && range.endDate === lastMonthEnd;
      },
    },
    {
      label: 'Últimos 30 dias',
      range: getLast30DaysRange, // Referência à função
      isSelected(range) {
        const now = new Date();
        const lastThirtyDays = subDays(now, 30);
        return range.startDate === lastThirtyDays && range.endDate === now;
      },
    },
    {
      label: 'Últimos 3 meses',
      range: getLast3MonthsRange, // Referência à função
      isSelected(range) {
        const now = new Date();
        const lastThreeMonths = subMonths(now, 3);
        return range.startDate === lastThreeMonths && range.endDate === now;
      },
    },
    {
      label: 'Últimos 6 meses',
      range: getLast6MonthsRange, // Referência à função
      isSelected(range) {
        const now = new Date();
        const lastSixMonths = subMonths(now, 6);
        return range.startDate === lastSixMonths && range.endDate === now;
      },
    },
    {
      label: 'Últimos 12 meses',
      range: getLast12MonthsRange, // Referência à função
      isSelected(range) {
        const now = new Date();
        const lastYear = subMonths(now, 12);
        return range.startDate === lastYear && range.endDate === now;
      },
    },
    {
      label: 'Neste ano',
      range: getCurrentYearRange, // Referência à função
      isSelected(range) {
        return range.startDate === startOfYear(new Date()) && range.endDate === endOfYear(new Date());
      },
    },
  ];

  function getTransactionResult(){
    const query = {
      ...filters,
      startDate: startDate,
      endDate: endDate,
    }
    setLoading(true)
    api.get(`fitbank/transaction/`, {params: query})
      .then(response => {
        const data = response.data

        setTransactionResult(data)
        setLoading(false)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  useEffect(() => {
   if (searchParams.get("page")){
   setFilters({
     ...filters,
     page: searchParams.get("page"),
   })
   }else {
     getTransactionResult()
   }


  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Opcional: para um scroll suave
    });
    getTransactionResult()
  }, [startDate, endDate, condo, filters.status, filters.event, filters.page, filters.page_size])

  useEffect(() => {
    console.log("filters condo", filters)
      setSearchParams(filters)
      setCondo(filters.condo_id)
      setType(filters.status)
      setReqEvent(filters.event)
  }, [filters])

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  useEffect(()=>{
    setStartDate(moment(dataRange[0].startDate).format("YYYY-MM-DD"))
    setEndDate(moment(dataRange[0].endDate).format("YYYY-MM-DD"))
  }, [dataRange])

  useEffect(()=>{
    let params = { ...searchParams, "startDate": moment(dataRange[0].startDate).format("YYYY-MM-DD"), "endDate": moment(dataRange[0].endDate).format("YYYY-MM-DD"), }
    if (searchParams.get('condo_id') > 0){
      setCondo(searchParams.get('condo_id'))
      params.condo_id = searchParams.get('condo_id')
    }
    setSearchParams(params)
  }, [startDate, endDate])
  return (
    <>
      <Helmet>
        <title>Transações - Bloco B3</title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loading}
      >

        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width:600px)': {
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              gap: 3,
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,

          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h5'}
          >
            Transações ({transactionResult?.count || 0})
          </Typography>
          <Box>

              <Grid item xs={12} md={4} lg={3} sx={{ alignItems: "center", justifyContent: "end", display: "flex", flexGrow:1, }}>
                <FormControl  sx={{ m: 1, width: 150}} >
                  <InputLabel size={"small"} id="demo-simple-select-label">Tipo </InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={type}
                      size={"small"}
                      label="Tipo"
                      onChange={(event)=>{

                        setFilters({
                          ...filters,
                          status: event.target.value,
                        })
                      }}
                  >
                    <MenuItem value={null}>Todos</MenuItem>
                    <MenuItem value={"IN"}>Entrada </MenuItem>
                    <MenuItem value={"OUT"}>Saida</MenuItem>

                  </Select>
                </FormControl>
                <FormControl  sx={{ m: 1, width: 150}} >
                  <InputLabel size={"small"} id="demo-simple-select-label">Evento </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={reqEvent}
                    size={"small"}
                    label="Evento"
                    onChange={(event)=>{

                      setFilters({
                        ...filters,
                        event: event.target.value,
                      })
                    }}
                  >
                    <MenuItem value={null}>Todos</MenuItem>
                    <MenuItem value={"Bankslip"}>Boleto</MenuItem>
                    <MenuItem value={"Pix"}>PIX</MenuItem>
                    <MenuItem value={"MoneyTransfer"}>TED</MenuItem>
                    <MenuItem value={"InternalTransfer"}>Taxas</MenuItem>

                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: 300}}>
                  <InputLabel size={"small"} id="demo-multiple-name-label">Empresa</InputLabel>
                  <Select
                      labelId="condo-multiple-select-label"
                      id="condo-multiple-select"

                      value={condo}
                      onChange={(event)=>{
                        console.log(event.target.value)

                        setFilters({
                          ...filters,
                          condo_id: event.target.value,
                        })
                      }}
                      input={<OutlinedInput label="Empresa" />}
                      //MenuProps={MenuProps}
                      size={"small"}
                  >
                    <MenuItem
                        key={0}
                        value={null}
                        //style={getStyles(condo.name, selectedCondos, theme)}
                    >
                      Todas as empresas
                    </MenuItem>
                    {listCondos.map((condo) => (
                        <MenuItem
                            key={condo.id}
                            value={condo.id}
                            //style={getStyles(condo.name, selectedCondos, theme)}
                        >
                          {condo.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  id="basic-button"
                  startIcon={<CalendarIcon />}
                  size={'small'}
                  aria-controls={openMenu ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={handleClick}
                  variant={"outlined"}
                >
                  { startDate ?
                    <>
                  {moment(startDate, "YYYY-MM-DD").format("DD MMM YYYY")} - {moment(endDate, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </> : "Data"}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{minWidth:"200px"}}
                >
                  <MenuItem onClick={()=>{
                    const range = getCurrentMonthRange()

                    setDateRange([{
                      startDate: range.startDate,
                      endDate: range.endDate,
                      key: 'selection'
                    }])
                    handleCloseMenu()
                  }} sx={{ fontSize:".8rem"}}>Mês Atual</MenuItem>
                  <Divider/>
                  <MenuItem onClick={()=>{
                    const range = getLastMonthRange()
                    setDateRange([{
                      startDate: range.startDate,
                      endDate: range.endDate,
                      key: 'selection'
                    }])
                    handleCloseMenu()
                  }} sx={{ fontSize:".8rem"}}>Último Mês</MenuItem>
                  <Divider/>
                  <MenuItem  onClick={()=>{
                    const range = getLast30DaysRange()
                    setDateRange([{
                      startDate: range.startDate,
                      endDate: range.endDate,
                      key: 'selection'
                    }])
                    handleCloseMenu()
                  }} sx={{ fontSize:".8rem"}}>Último 30 dias</MenuItem>
                  <Divider/>
                  <MenuItem onClick={()=>{
                    const range = getLast3MonthsRange()
                    setDateRange([{
                      startDate: range.startDate,
                      endDate: range.endDate,
                      key: 'selection'
                    }])
                    handleCloseMenu()
                  }} sx={{ fontSize:".8rem"}} >Último 3 meses</MenuItem>
                  <Divider/>
                  <MenuItem onClick={()=>{
                    const range = getLast6MonthsRange()
                    setDateRange([{
                      startDate: range.startDate,
                      endDate: range.endDate,
                      key: 'selection'
                    }])
                    handleCloseMenu()
                  }} sx={{ fontSize:".8rem"}}>Último 6 meses</MenuItem>
                  <Divider/>
                  <MenuItem onClick={()=>{
                    const range = getLast12MonthsRange()
                    setDateRange([{
                      startDate: range.startDate,
                      endDate: range.endDate,
                      key: 'selection'
                    }])
                    handleCloseMenu()
                  }} sx={{ fontSize:".8rem"}}>Último 12 meses</MenuItem>
                  <Divider/>
                  <MenuItem onClick={()=>{
                    const range = getCurrentYearRange()
                    setDateRange([{
                      startDate: range.startDate,
                      endDate: range.endDate,
                      key: 'selection'
                    }])
                    handleCloseMenu()
                  }} sx={{ fontSize:".8rem"}}>Neste Ano</MenuItem>
                  <Divider/>
                  <MenuItem onClick={()=> {
                    setOpen(true)
                    handleCloseMenu()
                  }} sx={{ fontSize:".8rem"}}>Customizar</MenuItem>

                </Menu>




                <Dialog
                  open={open}
                  maxWidth="md"
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >

                  <DialogContent>
                    <DateRangePicker
                      onChange={item => setDateRange([item.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={dataRange}
                      direction="horizontal"
                      preventSnapRefocus={true}
                      calendarFocus="backwards"
                      staticRanges={[...customStaticRanges]} // Adiciona as opções customizadas às padrão
                    />

                  </DialogContent>

                </Dialog>


              </Grid>


          </Box>
        </Box>


        <Card>

          <TableContainer>
            {transactionResult?.results?.length > 0  ?
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Empresa</TableCell>

                    <TableCell>Data</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Evento</TableCell>
                    <TableCell>Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>


                  { transactionResult.results.map(item =>
                    <TableRow
                      key={item.id}
                      hover
                      sx={{'cursor': 'pointer'}}

                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          TRA-{item.id}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {item.company}
                        </Box>
                      </TableCell>


                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {moment(item.reference_date).format("DD/MM/YYYY [às] HH:mm:ss")}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {item.description}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {item.status === "Entrada" ?
                            <Chip label={item.event} color={"success"} size="small" variant="outlined" />
                            :
                            <Chip label={item.event} size="small" variant="outlined" />
                          }




                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          color={item?.value < 0 && "error.main" }
                        >
                          {Masks.money(item?.value.toString())}

                        </Box>
                      </TableCell>





                    </TableRow>
                  )}

                </TableBody>
              </Table>
              :
              <NoResultsAnimation />
            }
          </TableContainer>
        </Card>

      </Container>
      {transactionResult?.count > 0 &&
        <PaginationGlobal
          state={transactionResult}
          filters={filters}
          setFilters={setFilters}
        />
      }
    </>
  )
}

export default TransactionList
