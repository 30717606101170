import { Box, palette, styled } from '@mui/system'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  Button,
  Card,
  CardContent,
  CardHeader, Chip,
  Dialog, DialogContent,
  Divider, FormControl,
  Grid, InputLabel, Menu, MenuItem, OutlinedInput,
  Paper, Select,
  Skeleton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Tooltip,
  Typography
} from '@mui/material'
import moment from 'moment'
import { getCondoId, getFilterDate, setFilterDate } from '../../../services/Main/AuthStorage'
import { useContext, useEffect, useState } from 'react'
import Context from '../../../contexts/Context'
import {
  ArticleOutlined,
  CurrencyExchange,
  DescriptionOutlined,
  PaidOutlined,
  PaymentsOutlined, PendingActionsOutlined,
  ReceiptOutlined, RequestQuoteOutlined
} from '@mui/icons-material'
import { api } from '../../../services/Main/Api'
import { useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';

import * as React from 'react'
import Brasil from '../../../components/Shared/Brasil'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'

import { subDays, startOfYear, endOfYear, subMonths, startOfMonth, endOfMonth } from 'date-fns'
import { DateRangePicker } from 'react-date-range';
import { CalendarIcon } from '@mui/x-date-pickers'





const AdminDashboard = () => {

  const storageDate = getFilterDate()

  const { condoIdLogin } = useContext(Context)
  const [dataRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: 'selection'
    }
  ])


  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [condo, setCondo] = useState(null)

  const [result, setResult] = useState({})
  const [transactionYear, setTransactionYear] = useState({})
  const [transactionMonth, setTransactionMonth] = useState({})
  const [loadingResult, setLoadingResult] = useState(false)
  const [resultSixMonthsValue, setResultSixMonthsValue] = useState([])
  const [resultSixMonths, setResultSixMonths] = useState([])
  const [transactionQuantity, setTransactionQuantity] = useState([])
  const [transactionResult, setTransactionResult] = useState([])
  const [transactionQuantityValue, setTransactionQuantityValue] = useState([])
  const [billingCompany, setBillingCompany] = useState({
    xAxis: [],
    ySeries: []
  })
  const [transactionTotalMonth, setTransactionTotalMonth] = useState({
    xAxis: [],
    ySeries: []
  })

  const [transactionBlocoB3TotalMonth, setTransactionBlocoB3TotalMonth] = useState({
    xAxis: [],
    ySeries: []
  })
  const [invoiceCount, setInvoiceCount] = useState({
    valueMax: 0,
    value: 0,
    percentage: 0
  })

  const [transferCount, setTransferCount] = useState({
    valueMax: 0,
    value: 0,
    percentage: 0
  })
  const [searchParams, setSearchParams] = useSearchParams();


// Funções de intervalos separadas
   const getCurrentMonthRange = () => ({
    startDate: startOfMonth(new Date()),  // Início do mês atual
    endDate: endOfMonth(new Date()),      // Fim do mês atual
  });

   const getLastMonthRange = () => ({
    startDate: startOfMonth(subMonths(new Date(), 1)),  // Início do último mês
    endDate: endOfMonth(subMonths(new Date(), 1)),      // Fim do último mês
  });

   const getLast30DaysRange = () => ({
    startDate: subDays(new Date(), 30),  // Últimos 30 dias
    endDate: new Date(),
  });

   const getLast3MonthsRange = () => ({
    startDate: subMonths(new Date(), 3),  // Últimos 3 meses
    endDate: new Date(),
  });

   const getLast6MonthsRange = () => ({
    startDate: subMonths(new Date(), 6),  // Últimos 6 meses
    endDate: new Date(),
  });

   const getLast12MonthsRange = () => ({
    startDate: subMonths(new Date(), 12), // Últimos 12 meses
    endDate: new Date(),
  });

   const getCurrentYearRange = () => ({
    startDate: startOfYear(new Date()),  // Início do ano
    endDate: endOfYear(new Date()),      // Fim do ano
  });

  const customStaticRanges = [
    {
      label: 'Mês atual',
      range: getCurrentMonthRange, // Referência à função
      isSelected(range) {
        return range.startDate === startOfMonth(new Date()) && range.endDate === endOfMonth(new Date());
      },
    },
    {
      label: 'Último mês',
      range: getLastMonthRange, // Referência à função
      isSelected(range) {
        const lastMonthStart = startOfMonth(subMonths(new Date(), 1));
        const lastMonthEnd = endOfMonth(subMonths(new Date(), 1));
        return range.startDate === lastMonthStart && range.endDate === lastMonthEnd;
      },
    },
    {
      label: 'Últimos 30 dias',
      range: getLast30DaysRange, // Referência à função
      isSelected(range) {
        const now = new Date();
        const lastThirtyDays = subDays(now, 30);
        return range.startDate === lastThirtyDays && range.endDate === now;
      },
    },
    {
      label: 'Últimos 3 meses',
      range: getLast3MonthsRange, // Referência à função
      isSelected(range) {
        const now = new Date();
        const lastThreeMonths = subMonths(now, 3);
        return range.startDate === lastThreeMonths && range.endDate === now;
      },
    },
    {
      label: 'Últimos 6 meses',
      range: getLast6MonthsRange, // Referência à função
      isSelected(range) {
        const now = new Date();
        const lastSixMonths = subMonths(now, 6);
        return range.startDate === lastSixMonths && range.endDate === now;
      },
    },
    {
      label: 'Últimos 12 meses',
      range: getLast12MonthsRange, // Referência à função
      isSelected(range) {
        const now = new Date();
        const lastYear = subMonths(now, 12);
        return range.startDate === lastYear && range.endDate === now;
      },
    },
    {
      label: 'Neste ano',
      range: getCurrentYearRange, // Referência à função
      isSelected(range) {
        return range.startDate === startOfYear(new Date()) && range.endDate === endOfYear(new Date());
      },
    },
  ];





  function getSixMonthsRentData(){

    let filter = {
      start_date: startDate,
      end_date: endDate,
      condo_id: condo,
    }

    api.get(`analytics/admin-rent-contract/`, {params: filter})
      .then(response => {
        const data = response.data

        setResultSixMonths(data.months)
        setResultSixMonthsValue(data.values)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getTransactionQuantity(){

    let filter = {
      start_date: startDate,
      end_date: endDate,
      condo_id: condo,
    }

    api.get(`analytics/transaction-month-quantity/`, {params: filter})
      .then(response => {
        const data = response.data

        setTransactionQuantity(data.months)
        setTransactionQuantityValue(data.values)
        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }



  function getTransactionMonth(){

    let filter = {
      start_date: startDate,
      end_date: endDate,
      condo_id: condo,
    }

    api.get(`analytics/transaction-month/`, {params: filter})
      .then(response => {
        const data = response.data

        setTransactionMonth(data)

        //setLoading(false)
      }).catch(error => {
      console.log(error)
      //setLoading(false)
    })
  }

  function getDaysDifference(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // Milissegundos em um dia
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calcula a diferença em milissegundos e converte para dias
    const diffDays = Math.round(Math.abs((end - start) / oneDay));
    return diffDays;
  }

  function getTransactionMonthTotal(){

    let filter = {
      start_date: startDate,
      end_date: endDate,
      condo_id: condo,
    }
    if (startDate){
      api.get(`analytics/transaction-month-value/`, {params: filter})
        .then(response => {
          const data = response.data
          setTransactionTotalMonth({
            xAxis: data.months,
            ySeries: data.values,
          })

          //setLoading(false)
        }).catch(error => {
        console.log(error)
        //setLoading(false)
      })
    }
  }

  function getBlocoB3TransactionMonthTotal(){

    let filter = {
      start_date: startDate,
      end_date: endDate,
      condo_id: condo,
    }
    if (startDate){
      api.get(`analytics/admin-blocob3-fees/`, {params: filter})
          .then(response => {
            const results = response.data
            const data = results?.data

            const organizedData = data.reduce((acc, curr) => {
              const date = Object.keys(curr)[0];
              if (!acc[date]) {
                acc[date] = {};
              }
              // Verifica se o objeto tem fee ou plan e adiciona ao acumulador
              acc[date].total = acc[date].total || 0;
              if ('fee' in curr[date]) {
                acc[date].fee = curr[date].fee;
                acc[date].total += curr[date].fee;
              }
              if ('plan' in curr[date]) {
                acc[date].plan = curr[date].plan;
                acc[date].total += curr[date].plan;
              }
              acc[date].date = curr[date].date;

              return acc;
            }, {});

            // Agora vamos criar os três arrays
            const dates = Object.values(organizedData).map(item => {
              // Formata a data para DD/MM/YYYY
              return new Date(item.date).toLocaleDateString('pt-BR');
            });

            const fees = Object.values(organizedData).map(item => item.fee || 0);

            const plans = Object.values(organizedData).map(item => item.plan || 0);

            const totals = Object.values(organizedData).map(item => item.total || 0);

            const show = getDaysDifference(startDate, endDate) > 30 ? "month" : "day"
            setTransactionBlocoB3TotalMonth({
              xAxis: dates,
              ySeries: fees,
              insight: results?.insight,
              ySeriesPlan: plans,
              yTotal: totals,
              show: show
            })
          }).catch(error => {
        console.log(error)
        //setLoading(false)
      })
    }
  }

  useEffect(()=>{
      getSixMonthsRentData()
      getTransactionQuantity()
      getTransactionMonthTotal()
      getTransactionMonth()
      getBlocoB3TransactionMonthTotal()

  }, [startDate, endDate, condo])



  useEffect(()=>{


    setStartDate(moment(dataRange[0].startDate).format("YYYY-MM-DD"))
    setEndDate(moment(dataRange[0].endDate).format("YYYY-MM-DD"))


  }, [dataRange])

  useEffect(()=>{
    let params = { ...searchParams, "startDate": moment(dataRange[0].startDate).format("YYYY-MM-DD"), "endDate": moment(dataRange[0].endDate).format("YYYY-MM-DD"), }
    if (searchParams.get('condo_id') > 0){
      setCondo(searchParams.get('condo_id'))
      params.condo_id = searchParams.get('condo_id')
    }
    setSearchParams(params)
  }, [startDate, endDate, condo])


  const [listCondos, setListCondos] = useState([]);

  useEffect(() => {
    const fetchCondos = async () => {

        api.get(`condo-select/`)
            .then(response => {
              const data = response.data

              setListCondos(data);

              //setLoading(false)
            }).catch(error => {
          console.log(error)
          //setLoading(false)
        })

    };

    fetchCondos();
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid container spacing={2} justifyContent={"end"}>

        <Grid item xs={12} md={12} lg={12} sx={{ alignItems: "center", justifyContent: "end", display: "flex", flexGrow:1, }}>
          <FormControl sx={{ m: 1, width: 300}}>
            <InputLabel id="demo-multiple-name-label">Empresa</InputLabel>
            <Select
                labelId="condo-multiple-select-label"
                id="condo-multiple-select"

                value={condo}
                onChange={(event)=>{

                  setCondo(event.target.value)
                }}
                input={<OutlinedInput label="Empresa" />}
                //MenuProps={MenuProps}
                size={"small"}
            >
              <MenuItem
                  key={0}
                  value={null}
                  //style={getStyles(condo.name, selectedCondos, theme)}
              >
                Todas as empresas
              </MenuItem>
              {listCondos.map((condo) => (
                  <MenuItem
                      key={condo.id}
                      value={condo.id}
                      //style={getStyles(condo.name, selectedCondos, theme)}
                  >
                    {condo.name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            id="basic-button"
            startIcon={<CalendarIcon />}
            size={'small'}
            aria-controls={openMenu ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={handleClick}
            variant={"outlined"}
          >
            {moment(startDate, "YYYY-MM-DD").format("DD MMM YYYY")} - {moment(endDate, "YYYY-MM-DD").format("DD MMM YYYY")}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{minWidth:"200px"}}
          >
            <MenuItem onClick={()=>{
              const range = getCurrentMonthRange()

              setDateRange([{
                startDate: range.startDate,
                endDate: range.endDate,
                key: 'selection'
              }])
              handleCloseMenu()
            }} sx={{ fontSize:".8rem"}}>Mês Atual</MenuItem>
            <Divider/>
            <MenuItem onClick={()=>{
              const range = getLastMonthRange()
              setDateRange([{
                startDate: range.startDate,
                endDate: range.endDate,
                key: 'selection'
              }])
              handleCloseMenu()
            }} sx={{ fontSize:".8rem"}}>Último Mês</MenuItem>
            <Divider/>
            <MenuItem  onClick={()=>{
              const range = getLast30DaysRange()
              setDateRange([{
                startDate: range.startDate,
                endDate: range.endDate,
                key: 'selection'
              }])
              handleCloseMenu()
            }} sx={{ fontSize:".8rem"}}>Último 30 dias</MenuItem>
            <Divider/>
            <MenuItem onClick={()=>{
              const range = getLast3MonthsRange()
              setDateRange([{
                startDate: range.startDate,
                endDate: range.endDate,
                key: 'selection'
              }])
              handleCloseMenu()
            }} sx={{ fontSize:".8rem"}} >Último 3 meses</MenuItem>
            <Divider/>
            <MenuItem onClick={()=>{
              const range = getLast6MonthsRange()
              setDateRange([{
                startDate: range.startDate,
                endDate: range.endDate,
                key: 'selection'
              }])
              handleCloseMenu()
            }} sx={{ fontSize:".8rem"}}>Último 6 meses</MenuItem>
            <Divider/>
            <MenuItem onClick={()=>{
              const range = getLast12MonthsRange()
              setDateRange([{
                startDate: range.startDate,
                endDate: range.endDate,
                key: 'selection'
              }])
              handleCloseMenu()
            }} sx={{ fontSize:".8rem"}}>Último 12 meses</MenuItem>
            <Divider/>
            <MenuItem onClick={()=>{
              const range = getCurrentYearRange()
              setDateRange([{
                startDate: range.startDate,
                endDate: range.endDate,
                key: 'selection'
              }])
              handleCloseMenu()
            }} sx={{ fontSize:".8rem"}}>Neste Ano</MenuItem>
            <Divider/>
            <MenuItem onClick={()=> {
              setOpen(true)
              handleCloseMenu()
            }} sx={{ fontSize:".8rem"}}>Customizar</MenuItem>

          </Menu>




          <Dialog
            open={open}
            maxWidth="md"
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >

            <DialogContent>
              <DateRangePicker
                onChange={item => setDateRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={dataRange}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="backwards"
                staticRanges={[...customStaticRanges]} // Adiciona as opções customizadas às padrão
              />

            </DialogContent>

          </Dialog>


        </Grid>

      </Grid>
      <Divider  sx={{marginY: 3}} />
      <Grid container spacing={2} justifyContent={"space-between"}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={2} justifyContent={"space-between"}>

            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de transações</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction?.total && Masks.money(transactionMonth?.transaction?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de entrada</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_in?.total && Masks.money(transactionMonth?.transaction_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total de Saída</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_out?.total && Masks.money(transactionMonth?.transaction_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_boleto_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Boleto(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_boleto_in?.total && Masks.money(transactionMonth?.transaction_boleto_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_boleto_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Boleto(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_boleto_out?.total && Masks.money(transactionMonth?.transaction_boleto_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_pix_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>PIX(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_pix_in?.total && Masks.money(transactionMonth?.transaction_pix_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_pix_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>PIX(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_pix_out?.total && Masks.money(transactionMonth?.transaction_pix_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_ted_in?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>TED(Entrada)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_ted_in?.total && Masks.money(transactionMonth?.transaction_ted_in?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Item >
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <CurrencyExchange sx={{ color:'primary.main',}} />
                  <Box>{transactionMonth?.transaction_ted_out?.quantity} transações</Box>
                </Box>
                <Box>
                  <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>TED(Saída)</Typography>
                </Box>
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                  { loadingResult ?
                    <Skeleton variant="rectangular" width={200} height={50} />
                    :
                    <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                      {transactionMonth?.transaction_ted_out?.total && Masks.money(transactionMonth?.transaction_ted_out?.total.toString())}
                    </Typography>
                  }
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Divider sx={{fontWeight: 'bold', fontSize: "1.5rem", marginY: "2rem"}} />
        </Grid>
        <Grid item  xs={12} md={6} lg={8}>
          <Card>
            <CardHeader title={'Número transações'}/>
            <CardContent>
              <BarChart
                sx={{display: "block"}}
                colors={['#f50863']}
                xAxis={[
                  {
                    id: 'barCategories',
                    data: transactionQuantity,
                    scaleType: 'band',

                  },
                ]}
                series={[
                  {
                    data: transactionQuantityValue,
                  },
                ]}

                height={500}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card>
            <CardHeader title={'Onde estamos'}/>
          <Box sx={{display: "flex", justifyContent: "center", paddingBottom: 4}}>
          <Brasil startDate={startDate} endDate={endDate} />
          </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardHeader title={'Total de transações'}/>
            <CardContent>
              <LineChart
                xAxis={[{
                  data: transactionTotalMonth.xAxis,
                  scaleType: 'point',
                  valueFormatter: (value) => {
                    if (value.length > 5) {
                      return moment(value, 'MM/YYYY').format('MMM/YYYY')
                    }else{
                      return moment(value, 'DD/MM').format('DD/MMM')
                    }

                  },
                }]}
                margin={{ top: 5, right: 30, bottom: 30, left: 40 }}
                colors={['#f50863']}
                series={[
                  {
                    data: transactionTotalMonth.ySeries,
                    valueFormatter: (value) => (Masks.money(value.toString())),
                    area: true,
                  },
                ]}
                yAxis={[
                  {
                    valueFormatter: (value) => (`${value / 100000}k`),
                  },
                ]}

                height={500}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>

          <Card>
            <CardHeader title={'Número de novos contratos'}/>
            <CardContent>
              <BarChart
                sx={{display: "block"}}
                colors={['#f50863']}
                xAxis={[
                  {
                    id: 'barCategories',
                    data: resultSixMonths,
                    scaleType: 'band',

                  },
                ]}
                series={[
                  {
                    data: resultSixMonthsValue,
                  },
                ]}

                height={500}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} >
          <Divider sx={{ marginY: "2rem"}} />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <CurrencyExchange sx={{ color:'primary.main',}} />
              {/*<Box>{transactionBlocoB3TotalMonth?.transaction?.quantity} transações</Box>*/}
            </Box>
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Total</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                  <Skeleton variant="rectangular" width={200} height={50} />
                  :
                  <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                    {transactionBlocoB3TotalMonth?.insight?.total && Masks.money(transactionBlocoB3TotalMonth?.insight?.total.toString())}
                  </Typography>
              }
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <CurrencyExchange sx={{ color:'primary.main',}} />
              {/*<Box>{transactionMonth?.transaction_in?.quantity} transações</Box>*/}
            </Box>
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Planos</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                  <Skeleton variant="rectangular" width={200} height={50} />
                  :
                  <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                    {transactionBlocoB3TotalMonth?.insight?.b3_invoice && Masks.money(transactionBlocoB3TotalMonth?.insight?.b3_invoice.toString())}
                  </Typography>
              }
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Item >
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <CurrencyExchange sx={{ color:'primary.main',}} />
              {/*<Box>{transactionMonth?.transaction_out?.quantity} transações</Box>*/}
            </Box>
            <Box>
              <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}>Taxas</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
              { loadingResult ?
                  <Skeleton variant="rectangular" width={200} height={50} />
                  :
                  <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                    {transactionBlocoB3TotalMonth?.insight?.b3_fee && Masks.money(transactionBlocoB3TotalMonth?.insight?.b3_fee.toString())}
                  </Typography>
              }
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card>
            <CardHeader title={'Faturas e Taxas'}/>
            <CardContent>


              <LineChart
                  xAxis={[{
                    data: transactionBlocoB3TotalMonth?.xAxis,
                    scaleType: 'point',
                    valueFormatter: (value) => {

                      if (transactionBlocoB3TotalMonth.show === "month") {
                        return moment(value, "DD/MM/YYYY").format('MMM/YYYY')
                      }else{
                        return moment(value, "DD/MM/YYYY").format('DD/MMM')
                      }

                    },
                  },
                  ]}
                  margin={{ top: 5, right: 30, bottom: 30, left: 40 }}
                  //colors={['#1E3040', ]}
                  series={[
                    {
                      data: transactionBlocoB3TotalMonth?.ySeries || [],
                      color: '#24394C',
                      valueFormatter: (value) => {
                        if (value){
                          return `Taxas: ${Masks.money(value.toString())}`
                        }else{
                          return `Taxas: ${Masks.money("0")}`
                        }
                      },
                      area: false,
                      showMark: false,
                    },
                    {
                      data: transactionBlocoB3TotalMonth?.ySeriesPlan || [],
                      color: '#24394C',
                      valueFormatter: (value) => {
                        if (value){
                          return `Planos: ${Masks.money(value.toString())}`
                        }else{
                          return `Planos: ${Masks.money("0")}`
                        }
                      },
                      area: false,
                      showMark: false,
                    },
                    {
                      data: transactionBlocoB3TotalMonth?.yTotal || [],
                      color: '#1E3040',
                      valueFormatter: (value) => {
                        if (value){
                          return <Typography sx={{ fontSize: 18}} fontWeight="bold">{`Total: ${Masks.money(value.toString())}`}</Typography>
                        }else{
                          return <Typography sx={{ fontSize: 18}} fontWeight="bold">{`Total: ${Masks.money("0")}`}</Typography>
                        }
                      },
                      area: true,
                    },
                  ]}
                  yAxis={[
                    {
                      valueFormatter: (value) => (`${value / 100000}k`),
                    },
                  ]}

                  height={500}
              />

            </CardContent>
          </Card>
        </Grid>


      </Grid>


    </>)
}

export default AdminDashboard