import React, {
  useContext,
  useEffect,
  useState
} from "react";
import {
  Backdrop,
  Box, Chip, CircularProgress,
  IconButton, Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import {
  Close,
  Delete,
  Download,
  MoreVert, OpenInNew,
  Share
} from "@mui/icons-material";
import moment from "moment";
import Masks from "../../../../../components/Shared/Masks";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {api} from "../../../../../services/Main/Api";
import {getCondoId} from "../../../../../services/Main/AuthStorage";
import {useSnackbar} from "notistack";
import Menu from "@mui/material/Menu";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";
import Context from "../../../../../contexts/Context";
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import axios from "axios";
import WhatsAppMessageComponent from "../../../Whatsapp/WhatsAppMessageComponent";
import EmailMessageComponent from "../../../Emails/EmailMessageComponent";

const TableChargeBody = ({ data, filters, setFilters }) => {
  const {enqueueSnackbar} = useSnackbar()

  const [units, setUnits] = useState(null)
  const [anchor, setAnchor] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedObject, setSelectedObject] = useState(null)
  const [openDelete, setOpenDelete] = useState(false)
  const {  loadingModal, setLoadingModal } = useContext(Context)

  const navigate = useNavigate()
  const unitValues = units?.map((option) => ({ name: `${option.number} | ${option.name}`, id: option.id }))

  async function fetchData() {
    try {
      const response = await api.get(`unit/?condo_id=${getCondoId()}`);
      setUnits(response?.data.results);
    } catch (error) {
      console.error(error?.data);
    }
  }

  const deleteReq = () => {
    api.delete(`/charge/${selectedObject?.id}/`)
      .then(response =>{
        setFilters(prevState => ({...prevState, deleteDone: !filters?.deleteDone}))
        enqueueSnackbar('Cobrança apagada', { variant: 'success' })
      })
      .catch(error => {
        enqueueSnackbar('Erro ao excluir cobrança', { variant: 'error' })
        const message = error.response.data.message || error.response.data;
        enqueueSnackbar(message, { variant: 'error' })
        console.log(error)
      })
      .finally(() => {
      })
  }

  useEffect(function getUnits() {
    fetchData()
  }, [])


  async function handleCheckUrl(url, id) {
    setLoadingModal(true)
    try {
      if (url) {
        window.open(url, '_blank');
        setLoadingModal(false)
      } else {
        const data= {
          bankslipcharge_id: id,
        }
        api.post(`create/url-new-bankslip/`, data)
            .then(response => {
              window.open(response.data, '_blank');
              setLoadingModal(false)
            })
            .catch(error =>{
              setLoadingModal(false)
              console.log(error)
            })
      }

    } catch (error) {
      setLoadingModal(false)
      console.error(error);
    }
  }

  return (
    <>
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingModal}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>

      <TableBody>
      {data?.results?.map(object =>
        <TableRow
          key={object.number}
          hover
          onClick={() => { navigate(`/cobranca/${object.id}`)}}
          sx={{ 'cursor': 'pointer', }}
        >
          <TableCell>
            {object.number}
          </TableCell>
          <TableCell>
            {unitValues?.find(element => element.id === object?.unit)?.name}
          </TableCell>
          <TableCell>
            {object.due_date
              ? moment(object.due_date).format('DD/MM/YYYY')
              : '-'}
          </TableCell>
          <TableCell>
            <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'start'
                }}
            >

              <Chip
                  color={object.status.key === 'pending' ? 'warning' :
                      object.status.key === 'paid' ? 'success' :
                          object.status.key === 'overdue' ? 'error' : 'info'
                  }
                  size="small"
                  variant="outlined"
                  label={object?.status.value}/>


            </Box>
          </TableCell>

          <TableCell>
            {Masks.money(String(object.total))}
          </TableCell>
          <TableCell
              sx={{textAlign: 'center'}}
          >
            { object?.bank_slip_status.key !== 0 ?

                <Link
                    href={object?.payment_page}
                    target="_blank"
                    onClick={(e) => e.stopPropagation()}>
                  <Chip
                      label={'Página de pagamento'}
                      size={'small'}
                      icon={<OpenInNew />}
                  />
                </Link>
                :
                <Close sx={{ color: 'error.main'}} />
            }
          </TableCell>
          <TableCell>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {object?.company?.type === 'RENT' &&
                  object.company?.whatsapp_send_message &&
                  (object?.status.key === "paid" ||
                      object?.status.key === "pending" ||
                      object?.status.key === "overdue") && (
                      <WhatsAppMessageComponent data={object?.whatsapp_message} />
                  )}

              {(object?.status.key === "paid" ||
                  object?.status.key === "pending" ||
                  object?.status.key === "overdue") && (
                  <EmailMessageComponent data={object?.email_message} />
              )}
            </Box>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align={"center"}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setSelectedObject(object)
                setAnchor(e.currentTarget)
                setOpen(object.id)
              }}>
              <MoreVert/>
            </IconButton>

            <Menu
              open={open === object.id}
              anchorEl={anchor && anchor}
              onClose={(e) => {
                e.stopPropagation()
                setOpen(false)
              }}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' },
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >

              {object.bank_slip_status.key !== 6 ?
              <MenuItem
                component={RouterLink}
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleCheckUrl(selectedObject?.bank_slip_charge[0]?.file, object.id);
                }}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Download/>
                </ListItemIcon>
                <ListItemText
                  primary="Baixar boleto"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>

              :
            <MenuItem
                sx={{
                  color: 'text.secondary'
                }}
            >
              <ListItemIcon>
                <RunningWithErrorsOutlinedIcon/>
              </ListItemIcon>
              <ListItemText
                  primary="Processando boleto"
                  primaryTypographyProps={{ variant: 'body2' }}/>
            </MenuItem>
              }
              <MenuItem
                onClick={ (e) => {
                  e.stopPropagation()
                  navigator.clipboard.writeText(selectedObject?.bank_slip_charge[0]?.file)
                    .then(() => {
                      enqueueSnackbar('Link copiado', { variant: 'success' })
                    })
                }
                }
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Share/>
                </ListItemIcon>
                <ListItemText
                  primary="Compartilhar boleto"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>
              <MenuItem
                component={RouterLink}
                onClick={(e)=> {
                  setOpenDelete(true)
                  setOpen(false)
                  e.stopPropagation()
                }}
                sx={{
                  color: 'text.secondary'
                }}
              >
                <ListItemIcon>
                  <Delete />
                </ListItemIcon>
                <ListItemText
                  primary="Excluir Cobrança"
                  primaryTypographyProps={{ variant: 'body2' }}/>
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      )
      }
    </TableBody>
      <SimpleDialog
        open={openDelete}
        setOpen={setOpenDelete}
        title={'Apagar Cobrança'}
        question={'Gostaria realmente de apagar esta cobrança?'}
        functionProp={deleteReq}
      />
    </>
  )
}

export default TableChargeBody