import React, { useEffect, useState, useRef } from 'react';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { api } from "../../../services/Main/Api";
import moment from "moment";
import { Popover, Box, Typography } from "@mui/material";
import theme from "../../../layout/Main/Theme";
import {Check} from "@mui/icons-material";

const WhatsAppMessageComponent = (dataWatsapp) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const boxRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (boxRef.current) {
                const { left, top, right, bottom } = boxRef.current.getBoundingClientRect();
                const isInside =
                    event.clientX >= left &&
                    event.clientX <= right &&
                    event.clientY >= top &&
                    event.clientY <= bottom;

                if (!isInside) {
                    setAnchorEl(null);
                }
            }
        };

        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    const getIconColor = () => {
        if (dataWatsapp?.data?.message_send || dataWatsapp?.data?.message_delivered || dataWatsapp?.data?.message_was_read) {
            return theme?.palette?.success.main;
        }
        return 'lightgray';
    };

    const getHoverMessages = () => {
        const messages = [];
        if (dataWatsapp?.data?.message_send) {
            messages.push({
                message: `Enviada em ${moment(dataWatsapp?.data?.send_date).format("DD/MM/YYYY [às] HH:mm:ss")}`,
                status: "send"
            });
        }
        if (dataWatsapp?.data?.message_delivered) {
            messages.push({
                message: `Entregue em ${moment(dataWatsapp?.data?.delivered_date).format("DD/MM/YYYY [às] HH:mm:ss")}`,
                status: "delivered"
            });
        }
        if (dataWatsapp?.data?.message_was_read) {
            messages.push({
                message: `Lida em ${moment(dataWatsapp?.data?.reading_date).format("DD/MM/YYYY [às] HH:mm:ss")}`,
                status: "read"
            });
        }
        return messages;
    };

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    return (
        <Box
            ref={boxRef}
            onMouseEnter={handleMouseEnter}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <WhatsAppIcon sx={{ color: getIconColor() }} fontSize="medium" />
            {getHoverMessages().length > 0 &&
                <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableRestoreFocus
            >
                <Box
                    sx={{
                        padding: '12px',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        maxWidth: '420px',
                    }}
                >
                    {getHoverMessages().map((msg, index) => (
                        <Typography
                            component="li"
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '12px',
                                color: 'black',
                                textAlign: 'start',
                                listStyleType: 'none',
                                gap: '4px',
                                marginBottom: index !== getHoverMessages().length - 1 ? '8px' : '0',
                            }}
                        >
                            {/* Ícones ao lado do texto */}
                            {msg.status === "send" && <Check fontSize="small" sx={{ color: '#999999', fontSize: '14px' }} />}
                            {msg.status === "delivered" && (
                                <>
                                    <Check fontSize="small" sx={{ color: '#999999', fontSize: '14px', marginLeft: '-6px' }} />
                                    <Check fontSize="small" sx={{ color: '#999999', fontSize: '14px', marginLeft: '-10px' }} />
                                </>
                            )}
                            {msg.status === "read" && (
                                <>
                                    <Check fontSize="small" sx={{ color: '#34B7F1', fontSize: '14px', marginLeft: '-6px' }} />
                                    <Check fontSize="small" sx={{ color: '#34B7F1', fontSize: '14px', marginLeft: '-10px' }} />
                                </>
                            )}
                            <span style={{ marginLeft: '8px' }}>{msg.message}</span>
                        </Typography>
                    ))}
                </Box>
            </Popover>
            }
        </Box>
    );
};

export default WhatsAppMessageComponent;
