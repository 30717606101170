import {
  Backdrop,
  Card,
  CardContent,
  Container,
  Fade,
  Grid,
} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import moment from "moment";
import {api} from "../../../services/Main/Api";
import {useSnackbar} from "notistack";
import {useParams} from "react-router-dom";
import Context from "../../../contexts/Context";
import Masks from "../../../components/Shared/Masks";
import {Helmet} from "react-helmet";
import ExtraChargeFormSet from "./ExtraChargeFormSet";
import ContractForm from "./ContractForm/ContractForm";
import RentDocuments from "./RentDocuments";
import ContractInvoices from "./ContractInvoices";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import RentNotes from "./RentNotes";
import RentSlipOptions from "./RentSlipOptions/RentSlipOptions";
import RentWarranty from "./RentWarranty";
import CircularProgress from "@mui/material/CircularProgress";
import RentWizard from "./RentWizard";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import successAnimation from "../../../assets/animations/success.json";
import ContractConclusionCard from "./ContractConclusionCard";
import RecipientTransferCard from "./RecipientTransferCard";
import RentContractFee from './RentContractFee'
import AutoTransfer from "./RentRecipientSet/AutoTransfer";
import TerminateContractDetail from "./ContractForm/TerminateContractDetail";
import TerminateContractForm from "./ContractForm/TerminateContractForm";
import {Box} from "@mui/system";


const RentForm = () => {
  //////////////////////////////////////
  //// States, Provider and Hooks /////
  ////////////////////////////////////

  const params = useParams()
  const { id } = params
  const {
    setFilters,
    unitOptions,
    userFiles,
    loadingModal,
    setLoadingModal,
    setDocuments,
    deleteApiFiles,
    setDeleteApiFiles,
    setDataProvider,
    dataProvider,
    setUserfiles,
    setRefreshScreen,
    screenRefresh,
  } = useContext(Context)
  const {enqueueSnackbar} = useSnackbar()
  const { activeStep, unitInfo } = dataProvider || {}

  const targetRef = useRef(null);

  const [reqData, setReqData] = useState({
    start_date: moment().format(),
    active: true,
    contract_type: 'residencial',
    administrative_tax: 10,
    intermediation_fee: 100,
    warning_date: moment().format('YYYY-MM-DD'),
    closing_date: moment().format('YYYY-MM-DD'),
    calculate_fine: false,
    number_of_months: null,
  })
  const [rentContractId, setRentContractId] = useState(false)
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState(null)
  const [errorDocuments, setErrorDocuments] = useState(null)
  const [reqLoading, setReqLoading] = useState(false)
  // const [unitInfo, setUnitInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(!!id)
  const [dataCharge, setDataCharge] = useState([])
  const [taxDelete, setTaxDelete] = useState([])
  const [loadingDocuments, setLoadingDocuments] = useState(!!id)
  const [rentNotes, setRentNotes] = useState([])
  const [guaranteeData, setGuaranteeData] = useState({due_date: moment()})
  const [insuranceData, setInsuranceData] = useState([])
  const [guarantee, setGuarantee] = useState(null)
  const [insurance, setInsurance] = useState(reqData?.id)
  const [show, setShow] = useState(false)
  const [dataBond, setDataBond] = useState([])
  const [bondDelete, setBondDelete] = useState([])
  const [prevWarranty, setPrevWarranty] = useState(null)
  const { user } = useContext(Context)
  const { perm_bank } = user || {}

  ////////////////////////////
  //// General functions ////
  //////////////////////////

  function helmetTitle () {
    if (!id) {
      return 'Criação de contrato'
    } else {
      return 'Editar contrato'
    }
  }

  function initalDataFilter () {
    if (!params.id) {
      setDataProvider(prevState => (
        {
          ...prevState,
          activeStep: 0,
          firstStep: false,
          secondStep: false,
          thirdStep: false,
          fourthStep: false,
          fifthStep: false,
        }
      ))
    }
  }

  function resetFilters () {
    setDocuments([])
    setDeleteApiFiles([])
    setEdit(false)
    setFilters(prevState => ({
      ...prevState,
      requestDone: !prevState.filters?.requestDone,
      load: true
    }))
  }

  function withoutDoc () {
    userFiles?.length < 1 && deleteApiFiles?.length < 1 && resetFilters()

  }

  function warrantyChoice (rentId) {
    if (guarantee === 'credpago'){
      postCredPago(rentId)
    }

    if (guarantee === 'guarantor'){
      postGuarantor(rentId)
    }

    if (guarantee === 'bail_insurance'){
      postBailInsurance(rentId)
    }

    if (guarantee === 'titulo_de_captalização'){
      createCapitalization(rentId)
    }

    if (guarantee === 'deposit') {
      createEscrow(rentId)
    }

  }

  function stepComplete() {
    return (
      <Grid item xs={12}>

        <Card>
          <Fade
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={300}
          >
            <CardContent>
              <AnimationGlobal
                text={'Etapa concluída'}
                size={'200px'}
                animation={successAnimation}
                fontStyles={{mb: '1rem'}}
                fontSize={'20px'}
              />
            </CardContent>
          </Fade>
        </Card>
      </Grid>
    )
  }

  function scrollToRecipients() {
    const targetPosition = targetRef.current.offsetTop

    window.scrollTo({
      top: targetPosition - 50,
      behavior: 'smooth',
    })
  }

  function verifyPercentageSum(array) {
    let soma = 0;
    for (let i = 0; i < array.length; i++) {
      soma += +array[i].percentage;
    }
    return soma;
  }

  function percentFilter(array){
    if ((verifyPercentageSum(array) !== 100) && (dataProvider?.afterDelete === undefined) && (array?.length > 0)){
      scrollToRecipients()
      setDataProvider(prevState => ({
        ...prevState,
        edit: true
      }))
      enqueueSnackbar('Distribuição está incorreta, por favor refaça', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      })
    }
  }

  /////////////////////////////
  //////// Requisitions ///////
  /////////////////////////////

  /////////////////
  ////-> 1 Get ////
  /////////////////

  const [contractLoad, setContractLoad] = useState(true)
  const [prevStateData, setPrevStateData] = useState(null);

  function getContract(param, refresh) {
    !refresh && setIsLoading(true)
    const rentId = param || dataProvider?.rentId
    api.get(`rent_contract/${id ?? rentId}/`)
      .then(response =>{
        setPrevStateData({...response.data, value: Masks.money(response.data.value.toString())})
        setReqData({...response.data, value: Masks.money(response.data.value.toString()) })
        setDataProvider(prevState => ({
          ...prevState,
          contractValue: response.data.value,
          autoTransfer: response.data.auto_transfer,
          transferDay: response.data.transfer_day,
          transferGuaranteed: response.data.transfer_guaranteed,
          transferGuaranteedDuration: response.data.transfer_guaranteed_duration,
          administrativeTaxAmount: response.data.administrative_tax_amount,
          readjustmentIndex: response.data.readjustment_index,

      }))
        //getTax()
        getGuarantee(response.data.guarantee)
        getInsurance()
        setDataProvider(prevState => ({...prevState, insurance: response.data.insurance}))
        setGuarantee(response.data.guarantee)
        dataProvider?.skelAuth && setDataProvider(prevState => ({
          ...prevState,
        skelWarranty: false
        }))

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        !refresh && setIsLoading(false)
        setContractLoad(false)
      })
  }

  function getTax(filters) {
    if (id || dataProvider?.rentId) {
      const rentId = id || dataProvider?.rentId
      api.get(`/rent_contract/tax/?rent_contract=${rentId}`, { params: filters ?? { fully_paid: false } })
          .then(response => {
            setDataCharge(response.data.results)
          })
          .catch(error => {
            console.log(error)
          })
    }
  }

  function getDocuments(refresh) {
    !refresh && setLoadingDocuments(true)
    api.get(`/rent_contract/document/`,{params: {rent_contract_id: id}})
      .then((response)=> {
        setDocuments(response.data.results)
      })
      .catch(error => {
        console.log(error)
        setErrorDocuments(error.data)
      }).finally(() => {
      !refresh && setLoadingDocuments(false)
    })
  }

  function detailedUnit() {
    !reqData?.unit && setDataProvider(prevState => ({...prevState, unitInfo: null}))

    if (reqData?.unit) {
      api.get(`/unit/${reqData?.unit?.id ?? reqData?.unit}/`)
        .then(response => {
          setDataProvider(prevState => ({...prevState, unitInfo: response.data}))
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  function getBonds(deleteReq, refresh) {
    if(id || dataProvider?.rentId) {
      !refresh && setIsLoading(true)
      api.get(`/rent_recipient/signed_recipient/?rent_contract_id=${id ?? id | dataProvider?.rentId}`)
        .then(response => {
          setDataBond(response.data.results)
          percentFilter(response.data.results)
          deleteReq && setDataProvider(prevState => ({
            ...prevState,
            deleteBondDone: !dataProvider?.deleteBondDone
          }))

        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          !refresh && setIsLoading(false)
        })
    }
  }

  function getGuarantee(guarantee) {
    if (guarantee) {
      const url =
        guarantee === 'credpago' && `rent_contract/cred_paid/` ||
        guarantee === 'guarantor' && `rent_contract/guarantor/` ||
        guarantee === 'bail_insurance' && `rent_contract/bail_insurance/` ||
        guarantee === 'titulo_de_captalização' && `rent_contract/capitalization_bond/` ||
        guarantee === 'deposit' && `rent_contract/deposit/`

      const rentId = id || dataProvider?.rentId

      api.get(url, {params: { rent_contract: rentId } })
        .then(response => {
          const { value, phone, cep, cpf_cnpj } = response?.data?.results[0] || {}
          setGuaranteeData({
            ...response.data.results[0],
            ...(value && {value: Masks.money(value.toString())}),
            ...(phone && {phone: `${phone}-`}),
            ...(cep && {cep: `${cep}-`}),
            ...(cpf_cnpj && {cpf_cnpj: `${cpf_cnpj}-`}),
          })
         setPrevWarranty({
           ...response.data.results[0],
           ...(value && {value: Masks.money(value.toString())}),
           ...(phone && {phone: `${phone}-`}),
           ...(cep && {cep: `${cep}-`}),
           ...(cpf_cnpj && {cpf_cnpj: `${cpf_cnpj}-`}),
         })
          setDataProvider(prevState => ({...prevState, skelWarranty: false}))
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  async function getInsurance(insurance) {
    if (id || dataProvider?.rentId) {
      const rentId = id || dataProvider?.rentId
      try {
        const response = await api.get('/rent_contract/insurance/', {params: {rent_contract: rentId}});
        setInsuranceData(response.data.results)
      } catch (error) {
        console.log(error);
      }
    }
  }

  // function getInsurance(insurance) {
  //   if (insurance) {
  //     const url =
  //       insurance === 'fire_insurance' && `rent_contract/cred_paid/` ||
  //       insurance === 'content_insurance' && `rent_contract/guarantor/` ||
  //
  //     api.get(url, {params: { rent_contract: id } })
  //       .then(response => {
  //         const { value, phone, cep, cpf_cnpj } = response?.data?.results[0] || {}
  //         setWarrantyData({
  //           ...response.data.results[0],
  //           ...(value && {value: Masks.money(value.toString())}),
  //           ...(phone && {phone: `${phone}-`}),
  //           ...(cep && {cep: `${cep}-`}),
  //           ...(cpf_cnpj && {cpf_cnpj: `${cpf_cnpj}-`}),
  //         })
  //         setPrevWarranty({
  //           ...response.data.results[0],
  //           ...(value && {value: Masks.money(value.toString())}),
  //           ...(phone && {phone: `${phone}-`}),
  //           ...(cep && {cep: `${cep}-`}),
  //           ...(cpf_cnpj && {cpf_cnpj: `${cpf_cnpj}-`}),
  //         })
  //         setDataProvider(prevState => ({...prevState, skelWarranty: false}))
  //
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //   }
  // }

  ///////////////////////////
  // -> 2 Post & Update /////
  //////////////////////////

  function create () {
    const {
      start_date,
      value,
      bankslip_fine_value,
      bankslip_interest_value,
      bankslip_discount_value,
      contract_time,
      generate_bankslip_day,
      generate_bankslip,
      bankslip_due_day,
      administrative_tax,
      intermediation_fee,
      readjustment_index
    } = reqData || {}

    const data = {
      ...reqData,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      contract_time: +contract_time,
      value: value?.replace(/\D+/g, ""),
      bankslip_fine_value: bankslip_fine_value ? bankslip_fine_value?.replace(/\D+/g, "") : null,
      bankslip_interest_value: bankslip_interest_value ? bankslip_interest_value?.replace(/\D+/g, "") : null,
      bankslip_discount_value: bankslip_discount_value ? bankslip_interest_value?.replace(/\D+/g, "") : null,
      generate_bankslip_day: generate_bankslip ? generate_bankslip_day : null,
      insurance: insurance?.length > 0 ? insurance : null,
      guarantee: guarantee?.length > 0 ? guarantee : null,
      administrative_tax: administrative_tax != null ? +administrative_tax?.toString()?.replace(/,/g, '.') : 0,
      intermediation_fee: intermediation_fee != null ? +intermediation_fee?.toString()?.replace(/,/g, '.') : 0,
      readjustment_index: readjustment_index
    }

    setIsLoading(true)
    api.post(`/rent_contract/`, data)
      .then(response =>{
        // documentPost(response.data.id)
        // documentDelete()
        documentPromise(response.data.id)
        withoutDoc()
        getContract(response.data.id)
        setDataProvider(prevState => ({
          ...prevState,
          rentId: response.data.id,
          firstStep: true,
          contractValue: response.data.value,
          // autoTransfer: response.data.auto_transfer,
          // transferDay: response.data.transfer_day,
          // transferGuaranteed: response.data.transfer_guaranteed,
          // transferGuaranteedDuration: response.data.transfer_guaranteed_duration,
          // administrativeTaxAmount: response.data.administrative_tax_amount,
        }))
        taxDeleteReq()
        setError(null)
      })
      .catch(error => {
        setError(error.response.data)
        error.response.data.active && enqueueSnackbar(error.response.data.active, { variant: 'error' })
        error.response.data[0] && enqueueSnackbar(error.response.data[0], { variant: 'error' })
        error.response.data.administrative_tax && enqueueSnackbar(error.response.data.administrative_tax, { variant: 'error' })
        error.response.data.value && enqueueSnackbar('É necessário definir um valor', { variant: 'error' })
        error.response.data.unit && enqueueSnackbar('É necessário definir um imóvel', { variant: 'error' })
        console.log(error)
      })
      .finally(()=> {
        setIsLoading(false)
      })
  }

  function update (fromWarranty, nullifyInsurance, nullifyGuarantee) {
    const {
      start_date,
      value,
      bankslip_fine_value,
      bankslip_interest_value,
      bankslip_discount_value,
      contract_time,
      administrative_tax,
      intermediation_fee,
      readjustment_index
    } = reqData || {}

    const data = {
      ...reqData,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      contract_time: +contract_time,
      value: value?.includes('R$') && value?.replace(/\D+/g, ""),
      bankslip_fine_value: bankslip_fine_value ? bankslip_fine_value?.toString().replace(/\D+/g, "") : null,
      bankslip_interest_value: bankslip_interest_value ? bankslip_interest_value?.toString()?.replace(/\D+/g, "") : null,
      bankslip_discount_value: bankslip_discount_value ? bankslip_discount_value?.toString()?.replace(/\D+/g, "") : null,
      // insurance:  null,
      insurance: !nullifyInsurance ? insurance : null,
      guarantee: !nullifyGuarantee ? guarantee : null,
      // guarantee: null,

      administrative_tax: administrative_tax != null ? +administrative_tax?.toString()?.replace(/,/g, '.') : 0,
      intermediation_fee: intermediation_fee != null ? +intermediation_fee?.toString()?.replace(/,/g, '.') : 0,
      readjustment_index: readjustment_index
    }

    if (id || dataProvider?.rentId) {
      const rentId = id || dataProvider?.rentId
      setLoadingModal(true)
      api.put(`/rent_contract/${rentId}/`, data)
        .then(response =>{
          withoutDoc()
          rentId && taxDeleteReq()
          setDataProvider(prevState => ({
            ...prevState,
            thirdStep: true,
            successObserver: !dataProvider?.successObserver,
          }))
          setRefreshScreen(screenRefresh)
          rentId && warrantyChoice(rentId)
          rentId && !fromWarranty && enqueueSnackbar('Contrato atualizado', { variant: 'success' })
          fromWarranty && enqueueSnackbar('Garantia/Seguro atualizado', { variant: 'success' })
          rentId && getContract(null, true)
          getBonds(null, true)
          setError(null)
        })
        .catch(error => {
          setError(error.response.data)
          error.response.data.active && enqueueSnackbar(error.response.data.active, { variant: 'error' })
          error.response.data[0] && enqueueSnackbar(error.response.data[0], { variant: 'error' })
          error.response.data.administrative_tax && enqueueSnackbar(error.response.data.administrative_tax, { variant: 'error' })
          error.response.data.value && enqueueSnackbar('É necessário definir um valor', { variant: 'error' })
          error.response.data.unit && enqueueSnackbar('É necessário definir um imóvel', { variant: 'error' })
          error.response.data.generate_bankslip_day && enqueueSnackbar(error.response.data.generate_bankslip_day, { variant: 'error' })
          console.log(error)
        })
        .finally(()=> {
          setLoadingModal(false)
        })
    }
  }

  function gambiUpdateWarranty () {
    const { active_date, value, bankslip_fine_value, bankslip_interest_value, bankslip_discount_value, contract_time } = reqData || {}

    const data = {
      ...reqData,
      start_date: moment(active_date).format('YYYY-MM-DD'),
      contract_time: +contract_time,
      value: value?.includes('R$') && value?.replace(/\D+/g, ""),
      bankslip_fine_value: bankslip_fine_value ? bankslip_fine_value?.replace(/\D+/g, "") : null,
      bankslip_interest_value: bankslip_interest_value ? bankslip_interest_value?.replace(/\D+/g, "") : null,
      bankslip_discount_value: bankslip_discount_value ? bankslip_discount_value?.replace(/\D+/g, "") : null,
      guarantee: guarantee?.length > 0 ? guarantee : null,
      insurance: insurance?.length > 0 ? insurance : null,
    }

    if (id || dataProvider?.rentId) {
      const rentId = id || dataProvider?.rentId
      setLoadingModal(true)
      api.put(`/rent_contract/${rentId}/`, data)
        .then(response =>{
          withoutDoc()
          warrantyChoice(rentId)
          rentId && enqueueSnackbar('Contrato atualizado', { variant: 'success' })
          getGuarantee()
          getContract()
          setError(null)
        })
        .catch(error => {
          setError(error.response.data)
          enqueueSnackbar(error.response.data ?? 'Erro ao atualizar contrato', { variant: 'error' })
          console.log(error)
        })
        .finally(()=> {
          setLoadingModal(false)
        })
    }
  }

  function documentPromise(rentId) {
    setLoadingModal(true)
    const postPromises = Array.isArray(userFiles)
      ? userFiles.map((file) =>
        api.post(
          'rent_contract/document/',
          {
            file: file,
            rent_contract: rentId || id,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
      )
      : [];

    const deletePromises = Array.isArray(deleteApiFiles)
      ? deleteApiFiles.map((file) =>
        api.delete(`rent_contract/document/${file.id}`)
      )
      : [];

    const allPromises = Promise.all([...postPromises, ...deletePromises]);

    allPromises
      .then((responses) => {
        getDocuments(!!responses);
        setUserfiles([])
        setDeleteApiFiles([])
        params.id && enqueueSnackbar('Documentos atualizados', { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        params.id && enqueueSnackbar('Erro ao atualizar documentos', {
          variant: 'error',
        });
        enqueueSnackbar(error.response.data[0], { variant: 'error' });
      })
      .finally(()=>{
        setLoadingModal(false)
      })
  }

  function taxCreate (rentId) {
    const dataReq = dataCharge.map(charge => {
      return {
        ...charge,
        rent_contract: +rentId
      };
    })

    dataReq?.map((charge) => {
      if(!charge?.id ) {
        api.post(`rent_contract/tax/`, {
          description: charge?.description,
          value: +charge?.value?.toString()?.replace(/[^0-9-]/g, '') ?? '',
          installments: charge?.installments ?? null,
          rent_contract: rentId,
          installment_start: charge.installment_start ?? null,
          year: +charge.year,
          month: +charge.month,
          apply_proportional_calculation: charge.apply_proportional_calculation,
          transfer_recipient: charge.transfer_recipient,
          include_income_tax: charge.include_income_tax,
          charge_recipient: charge.charge_recipient
        })
          .then(response => {
            //getTax()
            setDataProvider(prevState =>({
              ...prevState,
              secondStep: true
            }))
            enqueueSnackbar('Cobrança criada', { variant: 'success' })
          })
          .catch(error => {
            enqueueSnackbar('Erro ao criar cobrança', { variant: 'error' })
            console.log(error)
          })
          .finally(() => {

          })
      }}
    )
  }

  function taxUpdate (rentId, chargeId) {
    const updatedCharge = dataCharge.find(charge => charge.id === chargeId);
    if (updatedCharge) {
      api.put(`rent_contract/tax/${updatedCharge.id}/`, {
        description: updatedCharge.description,
        value: +updatedCharge?.value?.toString()?.replace(/[^0-9-]/g, '') ?? '',
        // value: +Masks.formatMoneyString(updatedCharge.value?.toString()) || '',
        installments: updatedCharge.installments ?? null,
        rent_contract: rentId,
        installment_start: updatedCharge.installment_start ?? null,
        year: +updatedCharge.year,
        month: +updatedCharge.month,
        apply_proportional_calculation: updatedCharge.apply_proportional_calculation,
        transfer_recipient: updatedCharge.transfer_recipient,
        include_income_tax: updatedCharge.include_income_tax,
        charge_recipient: updatedCharge.charge_recipient
      })
        .then(response => {
          enqueueSnackbar('Cobrança atualizada', { variant: 'success' })
        })
        .catch(error => {
          enqueueSnackbar('Erro ao atualizar cobrança', { variant: 'error' })
          console.log(error)
        })
        .finally(() => {
          // getTax()
        })
    }
  }

  function postCredPago (rentId) {
    const { value, due_date  } = guaranteeData || {}

    const data = {
      ...guaranteeData,
      ...(value?.includes('R$') && { value: value.replace(/\D+/g, "") }),
      due_date: moment(due_date).format('YYYY-MM-DD'),
      rent_contract: +rentId ?? params.id,
    }

    const alternateReq = !reqData?.guarantee
        ? api.post(`rent_contract/cred_paid/`, data)
        : api.put(`rent_contract/cred_paid/${guaranteeData?.id}/`, data)

    alternateReq
      .then(response => {
        setShow(false)
        setDataProvider(prevState => ({
          ...prevState,
          fourthStep: false
        }))
      })
      .catch(error => {
        console.log(error)
      })
  }

  function postGuarantor(rentId) {
    const { phone, cpf_cnpj, cep } = guaranteeData || {};

    const data = {
      ...guaranteeData,
      cpf_cnpj: cpf_cnpj?.replace(/\D+/g, ""),
      phone: phone?.replace(/\D+/g, ""),
      cep: cep?.replace(/\D+/g, ""),
      rent_contract: +rentId ?? params.id,
    }

    const alternateReq = !reqData?.guarantee
        ? api.post(`rent_contract/guarantor/`, data)
        : api.put(`rent_contract/guarantor/${guaranteeData?.id}/`, data)

    alternateReq
      .then(response => {
        setShow(false)
        setDataProvider(prevState => ({
          ...prevState,
          fourthStep: false
        }))
      })
      .catch(error => {
        console.log(error)
      })
  }

  function postBailInsurance(rentId) {
    const { value } = guaranteeData || {};

    const data = {
      ...guaranteeData,
      value: value?.replace(/\D+/g, ""),
      rent_contract: +rentId ?? params.id,
    };


    const alternateReq = !reqData?.guarantee
        ? api.post(`rent_contract/bail_insurance/`, data) :
      api.put(`rent_contract/bail_insurance/${guaranteeData?.id}/`, data)

    alternateReq
      .then(response => {
        setShow(false)
        setDataProvider(prevState => ({
          ...prevState,
          fourthStep: false
        }))
      })
      .catch(error => {
        console.log(error)
      })
  }

  async function createCapitalization(rentId) {
    try {
      const data = {
        ...guaranteeData,
        rent_contract: +rentId ?? params.id,
      };

      const alternateReq = !reqData?.guarantee
        ? await api.post(`rent_contract/capitalization_bond/`, data)
        : await api.put(`rent_contract/capitalization_bond/${guaranteeData?.id}/`, data);

      setShow(false);
      setDataProvider((prevState) => ({
        ...prevState,
        fourthStep: false,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async function createEscrow(rentId) {
    const { value } = guaranteeData || {};

    try {
      const data = {
        ...guaranteeData,
        rent_contract: +rentId ?? params.id,
        value: +value?.replace(/\D+/g, ""),
      };

      const alternateReq = !guaranteeData?.id
        ? await api.post(`rent_contract/deposit/`, data)
        : await api.put(`rent_contract/deposit/${guaranteeData?.id}/`, data);

      setShow(false);
      setDataProvider((prevState) => ({
        ...prevState,
        fourthStep: false,
      }));
    } catch (error) {
      console.error(error);
    }
  }



  function bondCreate(refresh, setIsSuccess, setActiveBackdrop) {
    const dataReq = dataBond.map((bond) => {
      return {
        ...bond,
        rent_contract: id ?? dataProvider?.rentId,
      };
    });

    const putRequests = dataReq.filter((bond) => bond.id);
    const postRequests = dataReq.filter((bond) => !bond.id);

    // Create and execute PUT requests first (only when bond.id is present)
    const putPromises = putRequests.map((bond) => {
      return api.put(`/rent_recipient/signed_recipient/${bond.id}/`, {
        percentage: +bond.percentage,
        recipient_account: bond.recipient_account,
        recipient_pix_key: bond?.recipient_pix_key,
      });
    });

    // Wait for all PUT requests to complete before executing POST requests
    Promise.all(putPromises)
      .then((putResponses) => {
        // Now, create and execute POST requests
        const postPromises = postRequests.map((bond) => {
          return api.post(`/rent_recipient/signed_recipient/`, {
            percentage: +bond.percentage,
            rent_contract: +bond.rent_contract,
            recipient_account: bond.recipient_account,
            recipient_pix_key: bond?.recipient_pix_key,
            rent_recipient: bond?.rent_recipient,
          });
        });

        return Promise.all(postPromises); // Wait for all POST requests to complete
      })
      .then((postResponses) => {
        // All PUT and POST requests are completed at this point
        getBonds(null, refresh);
        getContract(dataProvider?.rentId, false)
        // enqueueSnackbar('Vínculo atualizado e beneficiário cadastrado', { variant: 'success' });
        setDataProvider((prevState) => ({
          ...prevState,
          fifthStep: true,
        }));
        setIsSuccess(true)
      })
      .catch((error) => {
        setActiveBackdrop(false)
        console.log(error);
        enqueueSnackbar('Erro ao atualizar vínculo ou cadastrar beneficiário', {
          variant: 'error',
        });
        error.response.data.rent_contract[0] && enqueueSnackbar(error.response.data.rent_contract[0], { variant: 'error' });
      });
  }






  // function bondUpdate(id) {
  //   const dataReq = dataBond.map(bond => {
  //     return {
  //       ...bond,
  //       rent_contract: id ?? dataProvider?.rentId
  //     };
  //   })
  //
  //   dataReq?.map((bond) => {
  //     if (!bond?.id) {
  //       api.put(`/rent_recipient/signed_recipient/${id}`, {
  //           percentage: bond.percentage,
  //           rent_contract: bond.rent_contract,
  //           recipient_account: bond.recipient_account,
  //           recipient_pix_key: bond?.recipient_pix_key,
  //         }
  //       )
  //         .then(response => {
  //           getBonds()
  //           enqueueSnackbar('Vínculo atualizado', {variant: 'success'})
  //         })
  //         .catch(error => {
  //           enqueueSnackbar('Error ao atualizar vínculo', {variant: 'error'})
  //           enqueueSnackbar(error.response.data[0], { variant: 'error' })
  //         })
  //     }
  //   })
  //
  // }

  ////////////////////
  // -> 3 Delete /////
  ////////////////////

  function taxDeleteReq () {
    if (taxDelete?.length > 0){
      taxDelete?.map(tax => {
        api.delete(`/rent_contract/tax/${tax?.id}/`)
          .then(response => {
          })
          .catch(error => {
            console.log(error)
          })
      })
    }
  }

  function taxDeleteById (taxId) {
    api.delete(`/rent_contract/tax/${taxId}/`)
      .then(response => {
        enqueueSnackbar('Cobrança deletada', { variant: 'success' })
        //getTax()
      })
      .catch(error => {
        enqueueSnackbar('Erro ao excluir cobrança', { variant: 'error' })
        console.log(error)
      })
  }

  function deleteBondById (param, refresh) {
    api.delete(`/rent_recipient/signed_recipient/${param}/`)
      .then(response => {
        enqueueSnackbar('Beneficiário descadastrado', { variant: 'success' })
        getBonds(!!response, refresh)
        // reactDeleteBond();
      })
      .catch(() => {
        enqueueSnackbar('Erro ao desvincular beneficiário', { variant: 'error' })
      })

  }


  useEffect(() => {
    // Atualize o estado com o ID da URL quando o componente montar
    setRentContractId(id);
  }, [id]);

  ////////////////////////////////////
  //// UseEffect, triggers, calls ////
  ////////////////////////////////////

  useEffect(function setFilters(){
    initalDataFilter()
  }, [])

  useEffect(function getInfo(){
    if(id){
      getContract()
      getDocuments()
    }
  },[])

  useEffect(function contractDefaultInput() {
    if (!reqData?.start_date || !reqData?.active) {
      setReqData(prevState => ({
        ...prevState,
        start_date: moment(),
        active: true,
      }));
    }
  }, [])

  useEffect(function recipientSign(){
    getBonds()
  },[])

  useEffect(function dueDayInvoice(){
    if (reqData?.bankslip_due_day) {
      setDataProvider(prevState => ({...prevState, invoiceDueDate: moment().set('date', reqData?.bankslip_due_day).format('YYYY-MM-DD') }))
    } else {
      setDataProvider(prevState => ({...prevState, invoiceDueDate: moment().add(1, 'day').format('YYYY-MM-DD') }))
    }
  },[reqData])

  useEffect(function disableSlipStep() {

    if (reqData?.bankslip_due_day) {
      setDataProvider(prevState => ({
        ...prevState,
        enableSlip: true
      }));
    }
  }, [reqData])

  useEffect(function unitDetails(){
    detailedUnit()
  },[reqData?.unit])

  useEffect(function disableRecipientStep(){
    const findRegister = dataBond.find(e => e.id)

    if (findRegister) {
      setDataProvider(prevState => ({
        ...prevState,
        enableRecipient: true
      }))
    }
  }, [dataBond])

  useEffect(function disableWarrantyStep() {
    const { value, due_date, insurance_company, insurance_policy_number, name, insurer, how_many_times_the_rent, description} = guaranteeData || {};

    const conditionCred = value && due_date && insurance_policy_number;
    const conditionGuarantor = !!name;
    const conditionSeguro = value && insurance_policy_number && insurance_company;
    const conditionCaptialization = insurer && insurance_policy_number && how_many_times_the_rent;
    const conditionDeposit = description && value;


    let enableWarranty = false;

    if (guarantee === 'credpago') {
      enableWarranty = !!conditionCred;
    } else if (guarantee === 'guarantor') {
      enableWarranty = !!conditionGuarantor;
    } else if (guarantee === 'bail_insurance') {
      enableWarranty = !!conditionSeguro;
    } else if (guarantee === 'titulo_de_captalização') {
      enableWarranty = !!conditionCaptialization;
    } else if (guarantee === 'deposit') {
      enableWarranty = !!conditionDeposit;
    }

    setDataProvider(prevState => ({
      ...prevState,
      enableWarranty
    }));
  }, [guaranteeData, guarantee])

  useEffect(function disabletaxStep(){
    setDataProvider(prevState => ({
      ...prevState,
      enableCharge: dataCharge?.length
    }))
  }, [dataCharge])

  const handleReqData = (newReqData) => {
    setReqData(newReqData);
  }

  return (
    <>
      <Helmet>
        <title>{`${helmetTitle()} - Bloco B3`}</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loadingModal}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      {!id ?
        <Container
          maxWidth={false}
        >
          <Grid container spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
          >
            <Grid item xs={12} lg={8}>
              <RentWizard
                create={create}
                update={update}
                warrantyChoice={warrantyChoice}
                gambiUpdateWarranty={gambiUpdateWarranty}
              >
                <Grid container spacing={2}>

                  {activeStep === 0 && !dataProvider.firstStep &&
                    <ContractForm
                      prevStateData={prevStateData}
                      contractLoad={contractLoad}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      reqData={reqData}
                      setReqData={setReqData}
                      unitOptions={unitOptions}
                      error={error}
                      setError={setError}
                      getContract={getContract}
                      update={update}
                    />
                  }

                  {activeStep === 0 && dataProvider.firstStep &&
                    stepComplete()
                  }

                  {activeStep === 1 &&
                    <RentContractFee />
                  }

                  {activeStep === 2 &&
                    <Grid item xs={12}>
                      <RentSlipOptions
                        getContract={getContract}
                        update={update}
                        prevStateData={prevStateData}
                        reqData={reqData}
                        setReqData={setReqData}
                        isLoading={isLoading}
                        error={error}
                      />
                    </Grid>
                  }

                  {activeStep === 3 &&
                    <Grid item xs={12}>
                      <RentWarranty
                        rentData={reqData}
                        guaranteeData={guaranteeData}
                        setGuaranteeData={setGuaranteeData}
                        insuranceData={insuranceData}
                        setInsuranceData={setInsuranceData}
                        guarantee={guarantee}
                        setGuarantee={setGuarantee}
                        isLoading={isLoading}
                        getContract={getContract}
                        update={update}
                        setIsLoading={setIsLoading}
                        show={show}
                        setShow={setShow}
                        warrantyChoice={warrantyChoice}
                        getWarranty={getGuarantee}
                        getInsurance={getInsurance}
                        prevWarranty={prevWarranty}
                      />
                    </Grid>
                  }

                  {activeStep === 4 &&
                    <RecipientTransferCard
                      dataBond={dataBond}
                      setDataBond={setDataBond}
                      isLoading={isLoading}
                      setBondDelete={setBondDelete}
                      deleteBondById={deleteBondById}
                      bondCreate={bondCreate}
                      getBonds={getBonds}
                      getContract={getContract}
                      prevStateData={prevStateData}
                      reqData={reqData}
                      setReqData={setReqData}
                      setIsLoading={setIsLoading}
                    />
                  }

                  {activeStep === 5 &&
                    <ContractConclusionCard/>
                  }

                  {activeStep === 0 && !dataProvider.firstStep &&
                    <RentDocuments
                      loadingDocuments={loadingDocuments}
                    />
                  }
                </Grid>
              </RentWizard>
           </Grid>
          </Grid>
        </Container>
        :
        <Container
          maxWidth={false}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>

                <ContractForm
                  prevStateData={prevStateData}
                  contractLoad={contractLoad}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  reqData={reqData}
                  setReqData={setReqData}
                  unitOptions={unitOptions}
                  error={error}
                  getContract={getContract}
                  update={update}
                />

                <RecipientTransferCard
                  targetRef={targetRef}
                  dataBond={dataBond}
                  setDataBond={setDataBond}
                  isLoading={isLoading}
                  setBondDelete={setBondDelete}
                  deleteBondById={deleteBondById}
                  bondCreate={bondCreate}
                  getBonds={getBonds}
                  getContract={getContract}
                  prevStateData={prevStateData}
                  setIsLoading={setIsLoading}
                  reqData={reqData}
                  setReqData={setReqData}
                  error={error}
                />

                <RentContractFee />

                {id &&
                  <RentNotes
                    rentNotes={rentNotes}
                    setRentNotes={setRentNotes}
                    isLoading={isLoading}
                  />
                }

              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>
                <RentDocuments
                  loadingDocuments={loadingDocuments}
                  getDocuments={getDocuments}
                  documentPromise={documentPromise}
                />

                <Fade
                  in={!!id}
                  mountOnEnter
                  unmountOnExit
                  timeout={300}
                >
                  <Grid item xs={12}>
                    <ContractInvoices
                      unitId={reqData?.unit}
                      status={reqData?.status}
                    />
                  </Grid>
                </Fade>

                <Grid item xs={12}>
                  <RentSlipOptions
                    getContract={getContract}
                    update={update}
                    prevStateData={prevStateData}
                    reqData={reqData}
                    setReqData={setReqData}
                    isLoading={isLoading}
                    error={error}
                  />
                </Grid>


                <Grid item xs={12}>
                  <AutoTransfer
                      reqData={reqData}
                      setReqData={setReqData}
                      getContract={getContract}
                      prevStateData={prevStateData}
                      error={error}
                  />

                </Grid>

                <Grid item xs={12}>
                  <RentWarranty
                    rentData={reqData}
                    guaranteeData={guaranteeData}
                    setGuaranteeData={setGuaranteeData}
                    insuranceData={insuranceData}
                    setInsuranceData={setInsuranceData}
                    guarantee={guarantee}
                    setGuarantee={setGuarantee}
                    isLoading={isLoading}
                    getContract={getContract}
                    update={update}
                    setIsLoading={setIsLoading}
                    show={show}
                    setShow={setShow}
                    warrantyChoice={warrantyChoice}
                    getWarranty={getGuarantee}
                    getInsurance={getInsurance}
                    prevWarranty={prevWarranty}
                  />
                  {(reqData?.status === 'active' &&
                                  <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      width="100%"
                                      marginTop={'1.5rem'}
                                  >
                                    <TerminateContractForm
                                        open={open}
                                        params={params}
                                        handleClose={() => setOpen(false)}
                                        updateReqData={handleReqData}
                                    />
                                  </Box>
                  )
                  }

                  {(reqData?.status === 'inactive' && reqData?.warning_date &&
                                 <Box
                                       display="flex"
                                       justifyContent="center"
                                      alignItems="center"
                                       width="100%"
                                       marginTop={'1.5rem'}
                                   >
                                    <TerminateContractDetail
                                         open={open}
                                         params={params}
                                         handleClose={() => setOpen(false)}
                                         data={reqData}
                                     />
                                   </Box>


                      )
                  }
                </Grid>

              </Grid>
            </Grid>

          </Grid>
          <SimpleButtons
            type={'button'}
            // forwardButton={id ? 'Salvar' : 'Cadastrar'}
            // onForward={() => id ? update() : create() }
          />
        </Container>
      }
    </>
  )
}

export default RentForm