import {api} from "../../../../../services/Main/Api";
import {getCondoId} from "../../../../../services/Main/AuthStorage";
import moment from "moment";
import login from "../../../User/Login";






// export const submitTransfer = (e, reqData, selectedAccount, se) => {
//   e.preventDefault()
//
//     api.post('fitbank/money-transfer/', {
//       condo: +getCondoId(),
//       value: +reqData?.value.replace(/\D+/g, ""),
//       external_bank: +selectedAccount?.id,
//       description: reqData?.description,
//       payment_date: reqData?.payment_date,
//     })
//       .then((res) => {
//         enqueueSnackbar(res.data.status.label, { variant: 'success' })
//         setReqData({ payment_date: moment().format('YYYY-MM-DD') })
//         setSelectedAccount('')
//         setCheck(prevState => !prevState)
//       })
//       .catch((error) => {
//         enqueueSnackbar(error.response.data, { variant: 'error' })
//         console.log(error)
//         setCheck(prevState => !prevState)
//       })
//       .finally(()=>{
//         setTransferLoad(prevState => !prevState)
//       })
//
//
// }



export const getAcc = (setCheckingAccounts, filters) => {
  const condo = +getCondoId()
  const alternateUrl = filters?.transferType === 'ted' ?
    api.get(`fitbank/external-bank/?condo_id=${condo}`)
    : filters?.transferType === 'pix' &&
    api.get(`fitbank/pix-key/?condo_id=${condo}`)

  if (filters?.transferType) {
  alternateUrl
    .then((res) => {
      const { results } = res.data
      setCheckingAccounts(results)
    })
  }
}

export const getBanks = (setBankNames) => {
  if (setBankNames) {
    api.get('fitbank/bank-choices/')
      .then((res) => {
        const {data} = res
        data?.splice(137, 2)
        setBankNames(data)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
      })
  }
}

export const searchAcc = (account, setSelectedAccount, filters) => {

  if (account?.id && filters?.transferType){

    const alternateUrl = filters?.transferType === 'ted' ?
      api.get(`fitbank/external-bank/${account?.id}`)
      : filters?.transferType === 'pix' &&
      api.get(`fitbank/pix-key/${account?.id}`)

    alternateUrl
      .then((res) => {
        setSelectedAccount(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

export const getAccountData = (setAccountData) => {


  api.get(`fitbank/${getCondoId()}/`)
    .then((response) => {
      setAccountData({
        bank: {
          name: 'Fitbank',
          code: response.data.spb_bank,
        },
        bank_branch: response.data.spb_bank_branch,
        bank_account: `${response.data.spb_bank_account} - ${response.data.spb_bank_account_digit}`,
        pix_key_company: response.data.pix_key_company,
      })
    }).catch((error) => {
    console.log(error)
  })
    .then(() =>{
      api.get(`fitbank/balance/${getCondoId()}/`)
        .then((response) => {
          setAccountData(prevState=>({
            ...prevState,
            balance: Math.round(response.data.balance * 100).toString()
          }))
        })
        .catch(error => {
          console.log(error)
        })
    })

}

