import {
    Alert,
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, InputAdornment,
    InputLabel, ListSubheader,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Skeleton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
    Add,
    Business,
    Home,
    LocalAtm,
    LocalAtmOutlined, Lock,
    Money, Percent,
    ReceiptLong,
    ReceiptOutlined
} from '@mui/icons-material'
import Masks from '../../../components/Shared/Masks'
import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {MobileDatePicker} from '@mui/x-date-pickers'
import {api} from '../../../services/Main/Api'


import Context from '../../../contexts/Context'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {getCondoId} from "../../../services/Main/AuthStorage";
import CircularProgress from '@mui/material/CircularProgress'
import AnimationGlobal from '../../../components/Shared/animations/AnimationGlobal'
import successAnimation from '../../../assets/animations/success.json'
import {useSnackbar} from "notistack";
import RentRecipientInputs from "../RentRecipient/RecipientForm/RentRecipientInputs";
import RentRecipientAccountSet from "../RentRecipient/RentRecipientAccountSet";
import RentRecipientPixSet from "../RentRecipient/RentRecipientPixSet";
import RentRecipientFormSet from "./RentRecipientSet/RentRecipientFormSet";

function RentContractForm(props) {
    //////////////////////////////////////
    //// States, Provider and Hooks /////
    /////////////////////////////////////
    const {id} = useParams();
    const {dataProvider} = useContext(Context)
    //const { data, onChange, setTaxDelete, isLoading, taxUpdate, taxCreate, taxDeleteById, getTax } = props;
    const [filters, setFilters] = useState({fully_paid: false})

    const [reqPercent, setReqPercent] = useState(null);
    const [percentList, setPercentList] = useState([])
    const [openDelete, setOpenDelete] = useState(false);
    const [handleItem, setHandleItem] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [contractTime, setContractTime] = useState(30);
    const [customContractTime, setCustomContractTime] = useState(0);
    const [readjustment, setReadjustment] = useState("IGPM");
    const [typeContract, setTypeContract] = useState("residencial");
    const [administrativeTax, setAdministrativeTax] = useState(10);
    const [intermediationFee, setIntermediationFee] = useState(0);
    const [renterChoices, setRenterChoies] = useState([]);
    const [renter, setRenter] = useState(null);
    const [enableCharge, setEnableCharge] = useState(false);
    const [enableRecipient, setEnableRecipient] = useState(false);
    const [chargeDay, setChargeDay] = useState(1);
    const [chargeDueDate, setChargeDueDate] = useState(5);
    const [emailCharge, setEmailCharge] = useState(true);
    const [fineAndInterest, setFineAndInterest] = useState(false);
    const [fineValue, setFineValue] = useState(10);
    const [fineType, setFineType] = useState("PERCENTAGE");
    const [interestValue, setInterestValue] = useState(1);
    const [interestType, setInterestType] = useState("PERCENTAGE");
    const [transfer, setTransfer] = useState(false);
    const [transferDay, setTransferDay] = useState(10);
    const [transferType, setTransferType] = useState("MANUAL");
    const [prepaid, setPrepid] = useState(false);
    const [openDialog, setOpenDialog] = useState(true);
    const [redirectURL, setRedirectURL] = useState(null);
    const [redirectURLNow, setRedirectURLNow] = useState(false);
    const [openDetailDialog, setOpenDetailDialog] = useState(false);

    const [isSaving, setIsSaving] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [activeBackdrop, setActiveBackdrop] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(moment().toDate());
    const [value, setValue] = useState(0);
    const [valueFormated, setValueFormated] = useState("R$ 0,00");

    const [includeIncomeTax, setIncludeIncomeTax] = useState(true);
    const [proportionalCalculation, setProportionalCalculation] = useState(true);
    const {user} = useContext(Context)
    const {perm_bank} = user || {}
    const {enqueueSnackbar} = useSnackbar()
    const [rentRecipients, setRentRecipients] = useState([]);
    const [rentRecipient, setRentRecipient] = useState([
        {
            name: '',
            cpf: '',
            phone: '',
            email: '',
            percent: 100,
            pix_or_ted: 'PIX',
            pix_key: '',
            bank: '',
            branch: '',
            branch_digit: '',
            account: '',
            account_digit: '',
            account_type: '0',
        }
    ]);



    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    function handleAddData() {
        setOpenDialog(true)
    }


    function resetFields() {
        setValueFormated("0,00")
        setStartDate(moment().toDate())
        setTypeContract("residencial")
        setContractTime(30)
        setReadjustment("IGPM")
        setAdministrativeTax(10)
        setIntermediationFee(100)
        setRenter(null)
        setEnableCharge(false)
        setChargeDay(1)
        setChargeDueDate(5)
        setEmailCharge(true)
        setInterestType("FIX")
        setInterestValue(0)
        setFineType("FIX")
        setFineValue(0)
        setTransfer(false)
        setPrepid(false)
        setTransferDay(10)
        setTransferType("MANUAL")

    }

    const verifyPercentageSum = () => {
        const percentageSum = rentRecipients?.reduce((sum, receiver) => {
            return sum + parseFloat(receiver.percent || 0);
        }, 0);

        return parseFloat(percentageSum) === 100.0;
    }

    function handleSaveRent() {
        setIsSaving(true);
        setOpenDialog(false)
        setActiveBackdrop(true)

        const idRenter = renter.id
        const value = parseInt(valueFormated.replace(/[^0-9]/g, ''))
        const activeDate = moment(startDate).format("YYYY-MM-DD")
        let data = {
            value,
            start_date: activeDate,
            contract_type: typeContract,
            contract_time: contractTime,
            readjustment_index: readjustment,
            administrative_tax: administrativeTax,
            intermediation_fee: intermediationFee,
            is_income_tax: includeIncomeTax,
            is_proportional: proportionalCalculation,
            unit: idRenter,
            rent_recipients: rentRecipients,
            prepaid
        }
        if (contractTime === 0) {
            data.contract_time = customContractTime
        }

        if (enableCharge) {
            data.generate_bankslip = enableCharge
            data.generate_bankslip_day = parseInt(chargeDay)
            data.bankslip_due_day = parseInt(chargeDueDate)
            data.send_email = emailCharge
        }

        if (fineAndInterest) {
            data.fine_and_interest = fineAndInterest
            if (fineType === "FIX") {
                data.bankslip_fine_value = parseInt(fineValue.replace(/[^0-9]/g, ''))
            } else {
                data.bankslip_fine_percent = fineValue
            }

            if (interestType === "FIX") {
                data.bankslip_interest_value = parseInt(interestValue.replace(/[^0-9]/g, ''))
            } else {
                data.bankslip_interest_percent = interestValue
            }

        }

        if (transfer) {
            if (transferType === "AUTO") {
                data.auto_transfer = true
            } else {
                data.auto_transfer = false
            }
            data.transfer_day = parseInt(transferDay)
        }


        api.post("/rent-contract/", data).then(response => {

            const result = response.data
            window.location.href = `${process.env.REACT_APP_HOST_URL}/contratos/${result.id}`
            setTimeout(() => {
                setIsSuccess(true)
                resetFields()
            }, 3000);

            setIsSaving(false);

            setTimeout(() => {
                setActiveBackdrop(false)
                // setIsSuccess(false)
                props.closeForm()

            }, 4000);

        }).catch(error => {
            setIsSaving(false);
            setError(error?.response)
            error.response.data.generate_bankslip_day && enqueueSnackbar(error.response.data.generate_bankslip_day, {variant: 'error'})
            error.response.data.transfer_day && enqueueSnackbar(error.response.data.transfer_day, {variant: 'error'})
            console.log('error', error)
            error.response?.data?.message && enqueueSnackbar(error.response.data.message, {variant: 'error'})
            error.response?.data?.[0] && enqueueSnackbar(error.response.data[0], {variant: 'error'})
            setOpenDialog(true)
        })
    }

    const addRentRecipient = () => {
        let totalPercent = 0;
        for(let i = 0; i < rentRecipient.length; i++) {
            totalPercent += Number(rentRecipient[i].percent);
        }

        let availablePercent = totalPercent > 100 ? 0 : 100 - totalPercent;

        setRentRecipient([...rentRecipient, {
            name: '',
            cpf: '',
            phone: '',
            email: '',
            percent: availablePercent,
            pix_or_ted: 'PIX',
            pix_key: '',
            bank: '',
            branch: '',
            branch_digit: '',
            account: '',
            account_digit: '',
            account_type: '1',
        }]);
    };

    const getRentRecipient = () => {
        if (renter?.owners.length > 0) {
            const total = 100 / renter.owners.length;
            renter.owners.map(owner => {
                setRentRecipient([...rentRecipient, {
                    name: owner.name,
                    cpf: owner.cpf_cnpj,
                    phone: owner.phone,
                    email: owner.email,
                    percent: total,
                    pix_or_ted: 'PIX',
                    pix_key: '',
                    bank: '',
                    branch: '',
                    branch_digit: '',
                    account: '',
                    account_digit: '',
                    account_type: '1',
                }]);
            })
        }else {
            addRentRecipient()
        }
    };


    useEffect(() => {
        console.log("agoraaa", redirectURL)
        //window.location.href = redirectURL
    }, [redirectURLNow])

    function getRenters() {
        const condo_id = getCondoId()
        api.get(`/rent-contract/unit/?condo_id=${condo_id}`).then(response => {
            const {data} = response
            setRenterChoies(data.results)
        }).catch(error => {
            console.log(error)
        })
    }


    useEffect(() => {
        getRenters()
    }, [])


    useEffect(() => {
        setOpenDelete(true)
    }, [handleItem])

    useEffect(() => {
        if(rentRecipient && rentRecipients && !enableRecipient) {
            setRentRecipients([]);
            setRentRecipient([]);
        }
    }, [enableRecipient]);

    useEffect(() => {
        resetRecipient()

    }, [renter])

    const resetRecipient = () => {
        setRentRecipient([]);


    }


    return (
        <Grid item xs={12}>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={openDialog}
                scroll={'body'}
                onClose={() => props.closeForm()}
            >
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '1rem'}}>
                    <DialogTitle>Criar Contrato</DialogTitle>
                    <Box>
                        <ToggleButtonGroup
                            color="primary"
                            value={typeContract}
                            exclusive
                            onChange={(event) => {

                                setTypeContract(event.target.value)
                                if (event.target.value === 'commercial') {
                                    setContractTime(60)
                                } else {
                                    setContractTime(30)
                                }
                            }}
                            aria-label="Platform"
                            size={'small'}
                        >
                            <ToggleButton value="residencial">
                                Residencial
                            </ToggleButton>
                            <ToggleButton value="commercial">
                                Comercial
                            </ToggleButton>

                        </ToggleButtonGroup>
                    </Box>

                </Box>

                <Divider/>

                <DialogContent>

                    <Box
                        noValidate
                        component="form"

                    >
                        <Grid container spacing={2} mt={2}>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth>
                                    <MobileDatePicker
                                        label="Data de inicio"
                                        value={startDate}
                                        onChange={(timestamp) => {
                                            setStartDate(timestamp)
                                        }}
                                        slotProps={{
                                            field: {clearable: true, onClear: () => setCleared(true)},
                                            textField: {
                                                size: "small"
                                            }
                                        }}
                                    />

                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth size={'small'}>
                                    {contractTime === 0 ?
                                        <>
                                            <InputLabel>Tempo de contrato</InputLabel>
                                            <OutlinedInput placeholder="Tempo de contrato"
                                                           label={"Tempo de contrato"}
                                                           defaultValue={customContractTime}
                                                           onChange={(event) => {
                                                               setCustomContractTime(event.target.value)
                                                           }}
                                                           endAdornment={<InputAdornment
                                                               position="end">meses</InputAdornment>}
                                            />
                                        </>
                                        :
                                        <>
                                            <InputLabel id="transfer-select-label">Tempo de contrato</InputLabel>
                                            <Select
                                                labelId="transfer-select"
                                                id="transfer-select"
                                                defaultValue={contractTime}
                                                value={contractTime}
                                                label="Tempo de contrato"

                                                onChange={(event) => {
                                                    setContractTime(event.target.value)
                                                }}
                                            >

                                                <MenuItem value={12}>{'12 Meses'}</MenuItem>
                                                <MenuItem value={30}>{'30 Meses'}</MenuItem>
                                                <MenuItem value={48}>{'48 Meses'}</MenuItem>
                                                <MenuItem value={60}>{'60 Meses'}</MenuItem>
                                                <MenuItem value={0}>{'Personalizar'}</MenuItem>

                                            </Select> </>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth size={'small'}>

                                    <InputLabel id="transfer-select-label">Indice para reajuste</InputLabel>
                                    <Select
                                        labelId="transfer-select"
                                        id="transfer-select"
                                        defaultValue={readjustment}
                                        value={readjustment}
                                        label="Indice para reajuste"

                                        onChange={(event) => {
                                            setReadjustment(event.target.value)
                                        }}
                                    >
                                        <MenuItem value={"IGPM"}>{"IGP-M"}</MenuItem>
                                        <MenuItem value={"IPCA"}>{"IPC-A"}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth size={'small'} required={true}>
                                    <InputLabel>Taxa Administrativa</InputLabel>
                                    <OutlinedInput
                                        onChange={(event) => {
                                            if (event.target.value > 100) {
                                                setAdministrativeTax(100)
                                            } else if (event.target.value < 0) {
                                                setAdministrativeTax(0)
                                            } else {
                                                setAdministrativeTax(event.target.value)
                                            }

                                        }}
                                        value={administrativeTax}
                                        label="Taxa Administrativa"
                                        endAdornment={<InputAdornment position="end">
                                            <Percent/>
                                        </InputAdornment>}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth size={'small'} required={true}>
                                    <InputLabel>Taxa de Intermediação</InputLabel>
                                    <OutlinedInput
                                        onChange={(event) => {
                                            if (event.target.value > 100) {
                                                setIntermediationFee(100)
                                            } else if (event.target.value < 0) {
                                                setIntermediationFee(0)
                                            } else {
                                                setIntermediationFee(event.target.value)
                                            }

                                        }}
                                        value={intermediationFee}
                                        label="Taxa de Intermediação"

                                        endAdornment={<InputAdornment position="end">
                                            <Percent/>
                                        </InputAdornment>}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth size={'small'} required={true}>
                                    <InputLabel>Valor do Aluguel</InputLabel>
                                    <OutlinedInput
                                        onChange={(event) => {
                                            setValueFormated(Masks?.money(event.target.value))

                                        }}
                                        value={valueFormated}
                                        label="Valor do Aluguel"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size={'small'}>

                                    <FormLabel sx={{color: "#333"}} id="demo-row-radio-buttons-group-label">
                                        Aluguel é proporcional no 1º mês <Tooltip
                                        title="Este campo somente é valido para calcular a proporcionalidade no primeiro mês de contrato."><InfoOutlinedIcon
                                        fontSize={"12"}/></Tooltip>
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue={proportionalCalculation}
                                        onChange={(event) => {
                                            setProportionalCalculation(event.target.value)
                                        }}
                                    >
                                        <FormControlLabel value={true} control={<Radio/>} label="Sim"/>
                                        <FormControlLabel value={false} control={<Radio/>} label="Não"/>

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size={'small'}>

                                    <FormLabel sx={{color: "#333"}} id="demo-row-radio-buttons-group-label">
                                        Tipo do contrato <Tooltip
                                        title="Modo de cobrança do contrato, pré-pago iniciará a cobrança no mês da data de inicio do contrato."><InfoOutlinedIcon
                                        fontSize={"12"}/></Tooltip>
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue={prepaid}
                                        onChange={(event) => {
                                            setPrepid(event.target.value)
                                        }}
                                    >
                                        <FormControlLabel value={true} control={<Radio/>} label="Pré-pago"/>
                                        <FormControlLabel value={false} control={<Radio/>} label="Pós-pago"/>

                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {typeContract === 'commercial' && <Grid item xs={12} md={6}>
                                <FormControl fullWidth size={'small'}>
                                    <FormLabel sx={{color: "#333"}} id="irrf-radio-buttons-group-label">
                                        Habilitar dedução do IRRF <Tooltip
                                        title="Esse campo inclui ou não o valor na dedução do Imposto de Renda Retido na Fonte. "><InfoOutlinedIcon
                                        fontSize={"12"}/></Tooltip>
                                    </FormLabel>

                                    <RadioGroup
                                        row
                                        aria-labelledby="irrf-radio-buttons-group-label"
                                        name="irrf-radio-buttons-group"
                                        defaultValue={includeIncomeTax}
                                        onChange={(event) => {
                                            setIncludeIncomeTax(event.target.value)
                                        }}
                                    >
                                        <FormControlLabel value={true} control={<Radio/>} label="Sim"/>
                                        <FormControlLabel value={false} control={<Radio/>} label="Não"/>

                                    </RadioGroup>
                                </FormControl>
                            </Grid>}
                        </Grid>


                    </Box>
                </DialogContent>
                <Divider/>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '1rem'}}>
                    <DialogTitle>Dados do locatário</DialogTitle>
                </Box>

                <DialogContent>
                    <Grid container spacing={2} mt={0}>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="transfer-select-label">Locatário</InputLabel>
                                <Select
                                    labelId="transfer-select"
                                    id="transfer-select"
                                    defaultValue={renter}
                                    value={renter}
                                    label="Locatário"

                                    onChange={(event) => {
                                        setRenter(event.target.value)
                                        setIsSaving(false)

                                    }}

                                >
                                    {renterChoices.map((item, index) =>
                                        <MenuItem key={index} value={item} data={item}>{item.name}</MenuItem>
                                    )}
                                    {/*<ListSubheader>*/}
                                    {/*  <Divider />*/}

                                    {/*</ListSubheader>*/}
                                    {/*<MenuItem value={0} >*/}
                                    {/*  Adicionar novo locatário</MenuItem>*/}
                                </Select>
                            </FormControl>
                        </Grid>
                        {renter && <Grid item xs={12} md={4}>
                            <Typography sx={{fontWeight: 'bold'}}>Nome:</Typography>
                            <Typography variant="body2" display="block" gutterBottom>
                                {renter?.name}
                            </Typography>
                        </Grid>}
                        {renter && <Grid item xs={12} md={4}>
                            <Typography sx={{fontWeight: 'bold'}}>CPF/CNPJ:</Typography>
                            <Typography variant="body2" display="block" gutterBottom>
                                {renter?.cpf}
                            </Typography>
                        </Grid>}
                        {renter && <Grid item xs={12} md={4}>
                            <Typography sx={{fontWeight: 'bold'}}>Telefone:</Typography>
                            <Typography variant="body2" display="block" gutterBottom>
                                {renter?.phone}
                            </Typography>
                        </Grid>}
                        {renter && <Grid item xs={12} md={12}>
                            <Typography sx={{fontWeight: 'bold'}}>Endereço:</Typography>
                            <Typography variant="body2" display="block" gutterBottom>
                                {renter?.full_address}
                            </Typography>
                        </Grid>}

                    </Grid>


                </DialogContent>

                    <Divider/>


                    <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                        Beneficiário
                        <Switch
                            checked={enableRecipient}
                            onChange={() => {

                                    getRentRecipient()


                                setEnableRecipient(!enableRecipient);


                            }}
                            name="enableRecipient"
                            color="primary"

                        />
                    </DialogTitle>


                {enableRecipient &&
                    <DialogContent>
                        <Grid container spacing={2} mt={0}>
                            <Grid item>

                                <RentRecipientFormSet
                                    rentRecipients={rentRecipients}
                                    rentRecipient={rentRecipient}
                                    setRentRecipients={setRentRecipients}
                                    setRentRecipient={setRentRecipient}
                                    setError={setError}
                                    error={error}
                                />

                            </Grid>
                        </Grid>
                    </DialogContent>}

                    <Divider/>


                    <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            Cobrança Automática
                            {!perm_bank &&
                                <Tooltip title="Você precisa ativar o banco para usar essa funcionalidade.">
                                    <Lock sx={{ml:1}} fontSize={"small"} color={"warning"}/>
                                </Tooltip>
                            }
                        </Box>


                        <Switch
                            checked={enableCharge}
                            onChange={() => setEnableCharge(!enableCharge)}
                            name="enableCharge"
                            color="primary"
                            disabled={!perm_bank}

                        />
                    </DialogTitle>


                {enableCharge &&
                    <DialogContent>
                        <Grid container spacing={2} mt={0}>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth size={'small'}>
                                    <InputLabel>Gerar boleto todo dia</InputLabel>
                                    <OutlinedInput
                                        onChange={(event) => {
                                            setChargeDay(event.target.value)
                                        }}
                                        value={chargeDay}
                                        label="Gerar boleto todo dia"

                                    />


                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth size={'small'}>
                                    <InputLabel>Vencimento para o dia</InputLabel>
                                    <OutlinedInput
                                        onChange={(event) => {
                                            setChargeDueDate(event.target.value)
                                        }}
                                        value={chargeDueDate}
                                        label="Vencimento para o dia"

                                    />


                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth size={'small'}>
                                    <FormLabel sx={{color: "#333"}} id="irrf-radio-buttons-group-label">
                                        Enviar e-mail
                                    </FormLabel>

                                    <RadioGroup
                                        row
                                        aria-labelledby="irrf-radio-buttons-group-label"
                                        name="irrf-radio-buttons-group"
                                        defaultValue={emailCharge}
                                        onChange={(event) => {
                                            setEmailCharge(event.target.value)
                                        }}
                                    >
                                        <FormControlLabel value={true} control={<Radio/>} label="Sim"/>
                                        <FormControlLabel value={false} control={<Radio/>} label="Não"/>

                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </DialogContent>}

                    <Divider/>


                    <DialogTitle sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            Multa e Juros
                            {!perm_bank &&
                                <Tooltip title="Você precisa ativar o banco para usar essa funcionalidade.">
                                    <Lock sx={{ml:1}} fontSize={"small"} color={"warning"}/>
                                </Tooltip>
                            }
                        </Box>
                        <Switch
                            checked={fineAndInterest}
                            onChange={() => setFineAndInterest(!fineAndInterest)}
                            name="fineAndInterest"
                            color="primary"
                            disabled={!perm_bank}

                        />
                    </DialogTitle>


                {fineAndInterest && <DialogContent>
                    <Grid container spacing={2} mt={0}>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="interest-and-fines-label">Tipo da Multa</InputLabel>
                                <Select
                                    labelId="interest-and-fines"
                                    id="interest-and-fines"
                                    defaultValue={fineType}
                                    value={fineType}
                                    label="Tipo da Multa"
                                    onChange={(event) => {
                                        setFineType(event.target.value)
                                        setFineValue(0)
                                    }}
                                >
                                    <MenuItem value={"FIX"}>Fixo</MenuItem>
                                    <MenuItem value={"PERCENTAGE"}>Percentagem</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel>Multa</InputLabel>
                                <OutlinedInput
                                    onChange={(event) => {
                                        if (fineType === "FIX") {
                                            setFineValue(Masks?.money(event.target.value))
                                        } else {
                                            setFineValue(event.target.value)
                                        }
                                    }}
                                    value={fineValue}
                                    label="Multa"
                                />


                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="interest-and-fines-label">Tipo juros</InputLabel>
                                <Select
                                    labelId="interest-and-fines"
                                    id="interest-and-fines"
                                    defaultValue={interestType}
                                    value={interestType}

                                    label="Tipo dos juros"

                                    onChange={(event) => {
                                        setInterestType(event.target.value)
                                        setInterestValue(0)
                                    }}

                                >
                                    <MenuItem value={"FIX"}>Fixo</MenuItem>
                                    <MenuItem value={"PERCENTAGE"}>Percentagem</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel>Juros</InputLabel>
                                <OutlinedInput
                                    onChange={(event) => {
                                        if (interestType === "FIX") {
                                            setInterestValue(Masks?.money(event.target.value))
                                        } else {
                                            setInterestValue(event.target.value)
                                        }
                                    }}
                                    value={interestValue}
                                    label="Juros"

                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>}

                    <Divider/>


                    <DialogTitle sx={{display: "flex", justifyContent: "space-between"}}>
                        Repasse
                        <Switch
                            checked={transfer}
                            onChange={() => setTransfer(!transfer)}
                            name="transfer"
                            color="primary"

                        />
                    </DialogTitle>



                {transfer && <DialogContent>
                    <Grid container spacing={2} mt={0}>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel id="interest-and-fines-label">Tipo do repasse</InputLabel>
                                <Select
                                    labelId="interest-and-fines"
                                    id="interest-and-fines"
                                    defaultValue={"FIX"}
                                    value={transferType}
                                    label="Tipo do repasse"
                                    onChange={(event) => {
                                        setTransferType(event.target.value)
                                    }}
                                >
                                    <MenuItem value={"MANUAL"}>Manual</MenuItem>
                                    <MenuItem value={"AUTO"} disabled={!perm_bank}>
                                        Automático
                                        {!perm_bank &&
                                                <Lock sx={{ml:1}} fontSize={"small"} color={"warning"}/>
                                        }

                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth size={'small'}>
                                <InputLabel>Realizado todo dia</InputLabel>
                                <OutlinedInput
                                    onChange={(event) => {
                                        setTransferDay(event.target.value)
                                    }}
                                    value={transferDay}
                                    label="Realizado todo dia"
                                />


                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>}


                <Divider/>
                <DialogActions sx={{display: "flex", justifyContent: "space-between", padding: "2rem 1rem"}}>
                    <Button variant="text" color={"secondary"} onClick={() => props.closeForm()}>Cancelar</Button>
                    <Button variant="contained" disabled={isSaving} color={"primary"}
                            onClick={() => handleSaveRent()}>{"Salvar"}</Button>
                </DialogActions>
            </Dialog>

            {redirectURL}
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={activeBackdrop}
            >
                {!isSuccess ? <Box><CircularProgress color="inherit"/> </Box>
                    : <AnimationGlobal
                        text={'Seu contrato foi foi criado com sucesso!'}
                        size={'200px'}
                        animation={successAnimation}
                        fontStyles={{mb: '1rem'}}
                        fontSize={'20px'}
                        color="#FFFFFF"
                    />}
            </Backdrop>


        </Grid>
    );
}

export default RentContractForm