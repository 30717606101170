import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  TableHead, TableRow, TableCell, TableBody, Typography, Chip, Table, Link, DialogActions, Divider
} from '@mui/material'
import UserMenu from '../../components/Shared/UserMenu'
import React, {useContext, useEffect, useState} from 'react'
import BreadCrumbs from './BreadCrumbs'
import { MenuOutlined, OpenInNew } from '@mui/icons-material'
import NotificationMenu from '../../components/Shared/NotificationMenu'
import BalanceBar from '../../components/Bank/BalanceBar'
import SelectedCondo from "../../components/Shared/SelectedCondo";
import Context from "../../contexts/Context";
import Masks from '../../components/Shared/Masks'
import moment from 'moment'
import StatusInvoice from "../../components/Invoice/StatusInvoice";

const drawerWidth = 250

const Navbar = ({ handleDrawerToggle }) => {
  const { user } = useContext(Context)
  const { perm_bank } = user || {}


  const [req, setReq] = useState(null)
  const [open, setOpen] = useState(false)

   useEffect(() => {
       console.log("user", user)
       if (user?.overdue){
           setOpen(true)
       }
   }, [user])

  return (
    <Box sx={ user?.invoices ? { mb: 18 } : { mb: 10 }}>
      <AppBar
        position={'fixed'}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          bgcolor: 'white',
          boxShadow: 'rgb(32 32 32 / 6%) 0px 8px 16px;',
        }}>
        { user?.invoices &&
          <Toolbar  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: user?.overdue ? '#dd7a7a' : '#d6b98f',backgroundColor: user?.overdue ? '#FDEDEC' : '#FEFDEF' }}>
            {user?.overdue ?
            <Box>
              Olá {user?.first_name}, sua fatura venceu efetue o pagamento para evitar o bloqueio da sua conta. <Button onClick={()=> { setOpen(true)}}>Ver fatura</Button>
            </Box>
            :<Box>
                Olá {user?.first_name}, sua fatura referente ao BlocoB3 foi gerada. <Button onClick={()=> { setOpen(true)}}>Ver fatura</Button>
            </Box> }
        </Toolbar>  }
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <IconButton
            color="primary"
            aria-label="Abrir Barra"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuOutlined/>
          </IconButton>
          <BreadCrumbs/>
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <SelectedCondo />
            { perm_bank === true &&
              <Box
                onClick={() => setReq(prevState => !prevState)}
                >
                <BalanceBar req={req}/>
              </Box>
            }
            <NotificationMenu/>
            <UserMenu/>
          </Box>
        </Toolbar>
      </AppBar>

      { user?.invoices && <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        scroll={'body'}
        //onClose={()=> {setOpen(false)}}
      >
        <DialogTitle >Faturas ({user?.invoices.length})</DialogTitle>
        <Divider/>
        <DialogContent>
          {user?.overdue &&
          <Alert severity="error" sx={{ mb: 2, }} variant="filled">
              <Typography variant="body1" gutterBottom>
            Olá <strong>{user.first_name}</strong>, identificamos que você possui faturas vencidas.
            Por favor, regularize o pagamento para evitar a suspensão do serviço.
              </Typography>
          </Alert>}


          <Table>
            <TableHead>
              <TableRow>
                <TableCell >Código</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>status</TableCell>
                <TableCell>Vencimento</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Boleto/Pix</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { user?.invoices.map(invoice =>
                <TableRow>

                  <TableCell>
                    {invoice.number}
                  </TableCell>
                  <TableCell>
                    {invoice.name}
                  </TableCell>
                    <TableCell>
                        <StatusInvoice
                            invoice={invoice}
                            />
                    </TableCell>
                  <TableCell>
                    {moment(invoice.due_date).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {Masks.money(invoice.total.toString())}
                  </TableCell>
                  <TableCell>
                    <Link
                      href={invoice.payment_link}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}>
                      <Chip
                        label={'Página de pagamento'}
                        size={'small'}
                        icon={<OpenInNew />}
                      />
                    </Link>
                  </TableCell>

                </TableRow> )}



            </TableBody>

          </Table>
        </DialogContent>
        <DialogActions sx={{display:"flex", justifyContent:"space-between", padding: "2rem 1rem"}}>
          <Button variant="text" color={"secondary"} onClick={()=> setOpen(false) }>Fechar</Button>

        </DialogActions>
      </Dialog> }
    </Box>
  )
}
export default Navbar
